import React, { useEffect, useState, useContext } from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import StateContext from '../../../StateContext'
import DeleteMessageThread from './DeleteMessageThread'

function Messages(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(false);
    const [messages, setMessages] = useState([]);

    //console.log("Messages:", messages)

    const token = localStorage.getItem("token")
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    useEffect(() => {

        async function getMessages() {
            setIsloading(true)
            try {
                //console.log(page)
                
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/messages/", {headers: headers});
                if (response.data) {
                    setMessages(response.data)
                    console.log(response.data)
                } else {
                    console.log("Klarte ikke hente medlemmer")
                }
                
            } catch (error) {

            }
            setIsloading(false)

        }
        getMessages()

    }, [])

    function formatDate(date) {

        const dateMessage = new Date(date)
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];

        let minutes = dateMessage.getMinutes()
        let hours = dateMessage.getHours();
        let dateformatted = dateMessage.getDate() + "/" + monthNames[dateMessage.getMonth()];
        if (minutes < 10) {
            minutes = "0" + minutes
        } 
        if (hours < 10) {
            hours = "0" + hours
        }

        return hours + ":" + minutes;
    }

    if (isLoading) return (

        <div>
            <Loader />
        </div>

    )

    return (
        <>
            
            
            {messages.length ? messages.map((message, index) => {
                return (
                    
                        <div key={index} >
                            <div className={+props.threadId === message.id ? "card-body bg-light" : "card-body"}>
                                <div className="row">
                                    <div className="col-4 col-md-3">
                                        <img src={`http://www.gravatar.com/avatar/954e55e8323729f816c5f80277373afb?s=50&#038;r=g&#038;d=mm`} className="img-fluid rounded-circle" alt="user-avatar" />
                                    </div>
                                    <div className="col-8 col-md-9 float-right">
                                        <div className="row">
                                            
                                                <div className="col-10">
                                                    <Link  to={`/bp/messages/${message.id}`}>
                                                        <div className="text-dark font-weight-bold">{message.display_name}</div>
                                                        <div dangerouslySetInnerHTML={{__html: message.message.rendered}}></div>
                                                    </Link>
                                                </div>
                                            
                                            <div className="col-2">
                                                <small className="text-grey float-right">{formatDate(message.date)}</small><br/>
                                                {message.unread_count ? <span className="badge badge-danger float-right">{message.unread_count}</span> : null}
                                            </div>
                                        </div>
                                    </div>                   
                                </div> 
                                <div className="float-right pb-3">
                                    <DeleteMessageThread threadId={message.id} />
                                </div>
                                <br/>
                            </div>
                            
                            
                            <div style={{borderBottom: "1px solid #D8D8D8"}}></div>
                        </div>
                    
                )
            }) : <div className="col-md-6 mx-auto my-3">
                    <div className="card card-body">Du har ingen meldinger i innboksen</div>
                </div>}
        </>

    )
}
export default Messages