import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'
import FixtureListTemplate from '../Templates/FixtureListTemplate'
import LiveFixtureTemplateResponsive from '../Fixture/LiveFixtureTemplateResponsive'

function LatestFixturesLeague(props) {

    const [fixtures, setFixtures] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchLatestFixtures() {

            setIsLoading(true)

            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/fixtures/league/" + props.id +  "/last/10",
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    },"params":{
                    "timezone":"Europe/London"
                    }
                })
                console.log(response.data.api.fixtures, "Latest fixtures league")
                setFixtures(response.data.api.fixtures)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchLatestFixtures()


    }, [props.id])

    if (isLoading) return <p>Loading fixtures...</p>

    return (
        <div className="my-4">
            <h4>Latest fixtures</h4>
            {fixtures.map((fixture, index) => {

                let time, minutes, day, month
                const date = new Date(fixture.event_date);

                if (date.getHours() < 10) {
                    time = `1${date.getHours()}`
                } else {
                    time = date.getHours()
                }
                if (date.getMinutes() < 10) {
                    minutes = `0${date.getMinutes()}`
                } else {
                    minutes = date.getMinutes()
                }
                if (date.getDate() < 10) {
                    day = `0${date.getDate()}`
                } else {
                    day = date.getDate()
                }
                if (date.getMonth() < 10) {
                    month = `0${date.getMonth()}`
                } else {
                    month = date.getMonth()
                }
                const dateFormatted = `${day}/${month}/${date.getFullYear()} ${time}:${minutes}`

                return (
                    <>
                    <div className="showLarge">
                        <FixtureListTemplate key={index} fixture={fixture} index={index} />
                    </div>
                    
                    <div className="showResponsive">
                        <LiveFixtureTemplateResponsive key={index} fixture={fixture} index={index} />
                    </div>
                    </>
                )
            })}
        </div>
    )
}
export default LatestFixturesLeague