import React, { useContext, useState, useEffect } from 'react'
import Axios from 'axios';
import StateContext from '../../../StateContext';

function RecommendMovie(props) {

    const appState = useContext(StateContext)

    const [post, setPost] = useState()
    const [isRecommendationLoading, setIsRecommendationLoading] = useState(false)
    const [isAdded, setIsAdded] = useState(false)
    const [addError, setAddError] = useState(false)
    const [addErrorMessage, setAddErrorMessage] = useState()

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {
        let postHtml 

            postHtml = `
            <div class="metaContent"> ${appState.user.user_display_name} Anbefalte filmen ${props.movieTitle} </div>
            <div class="pt-1 mx-auto wideImagePoster">
                <img class="moviePoster" src="${props.moviePoster}">
            </div>
            `

            setPost(postHtml);

    }, [props.moviePoster])


    async function recommendMedia(e) {
        e.preventDefault();   

        setIsRecommendationLoading(true)
        try {
            
            const response = await Axios.post(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/activity', 
            {
                context: "edit",
                content: post,
                user_id: appState.user.appUserId,
                type: "activity_update"

            },
            {headers: headers}
            )
            if (response.data) {
                setIsAdded(true)
                console.log(response.data)
            } else {
                setAddError(true)
                console.log(response)
            }

        } catch (error) {
            setAddError(true)
            setAddErrorMessage(error.message)
            console.log(error)
        }

        setIsRecommendationLoading(false)
        

    }

    if (props.isLoading) {
        return <><p className="text-white">Laster..</p></>
    } 

    return (
        <>
            <form onSubmit={recommendMedia}>
                {addError && <p><span className="alert alert-danger"> {addErrorMessage}</span></p>}
                {isAdded && <p><span className="alert alert-success">Lagt til!</span></p>}
                <button 
                    className={isAdded ? "btn btn-warning btn-lg btn-block" : "btn btn-primary btn-lg btn-block"}
                >
                    <i class="far fa-paper-plane"></i> {isAdded ? "Fjern" : "Anbefal film"} 
                </button>
            </form>
        </>
    )

}
export default RecommendMovie