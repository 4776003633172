import React, { useContext, useState } from 'react'
import Axios from 'axios'
import StateContext from '../../../StateContext'

function DeleteMessageThread(props) {

    const appState = useContext(StateContext)

    const [deleted, setDeleted] = useState(false)
    const [deleteError, setDeleteError] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    async function deleteThread(e) {
        e.preventDefault()
        try {
            const response = await Axios.delete(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/messages/' + props.threadId, 
            {
                headers: headers, 
                data: {
                    context: "edit",
                    user_id: appState.user.appUserId
                }
            })
            if (response.data) {
                setDeleted(true)
            } else {
                setDeleteError(true)
            }
        } catch (error) {
            console.log(error)
            setDeleteError(true)
        }
    }

    if (deleted) return <div className="alert alert-success">Slettet</div>

    if (deleteError) return <div className="alert alert-danger">Noe gikk galt, prøv igjen</div>

    return (
        <div className="float-right">
            <form onSubmit={deleteThread}>
                <button style={{backgroundColor: "transparent", border: "none"}}><i className="fas fa-trash"></i></button>
            </form>
        </div>
    )

}
export default DeleteMessageThread