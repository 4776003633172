import React, { useContext, useEffect, useState } from 'react'
import {Link} from 'react-router-dom'

import StateContext from '../../../StateContext'
import Axios from 'axios'

function MemberFavoritePosts(props) {

    const appState = useContext(StateContext)

    const [posts, setPosts] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        async function fetchPosts() {
            setIsLoading(true)
            try {
                
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/activity?scope=favorites&per_page=100&component=blogs&user_id=' + props.userId)
                setPosts(response.data)
                console.log(response.data)

            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchPosts()

    }, [props.userId])

    return (

        <div>
            <br />
            {posts.map(post => {
                return (
                    <div key={post.id} className="card card-body">
                        <Link to={`/innlegg/single/${post.secondary_item_id}`}>
                            <div dangerouslySetInnerHTML={{__html: post.title}}></div>
                        </Link>
                        <small>Av {post.display_name}</small>
                    </div>
                )
            })}
            <br/>
        </div>

    )
}
export default MemberFavoritePosts