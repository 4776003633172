import React, { useState, useContext } from 'react'

import {auth} from './firebase'
import StateContext from '../../StateContext'
import DispatchContext from '../../DispatchContext';

const LoginFirebase = () => {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    const handleLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const credential = await auth.signInWithEmailAndPassword(email, password)
            console.log('Credential:', credential)
            appDispatch({type: "loginFB", value: true})
            appDispatch({type: "flashMessage", value: "Du har blitt logget inn til firebase"})
        } catch (error) {
            setError(true)
            console.log(error)
            
        }
        setLoading(false)

    }

    return (
        <div className="container my-5">
            <form onSubmit={handleLogin} >
                <label>Epost</label>
                <input className="form-control mb-2" onChange={e => setEmail(e.target.value)}  />
                <label>Passord</label>
                <input type="password" className="form-control mb-2" onChange={e => setPassword(e.target.value)} />
                {error && <div className="alert alert-danger">Noe gikk galt</div>}
                {appState.user.loggedInFB && <div className="alert alert-success">Logget inn</div>}
                <button type="submit" className="btn btn-success btn-block btn-lg btn-primary">Logg inn</button>
            </form>
        </div>
    )
}
export default LoginFirebase