import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'

import MoviePagination from '../Movie/MoviePagination'

function TV() {

    const [movies, setMovies] = useState([])
    const [moviesLoaded, setMoviesLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const api_key = "7e21606276f58bbdfa39c8cdd8e051f5"
    const {page} = useParams()

    useEffect(() => {

        async function getMovies() {
            setIsLoading(true);
            try {
                const response = await fetch(`https://api.themoviedb.org/3/tv/popular?api_key=${api_key}&language=en-US&page=${page}`)
                const data = await response.json()
                console.log(data.results)
                setMovies(data.results)
                setMoviesLoaded(true)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }

        getMovies()

    }, [page])

    if (isLoading) return <div>Laster...</div>

    return (
        <>
            <div className="container">
                <h2>Serier</h2>
                <hr></hr>
                    {moviesLoaded && <>

                    <div className="row">
                        {movies.map(movie => {
                        return (
                        <div className="col-6 col-md-3 col-lg-3 mb-3" key={movie.id}>
                            <div className="card">
                                <Link to={`/shows/single/${movie.id}`}>
                                    {movie.poster_path != null ? 
                                    <img src={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} alt={movie.title} className="img-fluid" /> : 
                                    <img src={`https://complexmovieapp.herokuapp.com/images/film-poster-placeholder.png`} alt={movie.title} className="img-fluid" />}
                                </Link>
                            </div>
                        </div>
                        )
                    })}
                    </div>
                </>}
                <MoviePagination link="shows" currentPage={page} />
            </div>
        </>
        
    )
}
export default TV