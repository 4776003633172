import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import LiveFixturesFilteredByLeague from './LiveFixturesFilteredByLeague'
import FixturesByDate from './FixturesByDate'
import LiveFixtureTemplate from './LiveFixtureTemplate'

function LiveFixtures() {

    const [fixtures, setFixtures] = useState()
    const [activeItem, setActiveItem] = useState("1")

    useEffect(() => {

        async function fetchLiveData() {

            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/fixtures/live",
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    },"params":{
                    "timezone":"Europe/London"
                    }
                })
                if (response.data) {
                    console.log(response.data.api.fixtures)
                    setFixtures(response.data.api.fixtures)
                }
                

            } catch (error) {
                console.log(error)
            }

        }
        fetchLiveData()

    }, [])

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    return (
        <div className="bg-light">
            <div className="container pb-5">
                <div className="row bg-white"  style={{borderTop: "1px solid #e3e3e3"}}>
                    <div className="column-4">
                        <button 
                            onClick={() => toggle("1")} 
                            className={
                                activeItem === "1" ? 
                                "btn-block text-dark p-2 z-depth-1 buttonTransparent" : 
                                "btn-block grey-text p-2 buttonTransparent"
                            } 
                        >
                            Live now
                        </button>
                    </div>
                    <div className="column-4">
                        <button onClick={() => toggle("2")} className={activeItem === "2" ? "btn-block text-dark p-2 z-depth-1 buttonTransparent" : "btn-block grey-text p-2 buttonTransparent"} >By league</button>
                    </div>
                    <div className="column-4">
                        <button onClick={() => toggle("3")} className={activeItem === "3" ? "btn-block text-dark p-2 z-depth-1 buttonTransparent" : "btn-block grey-text p-2 buttonTransparent"} >Date</button>
                    </div>        
                </div>

                {activeItem === "1" && 
                    <>
                        <h2 className="text-center">Live now</h2>
                        {fixtures && fixtures.length > 0 ? fixtures.map((fixture, index) => {
                            return (
                                <div key={index}>
                                    <LiveFixtureTemplate index={index} fixture={fixture} />
                                </div>
                            )
                        }) : 
                        <p>
                            Ingen kamper live akkurat nå <br/>
                            <button 
                                onClick={() => toggle("3")} 
                                className={"btn btn-primary"} 
                            >
                                Vis dagens kampprogram
                            </button>
                            </p>}
                    </>
                }
                {activeItem === "2" &&<LiveFixturesFilteredByLeague />}
                {activeItem === "3" && <FixturesByDate />}
            </div>
        </div>
    )

}
export default LiveFixtures