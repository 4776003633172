import React, {useState, useContext} from 'react'
import {withRouter} from 'react-router-dom'
import Axios from 'axios'
import DispatchContext from '../../DispatchContext'
import StateContext from '../../StateContext'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function CreateRecipe(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const token = appState.user.token
    const [title, setTitle] = useState()
    const [body, setBody] = useState()
    const [imageUrl, setImageUrl] = useState()
    

    const [value, setValue] = useState('');

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/wp/v2/recipe", 
                {
                    title, 
                    content: value
                },                     
                {headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                    }
                }
            )
        
            // Redirect til nytt innleggs url
            appDispatch({type: "flashMessage", value: "Du har lagt til en ny oppskrift!"})

            
        } catch (error) {
            console.log("Noe gikk galt: ", error)
        }

        
    }
    if (!appState.user.token) return <p>Du må være logget inn</p>

    return (
        <>
        <div className="container my-4">

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="post-title" className="text-muted mb-1">
                        <small>Title</small>
                    </label>
                    <input onChange={e => setTitle(e.target.value)} autoFocus name="title" id="post-title" className="form-control form-control-lg form-control-title" type="text" placeholder="" autoComplete="off" />
                </div>

                <ReactQuill theme="snow" value={value} onChange={setValue}/>

                <button className="btn btn-primary">Save New Post</button>
            </form>

        </div>
        </>
    )
}
export default CreateRecipe