import React from 'react'

import HomeCardSocial from './HomeCardSocial';

function Home() {



    return (
        <>
            <HomeCardSocial/>
            
        </>
    )
}
export default Home