import React from 'react'
import {Link} from 'react-router-dom'

function SingleBlogTemplate(props) {

    const post = props.post



    return (
        <div key={post.id} className={props.size && props.size === "full" ? "" : "col-lg-6 mb-3"}>
            <div className="card">
                <Link to={`/football/blog/${post.id}`}>
                    <img src={post.acf.featured_image_post || "https://mdbootstrap.com/img/Photos/Slides/img%20(68).jpg"} className="img-fluid container-image" />
                </Link>
                <div className="text-block">
                    <h6 className="font-weight-bold">
                        <Link to={`/football/blog/${post.id}`}>
                            {props.formatTitle(post.title.rendered)}
                        </Link>
                    </h6>
                </div>
            </div>
        </div>
    )
}
export default SingleBlogTemplate