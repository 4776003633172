import React, { useState, useEffect } from 'react'
import { firestore } from '../firebase'
import { Link } from 'react-router-dom';

function FootballBlogList() {

    const [posts, setPosts] = useState([])

    useEffect(() => {

        const entriesRef = firestore.collection("football-blogs");
        entriesRef.get().then((snapshot) => {
            const entries = snapshot.docs.map(doc => {
                const entries = {id: doc.id, ...doc.data()}
                return entries
            })
            setPosts(entries)
        })

    }, [])

    return (

        <div className="container">

            {posts && posts.map((post, index) => {
                return (
                    <Link to={`/football/blog-fb/edit/${post.id}`}><h2>{post.title}</h2></Link>
                )
            })}

        </div>

    )
}
export default FootballBlogList