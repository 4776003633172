import React from 'react'
import {Link} from 'react-router-dom'

function FixtureListTemplate(props) {

    const fixture = props.fixture
    function formatDate(dateFixture) {

        let date = new Date(dateFixture);

        //let year = date.getFullYear();


        let day = date.getDate()
        let month = date.getMonth();
        month = (month+1)
        let minutes = date.getMinutes()
        let hours = date.getHours();

        if (minutes < 10) {
            minutes = `0${minutes}`
        } else {
            minutes = minutes
        }
        if (hours < 10) {
            hours = `0${hours}`
        } else {
            hours = hours
        }
        if (month < 10) {
            month = `0${month}`
        }
        if (day < 10) {
            day = `0${day}`
        } else {
            day = day
        }
        let time = hours + ":" + minutes;   

        return `${day}/${month} ${time}`

    }
    return (
        <div key={props.index}>
            <div  className="row z-depth-1 bg-white p-3">
                <div className="col-2 col-xl-2">{formatDate(fixture.event_date)} </div>
                <div className="col-3 col-md-3 text-right"> {fixture.homeTeam.team_name} <img src={fixture.homeTeam.logo} className="img-fluid teamLogo" /></div>
                <div className="col-2 col-md-2 text-center"><Link to={`/football/fixtures/${fixture.fixture_id}`}>{fixture.goalsHomeTeam} - {fixture.goalsAwayTeam}</Link>  </div>
                <div className="col-3 col-md-3"><img src={fixture.awayTeam.logo} className="img-fluid teamLogo" /> {fixture.awayTeam.team_name} </div>
                <div className="col-2 col-xl-1"></div>
            </div>
        </div>
    )
}
export default FixtureListTemplate