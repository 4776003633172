import React, {useEffect, useState} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import {useImmerReducer} from 'use-immer'
import Axios from 'axios'
import {auth} from './components/Firebase/firebase'


import StateContext from './StateContext'
import DispatchContext from './DispatchContext'
import FlashMessages from './components/FlashMessages/FlashMessages'

import "./index.css";
import Home from './components/Home/Home'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'

// WP CORE
import BlogPostList from './components/Blog/BlogPostList'
import SinglePost from './components/Blog/SinglePost'

// MEDIA
import AddMedia from './components/Media/AddMedia'

import PageList from './components/Pages/PageList'
import SinglePage from './components/Pages/SinglePage'

// Add post / recipe /post type
import CreatePost from './components/Post/CreatePost'
import EditPost from './components/Post/EditPost'


// Custom post types
import CreateRecipe from './components/Post/CreateRecipe'
import EditRecipe from './components/Post/EditRecipe'

// RECIPES
import Recipe from './components/Recipe/Recipe'
import RecipeCategories from './components/Recipe/RecipeCategories'
import RecipeCategory from './components/Recipe/RecipeCategory'
import RecipePostLists from './components/Blog/RecipePostList'

// Buddypress
import Member from './components/BuddyPress/Member/Member';
import Members from './components/BuddyPress/Member/Members';

import Messages from './components/BuddyPress/Message/Messages'
import MessageThread from './components/BuddyPress/Message/MessageThread'
import AddMessageThread from "./components/BuddyPress/Message/AddMessageThread";

import Activity from './components/BuddyPress/Activity/Activity'
import SingleActivity from "./components/BuddyPress/Activity/SingleActivity";

import Signup from './components/BuddyPress/Auth/Signup'
import LoginForm from './components/BuddyPress/Auth/LoginForm'

import Groups from './components/BuddyPress/Group/Groups'
import SingleGroup from "./components/BuddyPress/Group/SingleGroup";
import AddGroup from "./components/BuddyPress/Group/AddGroup";
import NotificationsPage from './components/BuddyPress/Notifications/NotificationsPage'

// Custom components
import View from './components/View/View'
import ViewJarallax from "./components/View/ViewJarallax";


// PROGITEK BP
import FMVidunderbarn from './components/FM/FMVidunderbarn'

// MOVIE APP
import Movies from './components/FilmApp/Movie/Movies'
import SearchMovies from './components/FilmApp/Movie/SearchMovies'
import SingleMovie from './components/FilmApp/Movie/SingleMovie'
import HomeMovies from './components/FilmApp/Sixty/HomeMovies'
import TV from './components/FilmApp/TV/TV'
import SingleTV from './components/FilmApp/TV/SingleTV'
import SearchShows from './components/FilmApp/Search/SearchShows'
import InvitesPage from "./components/BuddyPress/Member/InvitesPage";
import MemberFavoritedMedia from "./components/BuddyPress/Member/MemberFavoritedMedia";
import NavbarInsta from "./components/Header/NavbarInsta";

// FB API
import AllFixtures from "./components/FootballApi/AllFixtures";
import AllLeagues from "./components/FootballApi/AllLeagues";
import Countries from './components/FootballApi/League/Countries'
import SingleLeague from "./components/FootballApi/SingleLeague";
import SingleTeam from "./components/FootballApi/SingleTeam";
import SinglePlayer from "./components/FootballApi/SinglePlayer";
import SingleFixture from "./components/FootballApi/SingleFixture";
import SingleCountry from "./components/FootballApi/League/SingleCountry";
import SearchTeams from "./components/FootballApi/Search/SearchTeams";
import LiveFixtures from "./components/FootballApi/Fixture/LiveFixtures";
import SingleBlog from "./components/FootballApi/FootballBlog/SingleBlog";
import AddPlayerFB from "./components/Firebase/FootballApiFB/AddPlayer";
import EditFootballBlog from "./components/Firebase/Post/EditFootballBlog";

// SPILL
import BlackJack from './components/Spill/BlackJack'
//import HomeMovies from "./components/FilmApp/Sixty/HomeMovies";

function App() {

    //const localWordpress = "http://localhost/dashboard/OLEPETTER/WORDPRESS/BUDDYPRESS/"
    const camillahardilla = "https://camillahardilla.com/"
    //const progitek = "https://progitek.no/privat/bp/wp-json/wp/v2/";
    const progitek = "https://progitek.no/privat/bp/";

    // Min kokebok details
    const minKokebok = {
        title: "Min Kokebok",
        image: "https://camillahardilla.com/wp-content/uploads/2020/02/flat-lay-photography-of-vegetable-salad-on-plate-1640777-1024x683.jpg",
        header: {
            
        }
    }

    const bpApp = {
        title: "Connectify",
        image: "https://images.pexels.com/photos/33129/popcorn-movie-party-entertainment.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        header: {

        }
    }

    const [userId, setUserId] = useState()
    const [loggedInFB, setLoggedInFB] = useState(false)

    useEffect(() => {

        return auth.onAuthStateChanged((firebaseUser) => {
            if (firebaseUser) {
                setLoggedInFB(true)
                setUserId(firebaseUser.uid)
            } else {
                setLoggedInFB(false);
            }

        });
    
    }, [])

  const initialState = {
    theme: "movies",
    loggedIn: Boolean(localStorage.getItem("token")),
    loggedInFB: Boolean(loggedInFB),
    flashMessages: [],
    userFB: {
        userId: userId
    },
    user: {
        token: localStorage.getItem("token"),
        user_email: localStorage.getItem("appEmail"),
        user_display_name: localStorage.getItem("appDisplayName"),
        user_nicename: localStorage.getItem("appUsername"),
        avatar: localStorage.getItem("appAvatar"),
        tokenExpiration: localStorage.getItem("appTokenExpiration"),
        appAdmin: localStorage.getItem("appAdmin"),
        appUserId: localStorage.getItem("appUserId"),
        appBPMemberId: localStorage.getItem("appBPMemberId"),
        appUserNotificationsCount: localStorage.getItem("appUserNotificationsCount")
    },
    isSearchOpen: false,
    darkBackground: false,
    wordpressUrl: progitek + "wp-json/wp/v2/",
    wordpressBaseUrl: progitek,
    wordpressSite: "bp-social",
    featuredImage: bpApp.image,
    showSingleMovie: false,
    singleMovie: null,
    singleMovieWidth: "0",
    genreId: null,
    prevGenreId: null,
    genreChanged: false,
    genreIndex: 0,
    activeComponent: 1,
    activeItem: 0,
    viewSingleMovie: false,
    pageCount: 1,
    windowHeight: null,
    currentMovieRow: 1
}

function ourReducer(draft, action) {
    switch (action.type) {

        case "changedTheme":
            draft.theme = action.value 
            return
        case "login":
            draft.loggedIn = true
            draft.user = action.data
            return
        case "loginFB":
            draft.loggedInFB = true
            return
        case "fetchAppUserId": 
            draft.appUserIdFetched = true
            draft.appUserId = action.value
            return
        case "appBPMemberIdFetched": 
            draft.appBPMemberIdFetched = true
            draft.user.appUserId = action.value
            return
        case "userNotificationsCount":
            draft.appUserNotifications = true
            draft.appUserNotificationsCount = action.value
            return
        case "logout":
            draft.loggedIn = false
            return
        case "flashMessage":
            draft.flashMessages.push(action.value)
            return 
        case "openSearch":
            draft.isSearchOpen = true
            return
        case "closeSearch":
            draft.isSearchOpen = false
            return            
        case "openModal":
            draft.darkBackground = true
            return
        case "closeModal":
            draft.darkBackground = false
            return
        case "genreFetched":
            draft.genreId = action.value
            draft.genreChanged = true;
            return
        case "genreChanged":
            draft.prevGenreId = action.value
            draft.genreChanged = true
            draft.pageCount = 1
            return 
        case "genreIndexFetched":
            draft.genreIndex = action.value
            draft.genreChanged = false;
            return
        case "showSingleMovie":
            draft.showSingleMovie = true
            draft.genreChanged = false;
            return
        case "singleMovieFetched":
            draft.singleMovie = action.value
            draft.singleMovieWidth = "20%"
            draft.genreChanged = false;
            return
        case "hideSingleMovie":
            draft.showSingleMovie = false
            draft.singleMovieWidth = "0%"
            draft.genreChanged = false;
            return
        case "increasedActiveItem":
            draft.activeItem = action.value
            draft.genreChanged = false;
            return
        case "increasedActiveComponent":
            draft.activeComponent = action.value
            draft.genreChanged = false;
            return
        case "decreasedActiveComponent":
            draft.activeComponent = action.value
            draft.genreChanged = false;
            return
        case "decreasedActiveItem":
            draft.activeItem = action.value
            draft.genreChanged = false;
            return
        case "viewSingleMovie":
            draft.viewSingleMovie = true;
            draft.showSingleMovie = true;
            return 
        case "hideSingleMovie":
            draft.viewSingleMovie = false;
            draft.showSingleMovie = false;
            return 
        case "loadMoreMovies":
            draft.pageCount = action.value
            draft.genreChanged = false;
            return 
        case "scrollWindow":
            draft.windowHeight = action.value
            draft.currentMovieRow = action.value
            return 
    }
}

const [state, dispatch] = useImmerReducer(ourReducer, initialState);

useEffect(() => {

    if (state.appUserNotifications) {
        localStorage.setItem("appUserNotificationsCount", state.user.appUserNotificationsCount)
    }

}, [state.appUserNotifications])

useEffect(() => {
    if (state.loggedIn) {

        if (state.user.user_email === "olepetter_kh@hotmail.no") {
            localStorage.setItem("appAdmin", state.user.appAdmin);
        }

        // Fetch user data / buddypress member data and get avatar and user_id
        localStorage.setItem("token", state.user.token)
        localStorage.setItem("appEmail", state.user.user_email)
        localStorage.setItem("appDisplayName", state.user.user_display_name)
        localStorage.setItem("appUsername", state.user.user_nicename)
        localStorage.setItem("appUserId", 3)

        const expirationToken = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7)
        localStorage.setItem("appTokenExpiration", expirationToken)
    } else {
        localStorage.removeItem("appAdmin")
        localStorage.removeItem("token")
        localStorage.removeItem("appEmail")
        localStorage.removeItem("appDisplayName")
        localStorage.removeItem("appUsername")
        localStorage.removeItem("appTokenExpiration")
        localStorage.removeItem("appUserId")
        localStorage.removeItem("appUserNotificationsCount")
    }
}, [state.loggedIn])

useEffect(() => {
    if (state.appBPMemberIdFetched) {
        localStorage.setItem("appUserId", state.user.appUserId)
    } else {
        localStorage.removeItem("appUserId")
    }
}, [state.appBPMemberIdFetched])



const expirationToken = new Date(localStorage.getItem("appTokenExpiration")).getTime();
const currentTime = new Date().getTime()


const tokenRemainingTime = (expirationToken-currentTime)
const tokenRemainingTimeSeconds = (expirationToken-currentTime)/1000
const tokenRemainingTimeMinutes = tokenRemainingTimeSeconds/60
const tokenRemainingTimeHours = tokenRemainingTimeMinutes/60
const tokenRemainingTimeDays = tokenRemainingTimeHours/60


// Sjekk om token har utløpt eller ikke
useEffect(() => {

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + state.user.token
    }

    if (state.loggedIn) {
        const ourRequest = Axios.CancelToken.source()
        async function fetchResults() {
            try {
                const response = await Axios.post(state.wordpressBaseUrl +  "wp-json/jwt-auth/v1/token/validate", {}, {headers: headers});

                if (!response.data) {
                    dispatch({type: "logout"});
                }
            } catch (error) {
                console.log("Validering feilet: ", error)
            }
            
        }
        fetchResults()
        return () => ourRequest.cancel()
    }

}, [])

    return (
      <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
      <BrowserRouter>
      <FlashMessages messages={state.flashMessages} />
        <Header />
        <NavbarInsta />
          <Switch>
            <Route path="/" exact>
              <>
                <ViewJarallax image={bpApp.image} preText="Velkommen til " text={bpApp.title} />
                <Home/>
              </>
            </Route>
            <Route path="/innlegg/:page" exact>
              <BlogPostList/>
            </Route>
            <Route path="/innlegg/single/:id" exact>
              <SinglePost/>
            </Route>

            <Route path="/sider" exact >
                <PageList />
            </Route>
            <Route path="/sider/single/:id" exact>
                <SinglePage />
            </Route>




            <Route path="/create-post" exact>
                <CreatePost/>
            </Route>
            <Route path="/edit-post/:id">
                <EditPost/>
            </Route>

            <Route path="/add-media" exact>
                <AddMedia />
            </Route>

            <Route path="/bp/members" exact>
                <Members />
            </Route>
            <Route path="/bp/members/:id" exact>
                {state.loggedIn ? <Member /> : <LoginForm message="Du må være logget inn for å se denne siden" />}
            </Route>

            <Route path="/bp/notifications" exact>
                <NotificationsPage/>
            </Route>
            <Route path="/bp/add-messagethread" exact>
                <AddMessageThread/>
            </Route>
            <Route path="/bp/messages" exact>
                <Messages />
            </Route>
            <Route path="/bp/messages/:id" exact>
                <MessageThread />
            </Route>

            <Route path="/bp/activity" exact>
                <Activity />
            </Route>
            <Route path="/bp/activity/:id" exact>
                <SingleActivity />
            </Route>
            <Route path="/bp/signup" exact>
                <Signup />
            </Route>
            <Route path="/bp/login" exact>
                <LoginForm />
            </Route>
            <Route path="/bp/groups" exact>
                <Groups />
            </Route>
            <Route path="/bp/groups/:id" exact>
                <SingleGroup/>
            </Route>
            <Route path="/bp/group/add" exact>
                <AddGroup/>
            </Route>
            <Route path="/bp/invites" exact>
                <InvitesPage />
            </Route>
            <Route path="/bp/favoritedmedia" exact>
                <div className="container my-5">
                    <MemberFavoritedMedia userId={state.user.appUserId} />
                </div>       
            </Route>



            <Route path="/create-recipe" exact>
                <CreateRecipe/>
            </Route>
            <Route path="/edit-recipe/:id">
                <EditRecipe/>
            </Route>
            <Route path="/oppskrift/:id" exact>
                <Recipe />
            </Route>
            <Route path="/categories" exact>
                <>
                    <View text="Kategorier" image="https://camillahardilla.com/wp-content/uploads/2020/02/flat-lay-photography-of-vegetable-salad-on-plate-1640777-scaled.jpg" />
                    <RecipeCategories />
                </>
            </Route>
            <Route path="/category/:id" exact>
                <RecipeCategory />
            </Route>
            
            <Route path="/oppskrifter/:page" exact>
                <RecipePostLists/>
            </Route>



            <Route path="/football/all-fixtures" exact>
                <AllFixtures />
            </Route>
            <Route path="/football/all-leagues" exact>
                <AllLeagues/>
            </Route>
            <Route path="/football/countries" exact>
                <Countries />
            </Route>
            <Route path="/football/countries/:code" exact>
                <SingleCountry />
            </Route>
            <Route path="/football/league/:id" exact>
                <SingleLeague/>
            </Route>

            <Route path="/football/search-teams" exact>
                <SearchTeams />
            </Route>
            <Route path="/football/teams/:id" exact>
                <SingleTeam />
            </Route>

            <Route path="/football/players/:id" exact>
                <SinglePlayer />
            </Route>
            <Route path="/football/live" exact>
                <LiveFixtures />
            </Route>
            <Route path="/football/fixtures/:id" exact>
                <SingleFixture />
            </Route>

            <Route path="/football/blog/:id">
                <SingleBlog />
            </Route>
            <Route path="/football/blog-fb/edit/:id" exact>
                <EditFootballBlog />
            </Route>
            <Route path="/football/add-player" exact>
                <AddPlayerFB />
            </Route>



            <Route path="/fm/vidunderbarn-table">
                <FMVidunderbarn/>
            </Route>


            <Route path="/movies-2" exact>
                <HomeMovies />
            </Route>
            <Route path="/movies/:page" exact>
                <Movies />
            </Route>
            <Route path="/movies-keypress" exact>
                <HomeMovies />
            </Route>
            <Route path="/search-movies" exact>
                <SearchMovies />
            </Route>
            <Route path="/movies/single/:id" exact>
                <SingleMovie />
            </Route>
            <Route path="/shows/:page" exact>
                <TV />
            </Route>
            <Route path="/search-shows" exact>
                <SearchShows />
            </Route>
            <Route path="/shows/single/:id" exact>
                <SingleTV />
            </Route>

            <Route path="/spill/21" exact>
                <BlackJack />
            </Route>

          </Switch>
          <Footer />
      </BrowserRouter>
      </DispatchContext.Provider>
      </StateContext.Provider>
    );

}

export default App;
