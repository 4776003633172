import React, { useEffect, useState, useContext } from 'react'
import Axios from 'axios'

import StateContext from '../../../StateContext'
import { Link } from 'react-router-dom'

function TeamPlayersWP(props) {

    const appState = useContext(StateContext)

    const [players, setPlayers] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchPlayers() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/players?per_page=35&search=teamid=" + props.id)
                if (response.data) {
                    setPlayers(response.data)
                }
                //console.log(response)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        fetchPlayers()

    }, [props.id])

    if (isLoading) return <p>Laster...</p>

    return (
        <div className="row">
            {players && players.map(player => {
                return (
                    <figure className="col-4 col-md-2" key={player.id}>
                        <div className=" z-depth-1 bg-white">
                            <Link to={`/football/players/${player.acf.player_id}`}>
                                <img style={{objectFit: "cover", height: "200px", width: "200px"}} src={player.acf.player_image} alt={player.title.rendered} /> 
                            </Link>
                            <div className="px-1">
                                <p>
                                    <small>{player.title.rendered}</small>
                                </p>
                            </div>
                        </div>
                    </figure>
                )
            })}
        </div>
    )
}
export default TeamPlayersWP