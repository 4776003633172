import React, { useContext } from 'react';
import {Link} from 'react-router-dom'
import StateContext from '../../../StateContext';

function MessagesHeader() {

    const appState = useContext(StateContext)
    const messages = appState.user.appUserMessagesCount || 0

    return (
        <li className="nav-item">
            <Link to="/bp/messages" className={"nav-link header-chat-icon " + (messages ? "text-danger" : "text-dark")}>
                <i className="far fa-paper-plane"></i>

                {messages ? <span className="chat-count-badge text-white">{messages < 10 ? messages : "9+"}</span> : ""}
            </Link>  
        </li>
    )
}
export default MessagesHeader