import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'

function LeagueSeason(props) {

    const [isLoading, setIsLoading] = useState(true)
    const [leagueSeasons, setLeagueSeasons] = useState()

    useEffect(() => {

        async function fetchData() {

            setIsLoading(true)

            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/leagues/seasonsAvailable/" + props.id,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })
                console.log(response.data.api)
                setLeagueSeasons(response.data.api.leagues)
            } catch (error) {
                console.log(error)
            }

            setIsLoading(false)

        }
        fetchData()

    }, [])

    if (isLoading) return <p>Laster....</p>

    return (
        <div className="my-4">
            League seasons
            {leagueSeasons.map((league, index) => {
                return (
                    <div key={index} className="card card-body mt-2">
                        <Link to={`/football/league/${league.league_id}`}> <img src={league.logo} className="img-fluid" style={{maxWidth: "25px"}} /> {league.name} {league.season}</Link>
                    </div>
                )
            })}
        </div>
    )
}
export default LeagueSeason