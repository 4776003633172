import React, { useContext, useEffect, useState } from 'react'
import StateContext from '../../../StateContext'
import Axios from 'axios'
import Loader from '../../Loader/Loader'


function EditGroupDetails(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [group, setGroup] = useState()

    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [status, setStatus] = useState("public")

    const [posted, setPosted] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        async function fetchGroup() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/' + props.groupId, {headers: headers})
                if (response.data) {
                    setGroup(response.data[0])
                    setName(response.data[0].name)
                    setDescription(response.data[0].description.raw)
                    setStatus(response.data[0].status)

                }
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchGroup()

    }, [])

    async function submitForm(e) {
        e.preventDefault();

        try {
            const response = await Axios.put(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/' + props.groupId, 
            {
                context: 'edit', 
                creator_id: appState.user.appUserId, 
                name: name, 
                description: description,
                status: status
            }, 
            {
                headers: headers
            }
            )
            console.log(response)
            setPosted(true)
        } catch (error) {
            console.log(error)
        }
    }

    if (isLoading) return <Loader/>

    if (!group) return <p>Ingen gruppe funnet</p>

    return (
        <>
        <br/>
        <div className="my-3">
            {console.log(group)}
            <div className="card">
                <div className="card-header">Rediger gruppe</div>
                <div className="card-body">
                    {posted && <div className="alert alert-success">Gruppe redigert!</div>}
                    <form onSubmit={submitForm}>
                        <input onChange={e => setName(e.target.value)} value={name} placeholder="Gruppens navn" className="form-control mb-2" />
                        <textarea onChange={(e) => setDescription(e.target.value)} value={description} placeholder="Gruppebeskrivelse" className="form-control mb-2" />

                        <p>Foreløpig status: {status}</p>
                        <select onChange={e => setStatus(e.target.value)} className="form-control mb-2">
                            <option value="public">Offentlig</option>
                            <option value="private">Lukket</option>
                            <option value="hidden">Skjult</option>
                        </select>

                        <button className="btn btn-success btn-rounded">Rediger</button>
                    </form>
                </div>
            </div>
        </div>
        </>
    )


}
export default EditGroupDetails