

import React, {useState, useEffect} from 'react'

import Axios from 'axios'
import LiveFixtureTemplateResponsive from './LiveFixtureTemplateResponsive';

function FixturesByDate() {

    const [fixtures, setFixtures] = useState()
    const today = new Date();

    let day, month, year
    month = today.getMonth()+1
    if (month < 10) {
        month = `0${month}`
    } else {
        month = month;
    }
    if (today.getDate() < 10) {
        day = `0${today.getDate()}`
    } else {
        day = today.getDate()
    }

    let todayFormatted = `${today.getFullYear()}-${day}-${month}`
    let todayFormattedUTC =  `${today.getFullYear()}-${month}-${day}`

    const [date, setDate] = useState(todayFormattedUTC)

    useEffect(() => {

        async function fetchLiveData() {

            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/fixtures/date/" + date,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    },"params":{
                    "timezone":"Europe/London"
                    }
                })
                if (response.data) {
                    console.log(response.data.api.fixtures)
                    setFixtures(response.data.api.fixtures)
                }
                

            } catch (error) {
                console.log(error)
            }

        }
        fetchLiveData()

    }, [date])

    return (
        <div className="container">
            <div className="col-md-4 my-3">
                <input className="form-control-lg" type="date" value={date} onChange={e => {setDate(e.target.value)}} /> 
            </div>
            
            
            {fixtures && fixtures.map((fixture, index) => {
                return (
                    <div key={index}>
                        <LiveFixtureTemplateResponsive index={index} fixture={fixture} />
                    </div>
                )
            })}
            
        </div>
    )
}
export default FixturesByDate