import React from 'react';
import {Link} from 'react-router-dom'

function MessageThreadAvatars(props) {

    const recipientsThread = props.recipientsThread 

    return (
        <div className="row no-gutters">
            {recipientsThread.map((recipient, index) => {
                return (
                    <div key={index} className="col-2 col-md-2 col-lg-1">
                        <Link key={recipient[1].id} to={`/bp/members/${recipient[1].user_id}`}>
                            <img alt="avatar-messagethread" className="avatar rounded-circle" style={{maxHeight: "3rem", marginRight: "-3rem"}} src={recipient[1].user_avatars.full} />
                        </Link>
                    </div>
                )
            })}
        </div>
    )

}
export default MessageThreadAvatars