import React, { useEffect, useContext, useState, useRef } from 'react'
import Axios from 'axios'
import StateContext from '../../../StateContext'
import DispatchContext from '../../../DispatchContext'
import { Link } from 'react-router-dom'

function MemberFriendRequests(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [friendRequests, setFriendRequests] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [isConfirmed, setIsConfirmed] = useState(false)
    const [confirmError, setConfirmError] = useState(false)

    const [isRejected, setIsRejected] = useState(false)
    const [rejectError, setRejectError] = useState(false)

    const confirmFriendshipForm = useRef(null)
    const rejectFriendshipForm = useRef(null)


    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {
        async function getMemberFriendRequests() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/friends?_embed&is_confirmed=0", 
                        {
                        data: {
                            context: "view",
                            'user_id': props.id,
                            'is_confirmed': 0
                        }, 
                        headers: headers
                    })
                setFriendRequests(response.data)
                console.log(response.data, "     FRIENDS MEMBERFRIENDREQUESTS")
            } catch (error) {
                console.log("getMemberFriendRequestError: ", error)
            }
            setIsLoading(false)
        }
        getMemberFriendRequests()
        
    }, [])

    async function confirmFriendship(e) {
        e.preventDefault()

        const form = confirmFriendshipForm.current

        const friendshipInitiatorId = form['friendshipInitiatorId'].value

        try {
            const response = await Axios.put(appState.wordpressBaseUrl + "wp-json/buddypress/v1/friends/" + friendshipInitiatorId, 
                {
                    context: "edit"
                },
                {headers: headers}
            );
            setIsConfirmed(true)
        } catch (error) {
            setConfirmError(true)
        }


    }

    // This function deletes friend requests
    async function rejectFriendship(e) {
        e.preventDefault()

        const form = rejectFriendshipForm.current
        const friendshipRemoveId = form['friendshipId'].value

        try {
            const response = await Axios.delete(appState.wordpressBaseUrl + "wp-json/buddypress/v1/friends/" + friendshipRemoveId, 
                {
                    context: 'edit',
                    force: true
                },
                {
                    headers: headers
                }               
            )
            //console.log("Status reject friendship: ", response.data)
            appDispatch({type: "flashMessage", value: "Vennskapsforespørsel fjernet"})
            setIsRejected(true)
        } catch (error) {
            //console.log(error)
            setRejectError(true)
        }

    }

    function formatDate(date) {

        let month = date.getMonth()
        month = month + 1;

        const dateFormattedNor = date.getDate() + "/" + month + "/" + date.getFullYear()
        //let dateFormatted = `Den ${date.getDate()}/${date.getMonth()}/${date.getFullYear}`
        return dateFormattedNor;
    }

    if (isLoading) return <p>Laster.....</p>

    return (
        <div>
            <br/>
            {friendRequests.map(friend => {
                return (
                    <div key={friend.id} >
                        {friend.is_confirmed ? "" : 
                            <div className="card card-body mb-3">
                                {!friend.is_confirmed && <>
                                    {friend.initiator_id == appState.user.appUserId ? 
                                    <div className="row">
                                        <div className="col-3 col-lg-2">
                                            <img src={friend._embedded.initiator[0].avatar_urls.full} alt="Initiator avatar" className="img-fluid rounded-circle" />
                                        </div>

                                        <div className="col-9 col-lg-10">
                                            {"Venter på bekreftelse fra : " + friend._embedded.friend[0].name}
                            
                                            <form onSubmit={rejectFriendship} ref={rejectFriendshipForm}>
                                                <input type="hidden" name="friendshipId" type="hidden" value={friend.id} />
                                                <button className="btn btn-sm btn-danger btn-rounded">Fjern forespørsel</button>
                                                {isRejected && <div className="alert alert-success">Forespørsel avvist</div>}
                                                {rejectError && <div className="alert alert-danger">Kunne ikke fjerne</div>}
                                            </form>
                                        </div>                                                                                   
                                        
                                    </div> : 
                                    
                                        <>
                                        {isConfirmed ? <div className="alert alert-success">Venneforespørsel godtatt</div> : 
                                        
                                            <div className="row">
                                                <div className="col-3 col-lg-2">
                                                    <Link to={`/bp/members/${friend.initiator_id}`}>
                                                        <img src={friend._embedded.initiator[0].avatar_urls.full} alt="Initiator avatar" className="img-fluid rounded-circle" />
                                                    </Link>
                                                </div>
                                                <div className="col-9 col-lg-10">
                                                    <p>
                                                        <small>
                                                            <span className="font-weight-bold">{friend._embedded.initiator[0].name} </span>
                                                            <span className="float-right">   <span className="btnText">Dato for forespørsel: </span>{formatDate(new Date(friend.date_created))}</span>
                                                        </small>                                                       
                                                    </p>

                                                    <div className="row">
                                                        <div className="col-6">
                                                            <form onSubmit={confirmFriendship} ref={confirmFriendshipForm}>
                                                                <input id="friendshipInitiatorId" name="friendshipInitiatorId" type="hidden" value={friend.initiator_id} className="form-control mb-2" />
                                                                <button className="btn btn-primary btn-sm btn-rounded btn-block">Bekreft <span className="btnText">vennskap</span></button>
                                                            </form>
                                                        </div>
                                                        <div className="col-6">
                                                            <button className="btn btn-light btn-sm btn-rounded btn-block">Slett</button>
                                                        </div>
                                                    </div>                                              
                                                    
                                                    <br/>
                                                    {confirmError && <div className="alert alert-danger">Noe gikk galt, kunne ikke bekrefte</div>}
                                                </div>
                                            </div>                                             
                                        
                                        }
                                        </>
                                    
                                    }
                                </>}
                                
                            </div>
                        }
                    </div>
                )
            })}
            <br/>
        </div>
    )

}
export default MemberFriendRequests