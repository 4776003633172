import React, { useEffect, useState } from 'react'
import Axios from 'axios';

function PlayerStatsSeason(props) {

    const [playerStats, setPlayerStats] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchData() {
            if (props.currentSeason && props.currentSeason.length > 3) {
  
                setIsLoading(true)
                try {
                    const response = await Axios({
                        "method":"GET",
                        "url":"https://api-football-v1.p.rapidapi.com/v2/players/team/" + props.id + "/" + props.currentSeason,
                        "headers":{
                        "content-type":"application/octet-stream",
                        "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                        "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                        "useQueryString":true
                        }
                    })
                    if (response.data.api.players) {
                        console.log(response.data.api)
                        setPlayerStats(response.data.api.players)
                    }
                } catch (error) {
                    console.log(error)
                }
                setIsLoading(false)

            }

        }
        fetchData();

    }, [props.id, props.currentSeason])

    console.log(props.currentSeason, "2019/2020")
    console.log(props.leagueName)

    let playerStatsLeague
    if (playerStats && props.leagueName) {
        playerStatsLeague = playerStats.filter(player => {
            console.log(player.league, "includes ", props.leagueName, player.league.includes(props.leagueName))
            let string = player.league
            return string.includes(props.leagueName)
        })
    } else {
        playerStatsLeague = playerStats
    }

    if (isLoading || !props.leagueName) return <p>Laster..</p>

    return (
        <div className="table-responsive bg-white">
            <h1>{props.leagueName}</h1>
            <table className="table table-bordered table-hover table-striped">
                <thead>
                    <tr>
                        <th>Player</th>
                        <th>League</th>
                        <th>GP</th>
                        <th>G</th>
                        <th>A</th>
                    </tr>
                </thead>
                <tbody>
                    {playerStatsLeague && playerStatsLeague.map((player, index) => {
                        return (
                            <tr key={index}>
                                <td>{player.player_name}</td>
                                <td>{props.leagueName} </td>
                                <td>{player.games.appearences}</td>
                                <td>{player.goals.total}</td>
                                <td>{player.goals.assists}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )

}
export default PlayerStatsSeason