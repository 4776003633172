import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'

function LeaguesByCountry(props) {

    const [leagues, setLeagues] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchLeagues() {
            setIsLoading(true)
            try {
                const response = await Axios.get("https://api-football-v1.p.rapidapi.com/v2/leagues/country/" + props.country + "/2019", 
                {headers: {
                    "x-rapidapi-key": "4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45"
                }}
                )
                if (response.data) {
                    setLeagues(response.data.api.leagues)
                }
                console.log(response.data, "PREDICTIONS");
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false)

        }
        fetchLeagues()

    }, [])

    if (isLoading) return <p>Laster...</p>

    return (
        <div >
            <h2>{props.country}</h2>

            {leagues ? 
            <>

            {leagues.length ? 
            leagues.map(league => {
                return (
                    <div key={league.league_id} className="mb-3 card card-body">
                        <Link to={`/football/league/${league.league_id}`}>{league.name} <span><img style={{maxWidth: "20px"}} src={league.logo} /></span></Link>
                    </div>
                )
            })
            : ""
            }
            </>

            : <p>No leagues 
                {console.log(leagues)}
            </p>
        
            
        } 
        
        </div>
    )

}
export default LeaguesByCountry