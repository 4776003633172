import React from 'react'

import CardImage from '../ReusableComponents/CardImage';

function HomeCard() {
    return (
        <>
        <div className="container">
            <div className="row my-3">
                <div className="col-md-6 mb-2">
                    <CardImage 
                        imageUrl="https://images.pexels.com/photos/177557/pexels-photo-177557.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" 
                        cardTitle="Nyheter"
                        cardDescription="Følg siste nytt fra samfunnet og folk du er venner med, oppdateringer fra nettsiden og mer"
                        btnText="Vis innlegg"
                        cardLink="/innlegg/1"
                        btnColor="primary"
                        cardCat="Nyheter"
                    />
                </div>
                <div className="col-md-6 mb-2">
                    <CardImage 
                        imageUrl="https://images.pexels.com/photos/33129/popcorn-movie-party-entertainment.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" 
                        cardTitle="Filmer og serier"
                        cardDescription="Finn filmer og serier, og legg de til i din favorittliste, og del de med dine venner!"
                        btnText="Vis gallerier"
                        cardLink="/movies/1"
                        cardCat="Media"
                        btnColor="warning"
                    />
                </div>                
                <div className="col-md-6 mb-2">
                    <CardImage 
                        imageUrl="https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" 
                        cardTitle="Aktivitet"
                        cardDescription="Delta i det sosiale forumet, legg til venner, opprett grupper og mye mer!"
                        btnText="Vis aktivitet"
                        cardLink="/bp/activity"
                        btnColor="success"
                        cardCat="Social"
                    />
                </div>
                <div className="col-md-6 mb-2">
                    <CardImage 
                        imageUrl="https://images.pexels.com/photos/47730/the-ball-stadion-football-the-pitch-47730.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" 
                        cardTitle="Fotballstats"
                        cardDescription="Følg med på dine favorittlag og ligaer, siste statistikk og oppdateringer!"
                        btnText="Vis ligaer"
                        cardLink="/football/countries"
                        cardCat="Fotball"
                        btnColor="success"
                    />
                </div>
            </div>
        </div>
        </>
    )
}
export default HomeCard