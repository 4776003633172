import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'

import TeamsByLeague from './TeamsByLeague'
import LatestFixturesLeague from './League/LatestFixturesLeague'
import UpcomingFixturesLeague from './League/UpcomingFixturesLeague'
import LeagueStandings from './League/LeagueStandings'
import LeagueSeason from './League/LeagueSeasons'
import ViewJarallax from '../View/ViewJarallax'
import LeagueTopScorer from './League/LeagueTopScorer'
import FixturesLeagueDate from './Fixture/FixturesLeagueDate'

function SingleLeague() {

    const {id} = useParams()
    const {season} = useParams()

    const [league, setLeague] = useState()
    const [isLoading, setIsLoading] = useState(true)

    const [activeItem, setActiveItem] = useState("1")

    useEffect(() => {

        async function fetchSingleLeague() {
            setIsLoading(true)
            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/leagues/league/" + id + "/" + season,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                    })
                if (response.data) {
                    setLeague(response.data.api.leagues[0])
                    console.log(response.data)
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchSingleLeague()

    }, [id, season])

    function toggle(tab) {
        if(activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    if (isLoading) return <p>Laster...</p>

    return (
        <>
        <ViewJarallax logo={league.logo} text={`${league.country} ${league.name} ${league.season}`} />
        <div className="bg-light">
            <div className="container">

                <div className="row mb-4 bg-white" style={{borderTop: "1px solid #e3e3e3"}}>
                    <div className="column-3 mb-2">
                        <button onClick={() => toggle("1")} className={activeItem === "1" ? "btn-block text-dark p-2 z-depth-1 buttonTransparent" : "btn-block grey-text p-2 buttonTransparent"}>Teams</button>
                    </div>
                    <div className="column-3 mb-2">
                        <button onClick={() => toggle("2")} className={activeItem === "2" ? "btn-block text-dark p-2 z-depth-1 buttonTransparent" : "btn-block grey-text p-2 buttonTransparent"}>Fixtures</button>
                    </div>
                    <div className="column-3">
                        <button onClick={() => toggle("3")} className={activeItem === "3" ? "btn-block text-dark p-2 z-depth-1 buttonTransparent" : "btn-block grey-text p-2 buttonTransparent"}>Table</button>
                    </div>
                    <div className="column-3">
                        <button onClick={() => toggle("4")} className={activeItem === "4" ? "btn-block text-dark p-2 z-depth-1 buttonTransparent" : "btn-block grey-text p-2 buttonTransparent"}>Seasons</button>
                    </div>
                    <div className="column-3">
                        <button onClick={() => toggle("5")} className={activeItem === "5" ? "btn-block text-dark p-2 z-depth-1 buttonTransparent" : "btn-block grey-text p-2 buttonTransparent"}>Top scorer</button>
                    </div>
                </div>

                {activeItem === "1" && <TeamsByLeague season={season} id={id} />}
                {activeItem === "2" && <><UpcomingFixturesLeague id={id} /> <LatestFixturesLeague season={season} id={id} /><FixturesLeagueDate id={id}/></>}
                {activeItem === "3" && <LeagueStandings season={season} name={league.name} id={id} />}
                {activeItem === "4" && <LeagueSeason season={season} id={id} />}
                {activeItem === "5" && <LeagueTopScorer id={id} />}
                
            </div>

            <br/>
        </div>
        </>
    )

}
export default SingleLeague