import React, { useContext } from 'react';
import MemberFriendRequests from './MemberFriendRequests';
import MemberGroupInvites from './MemberGroupInvites'
import StateContext from '../../../StateContext';

function InvitesPage(props) {

    const appState = useContext(StateContext)

    const userId = appState.user.appUserId

    return (
        <div className="container my-3">
            <h4>Venneforespørsler</h4>
            <MemberFriendRequests userId={userId} />
            <h4>Gruppeinvitasjoner</h4>
            <MemberGroupInvites userId={userId} />
        </div>
    )

}
export default InvitesPage