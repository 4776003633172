import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'

function TeamSquad(props) {

    const [squad, setSquad] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchSquad() {

            setIsLoading(true)

            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/players/squad/" + props.id +"/2019-2020",
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })
                if (response.data) {
                    setSquad(response.data.api.players)
                }
                //console.log(response.data.api.players)
            } catch (error) {
                console.log(error)
            }

            setIsLoading(false)

        }
        fetchSquad()

    }, [])

    if (isLoading) return <p>Laster spillere...</p>

    return (
        <div>
            {squad.map(player => {
                return (
                    <div style={{fontSize: "13px"}} className="row bg-white z-depth-1 p-3" key={player.player_id}>
                        <div className="col-4 col-xl-7">
                            <Link to={`/football/players/${player.player_id}`}>{player.player_name}</Link> 
                        </div>
                        
                        <div className="col-2 col-xl-1">
                            {player.age}
                        </div>
                        <div className="col-2">
                            {player.nationality}
                        </div>
                        <div className="col-2">
                            <span className="text-right">{player.position} </span>
                        </div>
                    </div>
                )
            })}
            <br/>
        </div>
    )

}
export default TeamSquad