import React, { useEffect, useState, useContext } from 'react'
import Axios from 'axios'
import StateContext from '../../../../StateContext';
import DispatchContext from '../../../../DispatchContext';

const MovieGenres = ({mode}) => {

    // Keypress handler:
    // 1. Check if appState.activeComponent === current component
    // 2. If true, handle keypress (up and down events)
    // 3. If keypress = down, setActiveItem to activeItem + 1, else if keypress = up, setActiveItem to activeItem - 1

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [genres, setGenres] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [currentGenreId, setCurrentGenreId] = useState(null);
    const [prevGenreId, setPrevGenreId] = useState(null);
    const [genreFetchedCount, setGenreFetchedCount] = useState(0);

    const api_key = "7e21606276f58bbdfa39c8cdd8e051f5";
    const genre_url = `https://api.themoviedb.org/3/genre/movie/list?api_key=${api_key}`;

    const fetchGenreByIndex = (index, currentIndex) => {
        if (index === currentIndex) {
            console.log("Fetch current genre");
        }
    }

    const handleGenre = (genreId, index) => {
        setPrevGenreId(currentGenreId);
        setGenreFetchedCount(genreFetchedCount+1);
        setCurrentGenreId(genreId);
        setCurrentIndex(index);

    }

    useEffect(() => {
        appDispatch({type: "genreFetched", value: currentGenreId});
        appDispatch({type: "genreIndexFetched", value: currentIndex});
        appDispatch({type: "genreChanged", prevGenreId})
    }, [genreFetchedCount])

    useEffect(() => {

        if (
            appState.activeComponent === 1 && 
            currentIndex !== null && 
            appState.activeItem === currentIndex &&
            appState.genreId !== currentGenreId
        ) {
            fetchGenreByIndex(appState.activeItem, currentGenreId);
            handleGenre(currentGenreId, currentIndex);
        }

    }, [appState.activeComponent, appState.activeItem, currentIndex, currentGenreId, appState.genreId])

    useEffect(() => {

        async function fetchGenres() {
            try {
                const response = await Axios.get(genre_url);
                if (response.data) {
                    setGenres(response.data.genres);
                }

            } catch (error) {
                
            }
        }
        fetchGenres();

    }, [appState.genreId])

    return (
        <div className="">
            {genres && genres.map((genre, index) => {
                // Check if activeComponent = 1 (genre tab) && activeItem === index
                if (appState.activeComponent === 1 && appState.activeItem === index) {
                    // set timeout to limit requests
                    setTimeout(() => {
                        setCurrentIndex(index)
                        setCurrentGenreId(genre.id)
                        setGenreFetchedCount(genreFetchedCount+1)
                    }, 200)
                }
                return (
                    <div 
                        style={mode === "dark" ? {color: "white"} : {color: "black"}}
                        className={appState.activeComponent === 1 && appState.activeItem === index ? "border-solid-yellow p-3" : "p-3"}
                        onClick={() => handleGenre(genre.id, index)} 
                        key={index} 
                    >
                        <h2 className="pointer">
                            {genre.name} {appState.genreId === genre.id && <span> <i className="fas fa-caret-right"></i> </span>}
                        </h2>
                    </div>
                )
            })}

        </div>
    )

}
export default MovieGenres