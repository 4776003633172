import React, { useEffect, useContext, useState } from 'react'

import StateContext from '../../../StateContext'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import BlogCarousel from './BlogCarousel'
import SingleBlogTemplate from './SingleBlogTemplate'

function FootballBlog() {

    const appState = useContext(StateContext)
    const [posts, setPosts] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchPosts() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/football_blog?per_page=4")
                if (response.data) {
                    setPosts(response.data);
                }
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchPosts();

    }, [])

    function formatTitle(title) {

        const limit = 30;
        const newTitle = []

        if (title.length > limit) {
            title.split(" ").reduce((acc, cur) => {
                if (acc + cur.length <= limit) {
                    newTitle.push(cur)
                }
                return acc + cur.length;
            }, 0)
            
            return `${newTitle.join(" ")}...`

        }

        return title

    }


    if (isLoading) return <p>Laster...</p>

    return (
        <>
        <div className="row"> 

            <div className="col-xl-6">
                {posts && <SingleBlogTemplate size="full" formatTitle={formatTitle} post={posts[0]} />}
            </div>
            <div className="col-xl-6">
                <div className="row mb-3">
                    {posts?.map(post => {
                        return (
                            <SingleBlogTemplate formatTitle={formatTitle} post={post} />
                        )
                    })}
                </div>
            </div>
        </div>
        </>
    )

}
export default FootballBlog