import React from 'react';

function AlertMessage(props) {

    if (props.showAlert) {
        return <div className={"alert alert-" + props.alertType} >{props.alertMessage} <span className="text-primary" onClick={() => props.setShowAlert(false)}>  Lukk </span></div>
    } else {
        return <></>
    }

}
export default AlertMessage