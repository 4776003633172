import React, { useState, useContext } from 'react'
import Axios from 'axios'
import StateContext from '../../StateContext'

function AddMedia(props) {

    const appState = useContext(StateContext)

    const token = appState.user.token
    const userId = appState.user.appUserId
    console.log("USERID: ", userId)

    const [image, setImage] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const [message, setMessage] = useState()
    const [error, setError] = useState(false)

    

    const headers = {
        'Authorization': 'Bearer ' + token
    }

    async function addMedia(e) {

        e.preventDefault();
        setIsLoading(true)

        if (!image) {
            return 
        }

        const formData = new FormData()

        formData.append( 'file', image, image.name)
        
        console.log("IMAGE PATH: ", image)
        try {

            const response = await fetch(appState.wordpressBaseUrl + "wp-json/wp/v2/media", {
                method: "POST",
                headers: {
                 Authorization: "Bearer " + token
                },
                body: formData
            })
            console.log(appState.wordpressBaseUrl + "wp-json/wp/v2/media")

            if (response.status === 201) {
                setMessage("Bilde lastet opp!")
                console.log(response)
                if (props.action === "uploadToPost") {
                    props.setImageUploaded(true)
                }
            } else {
                setError(true)
                setMessage("Noe gikk galt under opplasting")
            }

        } catch (error) {
            setError(true)
            setMessage("Noe gikk galt under opplasting")
            console.log("ERROR COVER UPLOAD: " , error.message)
        }
        setIsLoading(false)
    }

    if (isLoading) return <p>Laster opp......</p>

    return (
        <>
        <br/>
        <div className="">
            <div className="">
                <div className="card-header">Velg mediefil</div>
                <div className="card-body">
                    {message && <p><span className={error ? "alert alert-danger" : "alert alert-success"}>{message}</span></p>}
                    <form onSubmit={addMedia}>
                        <p>Bilde: {image ? image.name : "Ikke valgt"}</p>
                        <input className="form-control" onChange={(e) => setImage(e.target.files[0])} type="file" name="image" />
                        <button className="btn btn-primary btn-block" disabled={isLoading || !image} type="submit">
                            <i className="far fa-image" aria-hidden="true"></i>Last opp bilde
                        </button>
                    </form>
                </div>
            </div>
        </div>
        <br/>
        </>
    )

}
export default AddMedia