import React, {useEffect, useState, useContext} from 'react'
import Axios from 'axios'
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom'
import StateContext from '../../StateContext'

function RecipeCategories() {

    const appState = useContext(StateContext)

    const [categories, setCategories] = useState([])
    const [isLoading, setIsloading] = useState(false)

    useEffect(() => {
        async function getBlogCategories() {
            setIsloading(true)
            try {

                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/categories?_embed");
                if (response.data) {
                    console.log(response.data)
                    setCategories(response.data)
                } else {
                    console.log("Klarte ikke hente innlegg")
                }               
            } catch (error) {
                console.log(error)
            }
            setIsloading(false)
        }
        getBlogCategories()
    }, [])

    if (isLoading) return <Loader />

    return (
        <div className="bg-light">
            <div className="container py-5">
                <div className="row">
                    {categories.map(category => {
                        return (
                            <div key={category.id} className="col-lg-6 mx-auto">
                                {category.name != "Ukategorisert" &&
                                <div className="card mb-2 card-image" style={{backgroundImage: `url(${category.acf.kategori_bilde})`}}>                                         
                                    <div className="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
                                        <div>
                                            <h2 className="text-white text-center content-title h2-responsive py-5"><Link to={`/category/${category.id}`}>{category.name}</Link></h2>   
                                        </div>                 
                                    </div>                            
                                </div> }
                            </div>         
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default RecipeCategories