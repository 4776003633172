import React from 'react'

function Unauthorized(props) {
    return (
        <div className="col-md-6 mx-auto my-5">
            <div className="card">
                <div className="card-header">Kunne ikke vise side</div>
                <div className="card-body">
                    <p>{props.message}</p>
                </div>
            </div>
        </div>
    )
}
export default Unauthorized