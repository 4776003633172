import React, { useContext, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StateContext from '../../../StateContext'
import Axios from 'axios'


function AddGroupActivity(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [activityContent, setActivityContent] = useState()
    const [value, setValue] = useState('');

    const [posted, setPosted] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    
    const modules = {
        'image-tooltip': true,
        'link-tooltip': true
    }

    async function handleSubmit(e) {

        e.preventDefault()

        setIsLoading(true)
        try {
                
            const response = await Axios.post(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/activity', 
                {
                    context: 'edit',
                    component: 'groups',
                    content: value,
                    primary_item_id: props.groupId
                }, 
                {
                    headers: headers
                }
            )
            if (response.data) {
                setPosted(true)
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)

    }
    

    return (
        <div className="row">
            <div className="card my-2" style={{width: "100%"}}>
                
                {posted && <p><span className="alert alert-success">Aktivitet lagt til</span></p>}

                <form onSubmit={handleSubmit}>
                    <ReactQuill theme="snow" value={value} onChange={setValue} />
                    <button disabled={isLoading} className="btn btn-sm btn-primary">Send</button>
                </form>
            </div>
        </div>

    )

}
export default AddGroupActivity