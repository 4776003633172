import React, {useState, useEffect, useContext} from 'react'
import {useParams} from 'react-router-dom'
import Axios from 'axios'

import SaveMovie from '../Movie/SaveMovie'
import StateContext from '../../../StateContext'
import AddStreamMovie from '../Movie/AddStreamMovie'

function SingleTV() {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [moviesLoaded, setMoviesLoaded] = useState(false);
    const [movie, setMovie] = useState()

    const [isShowAdded, setIsShowAdded] = useState(false)
    const [postError, setPostError] = useState(false)

    const api_key = "7e21606276f58bbdfa39c8cdd8e051f5"
    const {id} = useParams()
    const username = localStorage.getItem("appUsername")

    //console.log("Movie: ", movie)

    const getYear = (date) => {
        return new Date(date).getFullYear()
    }


    useEffect(() => {

        // Check if logged in user has saved show in db
        async function isShowSaved() {
            
            try {
                
                const response = await Axios.post("https://backend-react-complex-movieapp.herokuapp.com/userShows/" + id, {
                    username: localStorage.getItem("appUsername")
                })
                console.log(response)
                if (response.data) {
                    console.log("isShowSaved: ", response.data)
                    setIsShowAdded(true)
                } else {
                    console.log("Serie er IKKE lagt til")
                    setIsShowAdded(false)
                }

            } catch (error) {
                console.log(error)
            }
        }
        isShowSaved()

    }, [isShowAdded])

    


    useEffect(() => {
        async function getSingleTV() {
            setIsLoading(true)
            try {
                const response = await fetch(`https://api.themoviedb.org/3/tv/${id}?api_key=${api_key}`);
                const data = await response.json()
                if (data) {
                    console.log("Filmdata: ", data)
                    setMovie(data);
                    setMoviesLoaded(true)
                    setIsShowAdded(true)
                } else {
                    console.log("Kunne ikke laste film")
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        getSingleTV()
    }, [id])

    

    if (isLoading) return <div>Laster...</div>

    return (
        <>
        
        {moviesLoaded &&
            <div 
                className="background-image" 
                style={{
                    backgroundImage: "url('https://image.tmdb.org/t/p/w1280/" + movie.backdrop_path + "')",
                    backgroundSize: "cover",
                    backgroundColor: "rgba(0, 0, 0, 0.9)"
                }} 
            >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.7)"}}>
                    <div className="container py-4">
                        <div className="row">
                            <div className="col-md-4">
                                
                                {movie.poster_path != null ? 
                                    <img src={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} className="img-fluid" alt="tv-poster" /> : 
                                    <img src={`https://complexmovieapp.herokuapp.com/images/film-poster-placeholder.png`} alt="tv-poster" className="img-fluid" />}
                            </div>
                            <div className="col-md-8 text-white">
                                <div className="container">

                                    <h2>{movie.original_name} <span className="grey-text"><small>{getYear(movie.first_air_date)}</small></span></h2>
            
                                    <h3 className="my-4 font-weight-bold">
                                        <span className="bg-success px-2 mr-2">{movie.vote_average}</span>
                                        <span>Brukeranmeldelser</span>
                                    </h3>

                                    <h4 className="font-weight-bold">Oversikt</h4>
                                    <p>{movie.overview}</p>

                                    <SaveMovie movieTitle={movie.original_name} movieId={id} userId={appState.user.appUserId} mediaType="serie" movieImageUrl={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} />

                                    <div className="my-2">
                                        <a href={`https://www.imdb.com/title/${movie.imdb_id}`}>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/6/69/IMDB_Logo_2016.svg" alt="imdb-logo" className="img-fluid" style={{maxWidth: "200px"}} />
                                        </a>
                                    </div>
                                    
                                    

                                </div>
                            </div>

                            <div>
                            <AddStreamMovie id={id} mediaType="serie" title={movie.original_name} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    
    )

}
export default SingleTV