import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { useParams } from 'react-router-dom'
import ViewJarallax from '../View/ViewJarallax'
import ViewJarallaxPlayer from '../View/ViewJarallaxPlayer'

function SinglePlayer() {

    const {id} = useParams()

    const [player, setPlayer] = useState()
    const [seasonStats, setSeasonStats] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchPlayer() {

            setIsLoading(true)

            try {   
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/players/player/" + id,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })
                console.log(response.data.api, "SINGLE PLAYER")
                setSeasonStats(response.data.api.players)
                setPlayer(response.data.api.players[0])
                console.log(response.data.api.players[0])
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchPlayer()

    }, [id])

    if (isLoading) return <p>Laster...</p>

    return (
        <>
        <ViewJarallaxPlayer draktnummer={player.number || 99} posisjon={player.position} age={player.age} text={player.player_name} birthPlace={player.birth_place} />
        <div className="mb-1">
            
            <div className="table-responsive">
                <table className="table table-bordered table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Season</th>
                            <th>Team</th>
                            <th>League</th>
                            <th>FP</th>
                            <th>G</th>
                            <th>A</th>
                        </tr>
                    </thead>
                    <tbody>

                        {seasonStats.map((season, index) => {
                            return (
                                <tr key={index}>           
                                    
                                    <td>{season.season}</td>
                                    <td>{season.team_name}</td>
                                    <td>{season.league}</td>
                                    <td>{season.games.appearences}</td>
                                    <td>{season.goals.total}</td>
                                    <td>{season.goals.assists}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}
export default SinglePlayer