import React, { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";

import StateContext from '../../../StateContext';
import ViewJarallax from '../../View/ViewJarallax';
import SingleGroupMembers from './SingleGroupMembers';
import SingleGroupActivity from './SingleGroupActivity';
import AddGroupActivity from './AddGroupActivity';
import EditGroupCoverImage from './EditGroupCover';
import AddMembersGroup from './AddMembersGroup';
import Unauthorized from '../../Error/Unauthorized';
import EditGroupDetails from './EditGroupDetails';
import SingleGroupMembersAvatars from './SingleGroupMembersAvatars'

function SingleGroup() {

    const [activeItem, setActiveItem] = useState("1")

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }
  
    const {id} = useParams();

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [group, setGroup] = useState()
    const [groupCoverImage, setGroupCoverImage] = useState()

    const [error, setError] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {


        async function fetchGroup() {
            setIsLoading(true)
            try {

                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/' + id, { headers: headers })
                //console.log(response.data)
                if (response.data) {
                    setGroup(response.data[0])
                }
                console.log(response)

            } catch (error) {
                setError(true)
                console.log(error.status)
            }
            setIsLoading(false)

        }
        fetchGroup()

    }, [])

    useEffect(() => {

        
        async function fetchGroupCover() {

            try {
                const response = await Axios(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/' + id + '/cover', {headers: headers})
                setGroupCoverImage(response.data[0].image)
            } catch (error) {
                console.log(error)
            }

        }
        fetchGroupCover()

    }, [])

    if (!appState.user.token) return <Unauthorized message="Du må være logget inn for å se denne siden" />

    if (error) return <Unauthorized message="Noe gikk galt, prøv å laste inn på nytt" />


    return (
        <>

        {group && group.status === "hidden" && !appState.user.token  ? <p>Du må være logget inn for å se denne gruppen</p>  : <>

        {isLoading ? 
            <ViewJarallax text={group ? group.name : "Laster..."} image={groupCoverImage ? groupCoverImage : ""} /> : 
            <ViewJarallax text={group ? group.name : "Single Group"} image={groupCoverImage ? groupCoverImage : ""} />
        }


        
        <div className="col-lg-8 col-xl-6 mx-auto mt-3">
            {group &&
                <>
                
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="font-weight-bold">{group ? group.name : "Single Group"}</h2>
                            <p>
                                {group.status === "hidden" ? 
                                <><i className="fas fa-lock"></i> Skjult gruppe</> : 
                                group.status === "private" ? 
                                <><i className="fas fa-user-shield"></i> Privat gruppe</> : 
                                <><i className="fas fa-globe-asia"></i> Offentlig gruppe</> }
                                <span> · 3 medlemmer</span>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div class="d-flex justify-content-end">
                                <SingleGroupMembersAvatars groupId={id}/>
                            </div>
                        </div>
                    </div>
                </>
            }

            <hr />

            <MDBNav className="nav-tabs mt-1 bg-white">
                <MDBNavItem>
                    <MDBNavLink link to="#" active={activeItem === "1"} onClick={() => toggle("1")} role="tab" >
                        Home
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink link to="#" active={activeItem === "2"} onClick={() => toggle("2")} role="tab" >
                        Aktivitet
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink link to="#" active={activeItem === "3"} onClick={() => toggle("3")} role="tab" >
                        Medlemmer
                    </MDBNavLink>
                </MDBNavItem>
                {appState.user.token &&
                <MDBNavItem>
                    <MDBNavLink link to="#" active={activeItem === "4"} onClick={() => toggle("4")} role="tab" >
                        Rediger gruppe
                    </MDBNavLink>
                </MDBNavItem>
                }
            </MDBNav>                

        </div>


        <div className="bg-light">

            <div className="col-lg-8 col-xl-6 mx-auto">    

            <MDBTabContent activeItem={activeItem} >

                <MDBTabPane tabId="1" role="tabpanel">
                    <br/>
                    <div className="my-4 card card-body">
                        {group ? <>
                            <p>{group.status === "hidden" ? 
                            <><i className="fas fa-lock"></i> Skjult gruppe</> : 
                            group.status === "private" ? 
                            <><i className="fas fa-user-shield"></i> Privat gruppe</> : 
                            <><i className="fas fa-globe-asia"></i> Offentlig gruppe</> }</p>

                            <p>Denne gruppen er {group.status}</p>
                            <div dangerouslySetInnerHTML={{__html: group.description.rendered}}></div>
                        </> : (
                            <p className="mt-2">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Nihil odit magnam minima, soluta doloribus reiciendis
                                molestiae placeat unde eos molestias. Quisquam aperiam,
                                pariatur. Tempora, placeat ratione porro voluptate odit
                                minima.
                            </p>
                        )}
                    </div>
                    <br/>
                    
                </MDBTabPane>

                <MDBTabPane tabId="2" role="tabpanel">
                    <AddGroupActivity groupId={id} />
                    <SingleGroupActivity groupId={id} />
                </MDBTabPane>

                <MDBTabPane tabId="3" role="tabpanel">
                    <SingleGroupMembers groupId={id} />
                </MDBTabPane>

                <MDBTabPane tabId="4" role="tabpanel">
                    {appState.user.token ? (
                        <>
                        <EditGroupDetails groupId={id} />
                        <AddMembersGroup groupId={id} />
                        <EditGroupCoverImage groupId={id} />
                        </>
                    )
                    : <p>Du har ikke rettigheter til å redigere </p> }
                </MDBTabPane>

                </MDBTabContent>

        </div>

        </div>

        </>}
        </>
    )
}
export default SingleGroup