import React, {useState, useEffect, useContext} from 'react'

import MovieTemplate from './MovieTemplate'
import StateContext from '../../../../StateContext';
import DispatchContext from '../../../../DispatchContext';
import Loader from '../../../Loader/Loader';

function MoviesSixty() {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext)


    const [movies, setMovies] = useState([])
    const [moviesLoaded, setMoviesLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)




    const fetchSingleMovie = (movie) => {
        appDispatch({type: "showSingleMovie"})
        appDispatch({type: "singleMovieFetched", value: movie})
    }


    useEffect(() => {

        const api_key = "7e21606276f58bbdfa39c8cdd8e051f5"
        const url = "https://api.themoviedb.org/3/movie/popular?api_key=" + api_key + "&page=" + appState.pageCount + "&append_to_response=videos=true"
        const genreUrl = `https://api.themoviedb.org/3/discover/movie?api_key=${api_key}&with_genres=${appState.genreId}&page=${appState.pageCount}&append_to_response=videos=true`

        async function getMovies() {
            setIsLoading(true);
            try {
                let response;
                if (appState.genreId) {
                    response = await fetch(genreUrl)
                } else {
                    response = await fetch(url)
                }
                
                const data = await response.json()
                
                // Check if movies already have been loaded, and if loadMoreMovies is true
                // Also check if genreId changes, if true, reload movies array
                let moviesArray
                if (movies && appState.pageCount > 1 && !appState.genreChanged) {
                    moviesArray = movies.concat(data.results);
                    
                } else {
                    moviesArray = data.results
                }
                setMovies(moviesArray)
                setMoviesLoaded(true)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }

        setTimeout(() => {
            getMovies()
        }, 500)

        getMovies()

    }, [appState.genreId, appState.pageCount, appState.genreIndex])

    return (         
            <div>
                {moviesLoaded && <>

                    <div className="row">

                        {movies ? movies.map((movie, index) => {

                            return (
                                <MovieTemplate                                    
                                    fetchSingleMovie={fetchSingleMovie} 
                                    movie={movie} 
                                    index={index}
                                    key={index} 
                                />
                            )
                            
                        }) : isLoading ? <Loader /> :  <p style={{color: "red"}}>No movies</p>}

                    </div>                  

                </>}

                          
            </div>
    )
}
export default MoviesSixty