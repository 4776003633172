import React, { useContext, useEffect } from 'react'
import StateContext from '../../../../StateContext';

const MovieTemplate = ({movie, fetchSingleMovie, index}) => {

    const appState = useContext(StateContext)

    // movie modal shown when appState.showSingleMovie === true;
    
    useEffect(() => {

        if (appState.showSingleMovie && appState.viewSingleMovie && index === appState.activeItem && appState.activeComponent === 2) {
            fetchSingleMovie(movie);
            console.log(index === appState.activeItem, "Index of movie equals activeItem")
        }
    }, [appState.viewSingleMovie, appState.activeItem, index, appState.activeComponent, appState.showSingleMovie])


    return (
        <div 
            className={
                appState.activeComponent === 2 && appState.activeItem === index 
                ? 
                "border-solid-yellow no-padding col-md-3 mb-3" 
                : 
                "col-md-3 mb-3"
            }  
            key={movie.id}
        >
            <div className={"card"} >
                <div style={{cursor: "pointer"}} onClick={() => fetchSingleMovie(movie)}>
                    {movie.poster_path != null ? 
                    <img src={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} alt={movie.title} className="img-fluid" /> : 
                    <img src={`https://complexmovieapp.herokuapp.com/images/film-poster-placeholder.png`} alt={movie.title} className="img-fluid" />}
                </div>
            </div>
        </div>
    )
            
}
export default MovieTemplate