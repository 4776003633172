import React, { useState, useContext } from 'react'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import StateContext from '../../../StateContext'

function AddCommentPost(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(false)
    const [post, setPost] = useState();
    const [posted, setPosted] = useState(false)
    const [postError, setPostError] = useState(false)

    const token = appState.user.token

    async function addPost(e) {
        e.preventDefault()
    
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    
        try {
            setIsloading(true)
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity", 
                {
                    context: 'edit',
                    primary_item_id: props.parentPostId,
                    component: 'activity',
                    type: 'activity_comment',
                    content: post
                },
                {
                    headers: headers 
                }
            )
            if (response.data) {
                setPosted(true)
                console.log(response.data)        
            } else {
                console.log("Feil brukernavn eller passord")
                setPostError(true)
            }
            setIsloading(false)
        } catch (error) {
            console.log(error)
            setPostError(true)
            setIsloading(false)
        }
    }


    return (
        <>
        <form onSubmit={addPost}>
            {postError && <div className="alert alert-danger">Kommentar ikke sendt, vennligst prøv igjen</div>}


            <section className="sH9wk  _JgwE pl-2 pr-2">
                <div className="RxpZH">
                    <div className="X7cDz" >
                        <input type="hidden" name="parentPostId" value={props.parentPostId} /> 
                        <textarea 
                        onChange={e => setPost(e.target.value)} 
                        placeholder="Legg til en kommentar …" 
                        className="Ypffh" autoComplete="off" 
                        autoCorrect="off" 
                        style={{height: "18px"}}></textarea>
                        <button style={{backgroundColor: "transparent"}} className="sqdOP yWX7d y3zKF" disabled={isLoading || post === ""} type="submit">Publiser</button>
                    </div>
                </div>
            </section>
        </form>

        <div className="col-12">
            {posted && (
                <>
                <div className="alert alert-success">Kommentar sendt</div>

                <div className=" mb-2">
                    <div className="card-body">                                
                        <div className="row">
                            <div className="col-3 col-md-1">
                                <img src="//www.gravatar.com/avatar/954e55e8323729f816c5f80277373afb?s=50&#038;r=g&#038;d=mm" alt="user-avatar" className="img-fluid rounded-circle" /> 
                            </div>
                            <div className="col-9 col-md-11">
                                <span>Du delte:</span><br />
                                <small className="text-secondary">Akkurat nå</small>
                            </div>
                        </div>
                        
                        <br />
                        <div>{post}</div>                                                           
                    </div>
                </div>
                </>
            )}
        </div>
        </>
        
    )
}
export default AddCommentPost

