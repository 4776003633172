import React, { useEffect, useState, useContext } from 'react'
import {useParams, Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import CommentForm from './CommentForm'
import Comments from './Comments'
import View from '../View/View'
import StateContext from '../../StateContext'
import FavoriteBlogPost from './FavoriteBlogPost'
import ViewJarallax from '../View/ViewJarallax'


function SinglePost() {

    const {id} = useParams()

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(true);
    const [post, setPost] = useState([]);
    const isLoggedIn = localStorage.getItem("token")

    const [deleted, setDeleted] = useState(false);
    const [deleteError, setDeleteError] = useState(false)

    const headers =  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        async function getBlogPost() {
            setIsloading(true)
            try {

                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/posts/" + id + "?_embed");
                if (response.data) {
                    setPost(response.data)
                    console.log(response.data)
                } else {
                    console.log("Klarte ikke hente innlegg")
                }
                
            } catch (error) {

            }
            setIsloading(false)
        }
        getBlogPost()
    }, [id])

    async function deletePost(e) {
        e.preventDefault();
        const confirm = window.confirm("Er du sikker? ")

        if (confirm) {

            try {
            
                const response = await Axios(
                    {
                        method: "delete",
                        url: appState.wordpressBaseUrl + "wp-json/wp/v2/posts/" + id,
                        headers: headers
                    }
                )
                if (response) {
                    setDeleted(true)
                } 
                // End response
    
            } catch (error) {
                setDeleteError(true);
                console.log(error)
            } // End Try Catch block

        } // Confirm

    }


    if (isLoading) return  (
    
        <div className="text-center">
            <Loader />
        </div>

    )

    if (deleted) return <div className="col-md-6 mx-auto my-4"><div className="card card-body">Slettet!</div></div>

    return (
        <>
            <ViewJarallax text={post.title.rendered} image={post.acf.fremhevet_bilde_oppskrift || "https://images.pexels.com/photos/33129/popcorn-movie-party-entertainment.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} />
            <div className="container">            
                <div className="my-4">       
                    <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>           
                    <hr/>
                </div>

                {isLoggedIn && <CommentForm postId={post.id} />}             
                <Comments postId={post.id} /> 
            </div>   
        </>
    )
}
export default SinglePost