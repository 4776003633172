import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import StateContext from '../../StateContext'
import LoginForm from '../BuddyPress/Auth/LoginForm'
import Members from '../BuddyPress/Member/Members'
import Activity from '../BuddyPress/Activity/Activity'
import Groups from '../BuddyPress/Group/Groups'

function SinglePage() {

    const appState = useContext(StateContext)

    const { id } = useParams()

    const [isLoading, setIsloading] = useState(true);
    const [post, setPost] = useState([]);

    const token = appState.user.token
    let headers

    if (token) {
        headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    } else {
        headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    

    useEffect(() => {

        async function getBlogPost() {
            setIsloading(true)
            try {
                
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/pages/" + id, {headers: headers});
                if (response.data) {
                    setPost(response.data)
                    console.log(post)
                } else {
                    console.log("Klarte ikke hente side")
                }
                
            } catch (error) {
                console.log(error)
                setPost(false)
            }
            setIsloading(false)

        }
        getBlogPost()
    }, [id])

    if (isLoading) return (

        <div className="text-center">
            <Loader />
        </div>

    )

    if (!post) {
        return <div className="alert alert-danger my-4 col-md-6 mx-auto">Du må være innlogget for å se denne siden</div>
    }

    if (post.title.rendered === "Logg inn" || post.title.rendered === "Registrer") {
        return <LoginForm />
    } else if (post.title.rendered === "Medlemmer") {
        return <Members />
    } else if (post.title.rendered === "Aktivitet") {
        return <Activity />
    } else if (post.title.rendered === "Grupper") {
        return <Groups/>
    }

    return (
        <div className="container">
            <h1 className="text-center">{post.title.rendered}</h1>

            <div>

                <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>

                <hr></hr>
            </div>

        </div>

    )
}
export default SinglePage