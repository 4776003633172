import React, { useContext, useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import StateContext from '../../../StateContext';
import Axios from 'axios';
import FavoritePost from '../Activity/FavoritePost';
import AddCommentPost from '../Activity/AddCommentPost';
import DeleteGroupActivity from './DeleteGroupActivity';

function SingleGroupActivity(props) {

    const appState = useContext(StateContext)

    const [groupActivity, setGroupActivity] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [showCommentField, setShowCommentField] = useState(false)

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        async function getGroupActivity() {
            setIsLoading(true)
            try {
                
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity?display_comments=threaded&per_page=" + perPage + "&group_id=" + props.groupId, 
                {
                    headers: headers
                }
                )
                setGroupActivity(response.data)
                console.log(response.data, "  SINGLE GROUP ACTIVITY")
            } catch (error) {
                
            }
            setIsLoading(false)

        }
        getGroupActivity()

    }, [perPage])

    function getTimePost(date) {
        let datePosted = new Date(date)

        let offset = (1000 * 60 * 60 * 2)
        datePosted.getTime()
        const dateFormatted = `${datePosted.getDate()}/${datePosted.getMonth()+1}/${datePosted.getFullYear()}`
        let currentDate = new Date().getTime()
        let postedTime = datePosted.getTime()
        const minutesSincePost = Math.round( ( currentDate - (postedTime+offset) ) / (60*1000) )

        if (minutesSincePost < 60) {
            return minutesSincePost + " minutter siden";
        } else {
            return " den " + dateFormatted
        }
    }

    async function loadMore() {
        setPerPage(perPage + 5)
    }

    if (isLoading) return <p>Laster aktivitet...</p>

    return (
        <div className="mt-3">
            <div>
            {groupActivity.map(activity => {

                const date = new Date(activity.date)
                const dateFormatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}/${date.getHours()}:${date.getMinutes()}`

                return (
                    <div key={activity.id} className="row my-2">
                        <div className="card card-body">

            
                            <div className="row">

                                <div className="col-3 col-md-2">
                                    <Link to={`/bp/members/${activity.user_id}`}><img src={activity.user_avatar.thumb} alt="user-avatar" className="img-fluid rounded-circle" /></Link> 
                                </div>

                                <div className="col-9 col-md-10">
                                    <div className="row">
                                        <div className="col-9">
                                            <div dangerouslySetInnerHTML={{__html: activity.title}}></div>
                                            <small className="text-grey">{dateFormatted}</small>
                                        </div>
                                        <div className="col-3">
                                            <div className="float-right">
                                                {appState.user.token && appState.user.appUserId == activity.user_id && <DeleteGroupActivity activityId={activity.id} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />     

                            <div>
                                {activity.type === "created_group" && <p>{activity.display_name} opprettet gruppen <small> {getTimePost(activity.date)}</small></p>}
                                {activity.type === "joined_group" && <p>{activity.display_name} ble med i gruppen<small> {getTimePost(activity.date)}</small></p>}
                                {activity.type === "activity_update" &&(
                                    <>
                                        <div dangerouslySetInnerHTML={{__html: activity.content.rendered}}></div>
                                    </>
                                )}

                                <hr/>

                                <div className="row">
                                    <div className="col-4">
                                        <FavoritePost activity={activity} />
                                    </div>
                                    <div className="col-4">
                                        <div 
                                        onClick={() => {
                                            !showCommentField ? 
                                            setShowCommentField(true) :
                                            setShowCommentField(false)
                                        }}
                                        className="btn btn-outline-primary btn-block btn-responsive"><i className="far fa-comment mr-1"></i> 
                                            <span className="btnText">Kommenter </span>                               
                                            {activity.comment_count > 0 ? `(${activity.comment_count})` : ""}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="btn btn-outline-primary btn-block btn-responsive"><i className="fas fa-share mr-1"></i> 
                                            <span className="btnText">DEL</span>
                                        </div>
                                    </div>
                                </div>

                                {showCommentField && (
                                    <>
                                    <hr/>
                                    <AddCommentPost parentPostId={activity.id} commentsPost={activity.comments} />
                                    </>
                                )}    

                            </div>  
                        </div>                     
                    </div>
                )
            })}
            <br/>
            <div className="col-md-6 mx-auto my-3">
                <button onClick={loadMore} className="btn btn-lg btn-block btn-success">Eldre</button>
            </div>
            <br/>
            </div>
        </div>
    )

}
export default SingleGroupActivity