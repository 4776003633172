import React, { useContext, useEffect, useState } from 'react'
import Axios from 'axios';
import StateContext from '../../StateContext';

function FavoriteBlogPost(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(true)
    const [postActivity, setPostActivity] = useState()
    const [postActivityId, setPostActivityId] = useState()
    const [liked, setLiked] = useState(false)
    const [likeCount, setLikeCount] = useState(0)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        async function fetchPostActivity() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/activity?secondary_id=' + props.postId, {headers: headers})
                setPostActivity(response.data, "           POSTACTIVITY")
                setPostActivityId(response.data[0].id)
                setLiked(response.data[0].favorited)
                setLikeCount(response.data[0].favorites)

                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchPostActivity()

    }, [props.postId])

    async function favoritePost(e) {
        e.preventDefault();

        try {
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity/" + postActivityId + "/favorite", {context: 'edit'}, {headers: headers})
            console.log(response)
            setLiked(response.data[0].favorited)
            setLikeCount(response.data[0].favorites)
        } catch (error) {
            console.log(error)
        }
    }

    if (isLoading) return <i className="fas fa-heart fa-3x"></i>

    return (

        <div>
            <form onSubmit={favoritePost}>
                <button 
                    style={{backgroundColor: "transparent", border: "none"}} 
                    disabled={isLoading}
                >
                    <i className={liked ? "fas fa-heart fa-3x text-danger" : "fas fa-heart fa-2x"}></i> {postActivity && postActivity[0] && +likeCount}
                </button>
            </form>
        </div>

    )
}
export default FavoriteBlogPost