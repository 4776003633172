import React, { useEffect, useState, useContext } from 'react'
import {Link, useParams} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import AddPost from './AddPost'
import LoginForm from '../Auth/LoginForm'
import AddCommentPost from './AddCommentPost'

import DispatchContext from '../../../DispatchContext'
import StateContext from '../../../StateContext'
import FavoritePost from './FavoritePost'
import SingleActivityTemplate from '../Templates/SingleActivityTemplate'

function SingleActivity(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const {id} = useParams()

    const token = appState.user.token
    const [isLoading, setIsloading] = useState(true);
    const [activity, setActivity] = useState();
    const [message, setMessage] = useState("");
    const [showCommentField, setShowCommentField] = useState(false)
    
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    

    useEffect(() => {

        async function getActivity() {
  
            try {
                //console.log(page)
                setIsloading(true)
                const response = await Axios.get(
                    appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity/" + id +"?display_comments=threaded", 
                        {
                            data: {
                                context: 'view'
                            },
                            headers: headers
                        }
                    );
                if (response.data) {
                    setActivity(response.data[0])
                    console.log(response.data[0])
                } else {
                    setActivity()
                    console.log("Klarte ikke hente aktivitet")
                }
                setIsloading(false)
            } catch (error) {
                setActivity()
            }
            setIsloading(false)

        }
        getActivity();
    }, [id])

    if (isLoading) return (

        <div>
            <Loader />
        </div>

    )
    let dateFormatted
    if (!isLoading) {
        const date = new Date()
        dateFormatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}/${date.getHours()}:${date.getMinutes()}`
    } else {
        const date = new Date()
        dateFormatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}/${date.getHours()}:${date.getMinutes()}`
    }


    return (
        <div className="bg-light">
            <div className="container">
                <h1 className="text-center">Activity page</h1>

                {appState.loggedIn && activity ? 
                    <>
                    <div className="my-3">
                        <Link to="/bp/activity"><i className="fas fa-backward"></i> Tilbake til aktivitetsstrøm</Link>
                    </div>

                    <SingleActivityTemplate activity={activity} date={dateFormatted} isSingle={true} />
                    <br/>
                    </> 
                : 
                    <><div className="alert alert-danger">{message}</div><LoginForm /></>
                }
            </div>
        </div>

    )
}
export default SingleActivity