import React, { useState, useContext } from 'react'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import StateContext from '../../StateContext'

function CommentForm(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(false)
    const [comment, setComment] = useState("");
    const [commented, setCommented] = useState(false)
    const [commentError, setCommentError] = useState(false)

    const token = localStorage.getItem("token")

    async function commentPost(e) {
        e.preventDefault()

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }

        try {
            setIsloading(true)
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/wp/v2/comments?post=" + props.postId, 
                { content: comment },
                {headers: headers }
            )
            if (response.data) {
                setCommented(true)
                console.log(props.postId)        
            } else {
                console.log("Feil brukernavn eller passord")
                setCommentError(true)
            }
            setIsloading(false)
        } catch (error) {
            console.log(error)
            setCommentError(true)
            setIsloading(false)
        }
    }

    if (isLoading) return <Loader />

    if (commentError) {
        return (
            <>
                <div className="alert alert-danger">Kommentar kunne ikke sendes. Prøv igjen</div>         
                <form onSubmit={commentPost}>
                    <textarea required className="form-control mb-2" placeholder="Din kommentar" onChange={e => setComment(e.target.value)}></textarea>
                    {comment 
                        ? <button className="btn btn-dark">Publiser kommentar</button> 
                        : <div className="alert alert-warning">Vennligst fyll ut kommentarfeltet for å kommentere oppskriften</div>
                    }
                    
                </form>   
            </>
        )
    }
    if (commented) {
        return  (
            <>
                <div className="alert alert-success">Kommentar sendt</div>
                <div className="bg-light p-3">
                    <p>
                        <strong>Din kommentar:</strong>
                        <small className="pull-right text-muted">
                            <i className="far fa-clock mx-1"></i> Akkurat nå
                        </small>
                    </p>
                    <hr />
                    <div>{comment}</div>
                </div>
            </>
        )
    }

    return (
        <form onSubmit={commentPost}>
            <textarea required className="form-control mb-2" placeholder="Din kommentar" onChange={e => setComment(e.target.value)}></textarea>
            {comment 
                ? <button className="btn btn-dark">Publiser kommentar</button> 
                : <div className="alert alert-warning">Vennligst fyll ut kommentarfeltet for å kommentere oppskriften</div>
            }
            
        </form>   
    )
}
export default CommentForm