import React, { useState, useContext } from 'react'
import Axios from 'axios'
import StateContext from '../../StateContext'
import DispatchContext from '../../DispatchContext'

function AddAvatarImage() {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const token = appState.user.token

    const userId = appState.user.appUserId

    const [message, setMessage] = useState()

    const [error, setError] = useState(false)
    const [uploaded, setUploaded] = useState(false)
    const [image, setImage] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const headers = {
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
    }

    async function editAvatarImage(e) {

        e.preventDefault();
        setIsLoading(true)

        if (!image) {
            return 
        }

        const formData = new FormData()

        formData.append( 'context', 'edit' );
        formData.append( 'action', 'bp_avatar_upload' );
        formData.append( 'file', image, image.name)

        
        try {
            
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/members/" + userId + "/avatar", formData, 
                {
                    headers: headers
                }
            )
            if (response.data) {
                console.log(response)
                appDispatch({type: "flashMessage", value: "Avatar lastet opp!"})
                setUploaded(true);
                setMessage("Bilde lastet opp!")
            } else {
                console.log("No response");
                setMessage("Bilde ikke lastet opp!")
            }

        } catch (error) {
            console.log("ERROR COVER UPLOAD: " , error.message)
            setError(true);
            setMessage("Bilde ikke lastet opp!")
        }
        setIsLoading(false)
    }

    if (isLoading) return <p>Laster opp......</p>

    return (
        <>
        <br/>
        <div className="card my-5">
            {uploaded && <div className="alert alert-success text-center">Lastet opp!</div>}
            {error && <div className="alert alert-danger text-center">Kunne ikke laste opp, vennligst prøv igjen</div>}
            <div className="card-header">Velg Avatar</div>
            <div className="card-body">
                <form onSubmit={editAvatarImage}>
                    <p>Bilde: {image ? image.name : "Ikke valgt"}</p>
                    <input className="form-control-file" onChange={(e) => setImage(e.target.files[0])} type="file" name="image" />

                    {!image ? <div className="alert alert-danger text-center">Velg et bilde</div> : <div className="alert alert-success text-center">Bilde valgt</div>}
                    <button className="btn btn-primary btn-block" disabled={isLoading || !image} type="submit">Last opp bilde</button>
                </form>
            </div>
        </div>
        <br/>
        </>
    )

}
export default AddAvatarImage