import React, { useEffect, useState, useContext } from 'react'
import {useParams, Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import View from '../View/View'
import CommentFormRecipe from './CommentFormRecipe'
import Comments from '../Blog/Comments'
import StateContext from '../../StateContext'
import FavoriteBlogPost from '../Blog/FavoriteBlogPost'


function Recipe() {

    const appState = useContext(StateContext)
    const {id} = useParams()

    const [isLoading, setIsloading] = useState(true);
    const [post, setPost] = useState([]);
    const [featuredImage, setFeaturedImage] = useState("https://images.pexels.com/photos/94847/pexels-photo-94847.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")

    useEffect(() => {

        async function getBlogPost() {
            setIsloading(true)
            try {

                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/recipe/" + id + "?_embed");
                if (response.data) {
                    setPost(response.data)
                    
                    setFeaturedImage(response.data._embedded['wp:featuredmedia']['0'].source_url)
                } else {
                    console.log("Klarte ikke hente innlegg")
                }
                
            } catch (error) {

            }
            setIsloading(false)
        }
        getBlogPost()
    }, [id])

    if (isLoading) return  (
    
        <div className="text-center">
            <Loader />
        </div>

    )

    return (
        <>
            <View text={post.title.rendered} image={featuredImage}/>
            <div className="container"> 
                <div className="row my-2">
                    <div className="col-6">
                        <FavoriteBlogPost postId={id} />
                    </div>
                    <div className="col-6">
                        <div className="float-right">
                            {appState.user.user_display_name == post._embedded.author[0].name && (
                                <span className="pt-2">
                                    <Link to={`/edit-recipe/${id}`} className="text-primary mr-2" title="Edit"><i className="fas fa-edit"></i></Link>
                                    <a className="delete-post-button text-danger" title="Delete"><i className="fas fa-trash"></i></a>
                                </span>   
                            )}
                            
                        </div> 
                    </div> 
                </div>
                <hr />     
                <div className="my-4">       
                    <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>           
                    <hr/>
                </div>
                <CommentFormRecipe postId={id}/>
                <Comments postId={post.id}/>
            </div>   
        </>
    )
}
export default Recipe