import React from 'react'
import AddPost from './AddPost'
import SingleActivityTemplate from '../Templates/SingleActivityTemplate'


function ActivityMember(props) {
    return (
        <div className="my-2">
            <div className="mt-2">
                <br/>
                <AddPost className="mt-2"/>
            </div>
            

            {props.memberActivity.map(activity => {

                const date = new Date(activity.date)
                const dateFormatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}/${date.getHours()}:${date.getMinutes()}`

                return (
                    <div key={activity.id} className="mb-2">
                        <SingleActivityTemplate activity={activity} date={dateFormatted} />
                    </div>
                )
            })}
            <br />
        </div>
    )
}
export default ActivityMember

            