import React from 'react'
import { Link } from 'react-router-dom'

function LiveFixtureTemplate(props) {

    const fixture = props.fixture
    const index = props.index

    const dateFixture = new Date(fixture.event_date);
    const time = dateFixture.getHours();
    let minutes = dateFixture.getMinutes();
    if (minutes < 10) {
        minutes = `0${minutes}`
    }
    const dateFixtureFormatted = `${time}:${minutes}`

    return (
        <div key={index} className="row p-2 z-depth-1 mb-1 bg-white" style={{borderBottom: "1px solid #e3e3e3", borderTop: "1px solid #e3e3e3"}}>
            <div className="col-1"> 

                {fixture.elapsed === 0 ? dateFixtureFormatted : 
                    <>
                        {fixture.elapsed && fixture.elapsed > 0 && fixture.elapsed <= 90 && fixture.statusShort !== "FT" ?
                        <span className="text-success">{fixture.elapsed + "'"} <i className="fas fa-circle-notch fa-spin"></i></span>
                        : "FT"
                        }
                    </>
                }

            </div>
            <div className="col-4 text-right">
                <Link to={`/football/teams/${fixture.homeTeam.team_id}`}>
                    {fixture.homeTeam.team_name} <img src={fixture.homeTeam.logo} className="img-fluid teamLogo" />
                </Link>
            </div>
            <div className="col-2 text-center"> 
                <Link to={`/football/fixtures/${fixture.fixture_id}`}>{fixture.goalsHomeTeam} - {fixture.goalsAwayTeam}</Link>
            </div>
            <div className="col-4">
                <Link to={`/football/teams/${fixture.awayTeam.team_id}`}>
                    <img src={fixture.awayTeam.logo} className="img-fluid teamLogo" /> {fixture.awayTeam.team_name} 
                </Link>
            </div>  
            <div className="col-1">
                <Link to={`/football/league/${fixture.league_id}`}>
                    <img src={fixture.league.logo} className="img-fluid teamLogo" />   
                </Link> 
            </div>          
        </div>
    )

}
export default LiveFixtureTemplate