import React, { useContext, useEffect, useState } from 'react'
import Axios from 'axios'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import StateContext from '../../StateContext';

function ImagePicker(props) {

    const appState = useContext(StateContext)
    const [media, setMedia] = useState([]);
    const [mediaLoading, setMediaLoading] = useState(true)
    const [showMedia, setShowMedia] = useState(false)
    const [mediaPage, setMediaPage] = useState(1);
    const [refresh, setRefresh] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    const responseUrl = "https://smorasstats.com/v4/wp-json/wp/v2/media?page=" + mediaPage + "&per_page=10"
    const baseUrl = appState.wordpressBaseUrl + "wp-json/wp/v2/media?page=" + mediaPage + "&per_page=10"

    useEffect(() => {
        async function fetchImages() {
            setMediaLoading(true)
            try {
                const response = await Axios.get(baseUrl, {headers: headers});
                setMedia(response.data)
                //console.log(response.data)
            } catch (error) {
                console.log("Kunne ikke hente media")
            }
            setMediaLoading(false)

        } 
        fetchImages()

    }, [mediaPage, refresh])

    function toggleMedia() {
        if (showMedia) {
            setShowMedia(false)
        } else {
            setShowMedia(true)
        }
    }

    function refreshMedia() {
        setRefresh(true);

        setTimeout(() => {
            setRefresh(false)
        }, 500)
        
    }



    function addImagePost(e, imageUrl) {
        e.preventDefault();

        props.setImage(imageUrl)
        setShowMedia(false)
        props.setValue(`${props.value}<img src='${imageUrl}'>`)

    }

    return (
        <div>

            {props.type === "activity" ? 
            <MDBBtn className="btn btn-rounded btn-success btn-sm" onClick={toggleMedia}><i className="fas fa-images"></i><span className="btnText"> Vis bildegalleri</span></MDBBtn> 
            : 
            <MDBBtn className="btn btn-rounded btn-success" onClick={toggleMedia}>Vis bildegalleri</MDBBtn>
            } 
            
            <MDBModal isOpen={showMedia} toggle={toggleMedia}>
                <MDBModalHeader toggle={toggleMedia}>Galleri</MDBModalHeader>
                <MDBModalBody>
                <>
                    {mediaLoading ? "Laster...." : 
                    
                    <> 
                    
                        <div className="row">
                            {media.map(image => {
                                return (
                                    <figure key={image.id} className="col-md-4 col-lg-3">
                                        <p>ID: {image.id}</p>
                                        {props.type === "activity" ? 
                                        <img 
                                        
                                            onClick={(e) => addImagePost(e, image.source_url)} 
                                            src={image.source_url} 
                                            className="img-fluid"  
                                        />
                                        : <img src={image.source_url} className="img-fluid"  />}
                                        
                                    </figure>
                                )
                            })}
                        </div>
                        <div className="col-12">
                            <div className="my-3">
                                <div className="btn btn-sm btn-rounded btn-info" onClick={refreshMedia}>Refresh</div>
                                <p>For å vise alle bilder kreves ekstra rettigheter</p>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    {mediaPage > 1 && <div onClick={() => setMediaPage(mediaPage-1)}>Nyere bilder</div>}
                                </div>
                                <div className="col-6">
                                    <div onClick={() => setMediaPage(mediaPage+1)}>Eldre bilder</div>
                                </div>
                            </div>
                        </div>
                    </>
                    }

                </>
                </MDBModalBody>
                <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleMedia}>Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

            

        </div>
    )
}
export default ImagePicker