import React, { useContext, useEffect, useState } from 'react'
import StateContext from '../../../StateContext'
import Axios from 'axios';


function AddMembersGroup(props) {

    const appState = useContext(StateContext)

    const [members, setMembers] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [message, setMessage] = useState();

    const [userId, setUserId] = useState()

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        async function fetchMembers() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/members');
                setMembers(response.data)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchMembers()

    }, [])

    async function addMemberGroup(e) {
        e.preventDefault();

        try {
            const response = await Axios.post(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/' + props.groupId + '/members', 
            {
                context: 'edit',
                user_id: userId 
            }, 
            {
                headers: headers
            });
            console.log(response)
            setMessage("success")
        } catch (error) {
            console.log(error)
            setMessage("error")
        }

    }

    return (
        <div>
            <div className="card">
                <div className="card-header">Legg til medlem</div>
                <div className="card-body">
                    {message === "success" && <p><span className="alert alert-success">Lagt til!</span></p>}
                    {message === "error" && <p><span className="alert alert-danger">Noe gikk galt</span></p>}
                    <form onSubmit={addMemberGroup}>
                        {isLoading ? "Laster..." : 
                        <select onChange={(e) => setUserId(e.target.value)}>
                        {members.map(member => {
                            return (
                                <option key={member.id} value={member.id}>{member.name}</option>
                            )
                        })}
                        </select>
                        }
                        <button className="btn btn-sm btn-primary">Inviter</button>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default AddMembersGroup