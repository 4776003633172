import React from 'react'
import './ViewJarallax.css'

function ViewJarallaxPlayer(props) {

    
    return (

        <div 
            className="view jarallax" 
            data-jarallax='{"speed": 0.2}' 
            style={{height: "20rem", backgroundImage: `url(${props.image ? props.image : "https://images.pexels.com/photos/47730/the-ball-stadion-football-the-pitch-47730.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center"}} >
            <div className="mask rgba-black-strong">
                <div className="container text-white my-5">
                    <div className="row">
                        <div className="col-6">
                            <h2 className="font-weight-bold text-primary">#{props.draktnummer}</h2>
                            <h2 className="font-weight-bold ">{props.text}</h2>
                            <h4 className="font-weight-bold">{props.posisjon}</h4>
                        </div>
                        <div className="col-6">
                            <div className="text-right">
                                <p>Age</p>
                                <h4 className="font-weight-bold">{props.age}</h4>
                                <p>Place of birth</p>
                                <h4 className="font-weight-bold">{props.birthPlace}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    )
}
export default ViewJarallaxPlayer