import React, { useContext, useState } from 'react';
import StateContext from '../../../StateContext';
import Axios from 'axios';

function RemoveSavedMovie(props) {

    const appState = useContext(StateContext);

    const [deleted, setDeleted] = useState(false)
    const [deleteError, setDeleteError] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    async function removeItem(e) {
        e.preventDefault();

        try {
            const response = await Axios.delete(appState.wordpressBaseUrl + 'wp-json/wp/v2/favorite_media/' + props.favId, {headers: headers})
            if (response.data) {
                setDeleted(true)
                props.setRemoved(true)
            } else {
                setDeleteError(true)
                alert("Error!")
            }
            console.log(response)
        } catch (error) {
            setDeleteError(true)
            setDeleted(false);
            console.log(error)
            alert("Error!")
        }

        
    }

    if (deleteError) return <div className="alert alert-danger">Kunne ikke fjerne</div>

    if (deleted) return <div className="alert alert-success">Slettet</div>

    return (
        <form onSubmit={removeItem}>
            <button className="btn btn-danger">Fjern</button>
        </form>
    )

}
export default RemoveSavedMovie