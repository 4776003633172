import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { useParams } from 'react-router-dom'
import TeamSquad from './TeamSquad';
import TeamFixtures from './TeamFixtures';
import TeamTransfers from './Team/TeamTransfers';
import TeamLeagues from './Team/TeamLeagues';
import TeamPlayersWP from './Team/TeamPlayersWP';
import PlayerStatsSeason from './Team/PlayerStatsSeason';

function SingleTeam() {

    const {id} = useParams();

    const [team, setTeam] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [activeItem, setActiveItem] = useState("1")
    const [leagueName, setLeagueName] = useState();
    const [currentSeason, setCurrentSeason] = useState()

    useEffect(() => {

        async function fetchTeam() {

            setIsLoading(true)

            try {


                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/teams/team/" + id,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                    })

                //console.log(response.data.api.teams[0])
                if (response.data) {
                    setTeam(response.data.api.teams[0])
                }
            } catch (error) {
                console.log(error);
            }

            setIsLoading(false)
        }
        fetchTeam()

    }, [])

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    if (isLoading) return <p>Laster lag..</p>

    return (
        <div className="lightGreyBg">
            <div className="container">
                <div className="row bg-white">

                    <div className="col-12 py-3">
                        <div className="row">
                            <div className="col-4 col-md-3">
                                <img src={team.logo} className="img-fluid" />
                                
                            </div>
                            <div className="col-8 col-md-9">
                                <h2>{team.name} </h2>
                                <div className="row">
                                    <div className="col-6">
                                        <p>
                                            <small>{team.founded}</small><br/>
                                            <small>{team.country}</small><br/>
                                            <small>{currentSeason}</small>
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p>
                                            <small>{team.venue_name}</small><br/>
                                            <small>{team.venue_capacity}</small><br/>
                                            <small>{leagueName && leagueName}</small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>                               
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-1 bg-white text-center" style={{borderTop: "1px solid #e3e3e3", fontSize: "14px" }}>
                    <div style={{width: "33.33333%"}}>
                        <div 
                            style={{backgroundColor: "transparent", border: "none"}}
                            onClick={() => toggle("1")} 
                            className={activeItem === "1" ? "btn-block text-dark p-2 z-depth-1 btn-outline-primary" : "btn-block grey-text p-2"}
                        >
                            Team
                        </div>
                    </div>
                    <div style={{width: "33.33333%"}}>
                        <div 
                            style={{backgroundColor: "transparent", border: "none"}}
                            onClick={() => toggle("2")} 
                            className={activeItem === "2" ? "btn-block text-dark p-2 z-depth-1 btn-outline-primary" : "btn-block grey-text p-2"}>
                            Fixtures
                        </div>
                    </div>
                    <div style={{width: "33.33333%"}}>
                        <div 
                            style={{backgroundColor: "transparent", border: "none"}}
                            onClick={() => toggle("3")} 
                            className={activeItem === "3" ? "btn-block text-dark p-2 z-depth-1 btn-outline-primary" : "btn-block grey-text p-2"}
                        >
                            Transfers
                        </div>
                    </div>
                    <div style={{width: "33.33333%"}}>
                        <div 
                            style={{backgroundColor: "transparent", border: "none"}}
                            onClick={() => toggle("4")} 
                            className={activeItem === "4" ? "btn-block text-dark p-2 z-depth-1 btn-outline-primary" : "btn-block grey-text p-2"}
                        >
                            Tournaments
                        </div>
                    </div>
                    <div style={{width: "33.33333%"}}>
                        <div 
                            style={{backgroundColor: "transparent", border: "none"}}
                            onClick={() => toggle("5")} 
                            className={activeItem === "5" ? "btn-block text-dark p-2 z-depth-1 btn-outline-primary" : "btn-block grey-text p-2"}
                        >
                            Squad Stats
                        </div>
                    </div>
                </div>

                {activeItem === "1" && <><TeamPlayersWP id={id} /><TeamSquad id={id} /> </>}
                {activeItem === "2" && <TeamFixtures id={id} />}
                {activeItem === "3" && <TeamTransfers teamName={team.name} id={id} />}
                {activeItem === "4" && <TeamLeagues  id={id} />}
                {activeItem === "5" && leagueName && <PlayerStatsSeason currentSeason={currentSeason} leagueName={leagueName} id={id} season={"2019"} />}
                
                
                
                
            </div>
            <br/>
        </div>
    )

}
export default SingleTeam