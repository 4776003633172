

import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import UpcomingFixturesLeague from '../League/UpcomingFixturesLeague'
import FixturesLeagueDate from './FixturesLeagueDate'
import LiveFixturesByDate from './LiveFixturesByDate'

function LiveFixturesFilteredByLeague() {

    const [fixtures, setFixtures] = useState()

    useEffect(() => {

        async function fetchLiveData() {

            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/fixtures/live/1011-987",
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    },"params":{
                    "timezone":"Europe/Copenhagen"
                    }
                })
                if (response.data) {
                    console.log(response.data.api.fixtures)
                    setFixtures(response.data.api.fixtures)
                }
                

            } catch (error) {
                console.log(error)
            }

        }
        fetchLiveData()

    }, [])

    return (
        <div>
            <LiveFixturesByDate id={524} leagueName="Premier League" />
            <LiveFixturesByDate id={1310} leagueName="Eliteserien" />
            <LiveFixturesByDate id={1311} leagueName="Obos Ligaen" />
            <LiveFixturesByDate id={525} leagueName="Ligue 1" />
            <LiveFixturesByDate id={754} leagueName="Premier League" />
            <LiveFixturesByDate id={565} leagueName="Championship" />
            <LiveFixturesByDate id={891} leagueName="Serie A" />
            <LiveFixturesByDate id={1329} leagueName="Allsvenskan" />
            <LiveFixturesByDate id={1338} leagueName="Veikkausliga" />
            <LiveFixturesByDate id={1331} leagueName="Urvalsdeild" />
            <LiveFixturesByDate id={576} leagueName="Swiss Super League" />
            <LiveFixturesByDate id={2660} leagueName="Jupiler Pro League" />
            <LiveFixturesByDate id={2673} leagueName="Eredivisie" />
            <LiveFixturesByDate id={530} leagueName="Champions League" />
            <LiveFixturesByDate id={514} leagueName="Europa League" />

            <LiveFixturesByDate id={1383} leagueName="Belarus Vysshaya Liga" />

            

            {fixtures && fixtures.map((fixture, index) => {
                return (
                    <div key={index}>{fixture.homeTeam.team_name} {fixture.awayTeam.team_name}  </div>
                )
            })}
        </div>
    )

}
export default LiveFixturesFilteredByLeague