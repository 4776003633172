import React, { useState, useEffect, useContext } from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'
import {useImmerReducer} from 'use-immer'
import {CSSTransition} from 'react-transition-group'
import Loader from '../../Loader/Loader'
import LoginForm from './LoginForm'
import StateContext from '../../../StateContext'

function Register() {

    const appState = useContext(StateContext)

    const registerUrl = appState.wordpressBaseUrl + "wp-json/buddypress/v1/signup"

    const [isLoading, setIsloading] = useState(false)
    const [registered, setRegistered] = useState(false)
    const [activationKey, setActivationKey] = useState("")
    const [activatedUser, setActivatedUser] = useState(false);
    const [activateError, setActivateError] = useState(false)

    const initialState = {
        username: {
            value: "",
            hasErrors: false,
            message: "",
            isUnique: false,
            checkCount: 0
        },
        name: {
            value: "",
            hasErrors: false,
            message: "",
            isUnique: false,
            checkCount: 0
        },
        email: {
            value: "",
            hasErrors: false,
            message: "",
            isUnique: false,
            checkCount: 0
        },
        password: {
            value: "",
            hasErrors: false,
            message: ""
        },
        submitCount: 0
    }


    function ourReducer(draft, action) {
        switch (action.type) {
            case "usernameImmediately":
                draft.username.hasErrors = false
                draft.username.value = action.value
                if (draft.username.value.length > 30) {
                    draft.username.hasErrors = true
                    draft.username.message = "Brukernavn kan ikke overstige 30 bokstaver og tall"
                }
                if (draft.username.value && !/^([a-zA-Z0-9]+)$/.test(draft.username.value)) {
                    draft.username.hasErrors = true
                    draft.username.message = "Brukernavn kan kun inneholde bokstaver og tall"
                }
                return
            case "usernameAfterDelay":
                if (draft.username.value.length < 3) {
                    draft.username.hasErrors = true
                    draft.username.message = "Brukernavn må være minst 3 bokstaver eller tall"
                }
                if (!draft.hasErrors && !action.noRequest) {
                    draft.username.checkCount++
                }
                return
            case "usernameUniqueResults":
                if (action.value) {
                    draft.username.hasErrors = true
                    draft.username.isUnique = false
                    draft.username.message = "Det brukernavnet er allerede tatt"

                } else {
                    draft.username.isUnique = true
                }
                return
            case "emailImmediately":
                draft.email.hasErrors = false
                draft.email.value = action.value
                return
            case "emailAfterDelay":
                if (!/^\S+@\S+$/.test(draft.email.value)) {
                    draft.email.hasErrors = true
                    draft.email.message = "Du må oppgi en gyldig epost-adresse"
                }
                if (!draft.email.hasErrors && !action.noRequest) {
                    draft.email.checkCount++
                }
                return
            case "emailUniqueResults":
                if (action.value) {
                    draft.email.hasErrors = true
                    draft.email.isUnique = false
                    draft.email.message = "Den eposten er i bruk allerede"
                } else {
                    draft.email.isUnique = true;
                }
                return
            case "passwordImmediately":
                draft.password.hasErrors = false
                draft.password.value = action.value
                if (!draft.password.value.length > 50) {
                    draft.password.hasErrors = true;
                    draft.password.message = "Passord kan ikke overstige 50 symboler"
                }
                return
            case "passwordAfterDelay":
                if (draft.password.value.length < 12) {
                    draft.password.hasErrors = true
                    draft.password.message = "Passord må være minst 12 karakterer langt"
                }
                return
            case "submitForm":
                if (!draft.username.hasErrors && draft.username.isUnique && !draft.email.hasErrors && draft.email.isUnique && !draft.password.hasErrors) {
                    draft.submitCount++
                }
                return
            
        }
    }

    const [state, dispatch] = useImmerReducer(ourReducer, initialState);

    useEffect(() => {

        if (state.username.value) {
            const delay = setTimeout(() => dispatch({type: "usernameAfterDelay"}), 800)

            return () => clearTimeout(delay)
        }

    }, [state.username.value])

    useEffect(() => {

        if (state.email.value) {
            const delay = setTimeout(() => dispatch({type: "emailAfterDelay"}), 800)

            return () => clearTimeout(delay)
        }

    }, [state.email.value])

    useEffect(() => {

        if (state.password.value) {
            const delay = setTimeout(() => dispatch({type: "passwordAfterDelay"}), 800)

            return () => clearTimeout(delay)
        }

    }, [state.password.value])


    // Check if user already exists
    useEffect(() => {

        if (state.username.checkCount) {
            const ourRequest = Axios.CancelToken.source()
            
            async function fetchResults() {
                try {
                    const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/users?search=" + state.username.value)

                    let userExists

                    if (response.data.length) {
                        console.log(response.data.length)
                        if (state.username.value === response.data[0].name) {
                            userExists = true;
                        } else {
                            userExists = false;
                        }
                        console.log(state.username.value)
                        
                    } else {
                        userExists = false
                    }
                    dispatch({type: "usernameUniqueResults", value: userExists});
                } catch (error) {
                    console.log(error)
                }
            }
            fetchResults()
            return () => ourRequest.cancel()
        }

    }, [state.username.checkCount])

    // Check if email already exists
    useEffect(() => {

        if (state.email.checkCount) {
            const ourRequest = Axios.CancelToken.source()

            async function fetchResults() {
                try {
                    let emailExists;
                    const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/users?search=" + state.email.value)
                    console.log(response.data)
                    if (response.data.length) {
                        emailExists = true
                    } else {
                        emailExists = false
                    }

                    dispatch({type: "emailUniqueResults", value: emailExists});
                } catch (error) {
                    console.log(error)
                }
            }
            fetchResults()
            return () => ourRequest.cancel()
        }

    }, [state.email.checkCount])

    useEffect(() => {

        if (state.submitCount) {

            async function fetchResults() {
                try {
                    const response = await Axios.post(registerUrl, 
                        {

                            user_login: state.username.value,
                            user_name: state.name.value,
                            user_email: state.email.value,
                            password: state.password.value
                        }
                    )
                    setRegistered(true);
                    setActivationKey(response.data[0].activation_key)

                    console.log("Registrert! ", response)
                } catch (error) {
                    console.log(error)
                }
            }
            fetchResults()
            
        }
    }, [state.submitCount])

    useEffect(() => {
        if (registered) {
            const ourRequest = Axios.CancelToken.source()

            async function fetchResults() {
                try {
                    const response = await Axios.put(appState.wordpressBaseUrl + "wp-json/buddypress/v1/signup/activate/" + state.activation.activationKey, {
                        data: {
                            context: 'edit'
                        }
                    })
                    if (response.data) {
                        console.log("Aktiveringsmelding: ", response.data)
                        setActivationKey(response.data[0].activation_key)
                        dispatch({type: "isActivated", value: true})
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            fetchResults()
            return () => ourRequest.cancel()
        }
    }, [registered])

    function handleSubmit(e) {
        e.preventDefault();
        dispatch({type: "usernameImmediately", value: state.username.value})
        dispatch({type: "usernameAfterDelay", value: state.username.value, noRequest: true})
        dispatch({type: "emailImmediately", value: state.email.value})
        dispatch({type: "emailAfterDelay", value: state.email.value, noRequest: true})
        dispatch({type: "passwordImmediately", value: state.password.value})
        dispatch({type: "passwordAfterDelay", value: state.password.value})
        dispatch({type: "submitForm"})
    }

    async function activateUser(e) {
        e.preventDefault()
        try {
            const response = await Axios.put(appState.wordpressBaseUrl + "wp-json/buddypress/v1/signup/activate/" + activationKey, {
                data: {
                    context: 'edit'
                }
            })
            if (response.data) {
                console.log("Aktiveringsmelding: ", response.data)
                setActivatedUser(true)
            }
        } catch(error) {
            console.log(error)
            setActivateError(true)
        }
    }

    if (isLoading) return <Loader />

    if (activatedUser) return <> <div className="alert alert-success">Din konto er aktivert, du kan nå logge inn!</div><LoginForm /> </>

    if (registered) {
        return (
            <div className="container">
                <div className="alert alert-success text-center">Du er registrert!</div>
                
                <p>Trykk på knappen nedenfor for å aktivere kontoen din</p>
                
                {activateError && <div className="alert alert-danger">Din konto kunne ikke aktiveres</div>}
                <form onSubmit={activateUser} >
                    <label>Din aktiveringsnøkkel</label>
                    <input className="form-control mb-2" value={activationKey} />
                    <button className="btn btn-success" type="submit">Aktiver</button>
                </form>
                
                
                <p>Du kan logge inn etter aktivering av kontoen din</p>
                <Link className="btn btn-primary" to="/bp/login">Logg inn her</Link>
            </div>
        )
    } 

    return (
        <div style={{backgroundImage: `url('${appState.featuredImage}')`, size: "cover"}}>
            <div className="container">
                <div className="col-md-8 col-lg-6 mx-auto py-4">
                    <div className="card">
                        
                        <div className="card-header">Registrering</div>

                        <div className="card-body">

                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="username-register" className="text-muted mb-1">
                                        <small>Ditt brukernavn</small>
                                    </label>
                                    <input onChange={(e) => dispatch({type: "usernameImmediately", value: e.target.value})} id="username-register" name="username" className="form-control" type="text" placeholder="Velg et brukernavn" autoComplete="off" />
                                    <CSSTransition in={state.username.hasErrors} timeout={330} classNames="liveValidateMessage" unmountOnExit>
                                        <div className="alert alert-danger small liveValidateMessage">{state.username.message}</div>
                                    </CSSTransition>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="name-register" className="text-muted mb-1">
                                        <small>Ditt navn</small>
                                    </label>
                                    <input onChange={(e) => dispatch({type: "nameImmediately", value: e.target.value})} id="name-register" name="name" className="form-control" type="text" placeholder="Ditt navn" autoComplete="off" />
                                    <CSSTransition in={state.name.hasErrors} timeout={330} classNames="liveValidateMessage" unmountOnExit>
                                        <div className="alert alert-danger small liveValidateMessage">{state.name.message}</div>
                                    </CSSTransition>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email-register" className="text-muted mb-1">
                                        <small>Din epost</small>
                                    </label>
                                    <input onChange={(e) => dispatch({type: "emailImmediately", value: e.target.value})} id="email-register" name="email" className="form-control" type="text" placeholder="test@mail.com" autoComplete="off" />
                                    <CSSTransition in={state.email.hasErrors} timeout={330} classNames="liveValidateMessage" unmountOnExit>
                                        <div className="alert alert-danger small liveValidateMessage">{state.email.message}</div>
                                    </CSSTransition>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password-register" className="text-muted mb-1">
                                        <small>Passord</small>
                                    </label>
                                    <input onChange={(e) => dispatch({type: "passwordImmediately", value: e.target.value})} id="password-register" name="password" className="form-control" type="password" placeholder="Opprett et passord" />
                                    <CSSTransition in={state.password.hasErrors} timeout={330} classNames="liveValidateMessage" unmountOnExit>
                                        <div className="alert alert-danger small liveValidateMessage">{state.password.message}</div>
                                    </CSSTransition>
                                </div>
                                <button type="submit" disabled={isLoading} className="py-3 mt-4 btn btn-lg btn-success btn-block">
                                    Registrer deg
                                </button>
                            </form>




                            <br />
                            <p>Allerede registrert? <Link to="/bp/login">Logg inn</Link></p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Register

