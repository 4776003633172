import React, {useEffect, useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import StateContext from '../../StateContext'

function BlogPosts(props) {

    const appState = useContext(StateContext)

    const [posts, setPosts] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    const postsPerPage = props.postsPerPage;
    const page = props.page;

    useEffect(() => {
        async function getBlogPosts(page) {

            try {
                //console.log(page)
                setIsloading(true)
                const response = await Axios.get(`${appState.wordpressBaseUrl}wp-json/wp/v2/posts?_embed&per_page=${postsPerPage}&page=${page}`)
                //const response = await Axios.get("https://smorasstats.com/v4/wp-json/wp/v2/posts?_embed&per_page=" + postsPerPage + "&page=" + page);
                if (response.data) {
                    setPosts(response.data)
                    console.log(response.data, "   BLOGG")
                    
                } else {
                    console.log("Klarte ikke hente innlegg")
                }
                setIsloading(false)
            } catch (error) {

            }

        }
        getBlogPosts(page)
    }, [page, postsPerPage])

    if (isLoading) return (

        <div>
            <Loader />
        </div>

    )

    return (
        <>
            {posts.map(post => {

                const date = new Date(post.date);
                const dateFormatted = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
                return (
                    <div key={post.id}>
                        <div className="row" key={post.id}>
                            <div className="col-xl-5 mb-2">
                                <Link to={`/innlegg/single/${post.id}`}>
                                <img alt="featured" className="img-fluid" src={"https://images.pexels.com/photos/9056/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500"} />
                                </Link>
                            </div>
        
                            <div className="col-xl-7 mb-2">
        
                                <h2 key={post.id}>{post.title.rendered}</h2>
                                <p className="text-secondary"><small>Av {post._embedded.author[0].name} den {dateFormatted}</small></p>
                                <hr />
                                <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered}}></div>
                                {post.type === "recipe" ? 
                                <Link className="btn btn-success" to={`/oppskrift/${post.id}`}>Les mer</Link> : 
                                <Link className="btn btn-success" to={`/innlegg/single/${post.id}`}>Les mer</Link>}
                                
                                
                            </div>
        
                        </div>
                        <hr />
                    </div>
                    
                )
                
            })}
        </>
    )
    

}
export default BlogPosts