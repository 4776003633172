import React, { useContext, useEffect, useState } from 'react'
import StateContext from '../../../StateContext'
import Axios from 'axios';
import { Link } from 'react-router-dom';

function SingleGroupMembersAvatars(props) {

    const groupId = props.groupId;

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [groupMembers, setGroupMembers] = useState([])

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        async function fetchMembersGroup() {
            setIsLoading(true)
            try {

                const url = appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/' + groupId + '/members'
                const testUrl = "http://localhost/dashboard/OLEPETTER/WORDPRESS/BUDDYPRESS/wp-json/buddypress/v1/groups/1/members"

                const response = await Axios.get(url, {headers: headers});
                setGroupMembers(response.data)
                console.log(response.data, "   FETCH MEMBER AVATAR DATA")

            } catch (error) {
                console.log(error, "    FETCH MEMBER AVATAR ERROR")
            }
            setIsLoading(false)

        }
        fetchMembersGroup()

    }, [])

    if (isLoading) return <p>Laster medlemmer...</p>

    return (
        <div className="d-flex justify-content-end bd-highlight mt-4 no-gutters">
            {groupMembers.length ? <>
            {groupMembers.map(member => {
                return (                                      
                    <div className="col-3 col-lg-2 float-right" key={member.id} style={{marginLeft: "-4px"}}>
                        <Link to={`/bp/members/${member.id}`}><img alt="member avatar" src={member.avatar_urls.full} className="img-fluid avatar rounded-circle" /></Link>                     
                    </div>                                                               
                )
            })}
            </>
            : <div>
                <div className="col-md-6 mx-auto my-5"><p>Ingen medlemmer enda</p></div>
            </div>}

        </div>
    )
}
export default SingleGroupMembersAvatars