import React, { useEffect, useContext, useState } from 'react'
import Axios from 'axios'
import StateContext from '../../../StateContext'

function MemberGroupInvites(props) {

    const appState = useContext(StateContext)

    const [invites, setInvites] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        async function fetchInvites() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/invites', {headers: headers})
                setInvites(response.data)
                console.log(response.data)
                
            } catch (error) {
                console.log(error)
                
            }
            setIsLoading(false)

        }
        fetchInvites()

    }, [])

    async function acceptInvite(e) {
        e.preventDefault();


        

        const formData = new FormData(e.target);
        formData.set('inviteId', formData.get('inviteId'));   //append the values with key, value pair
        const inviteId = formData.get("inviteId")
        console.log(inviteId);

        try {
            const response = await Axios.post(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/invites/' + inviteId, {context: "edit"}, {headers: headers})
            console.log(response)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(true)
        }

    }

    if (isLoading || !invites) return <p>Laster</p>

    return (
        <div>
            {invites.length ? 
            <>      
                {invites.map(invite => {
                    return (
                        <div key={invite.id} className="card card-body">
                            <h5>Gruppeinvitasjon</h5>
                            
                            {error && <p><span className="alert alert-danger">Ikke Godkjent!</span></p>}
                            <p>{invite.message.raw}</p>
                            <div className="row">
                                {success ? <p><span className="alert alert-success">Godkjent!</span></p> : <form onSubmit={acceptInvite}>
                                    <input type="hidden" name="inviteId" value={invite.id} />
                                    <button>Godkjenn</button>
                                </form>}
                                
                                
                                <button>Avslå</button>
                            </div>
                        </div>
                    )
                })}
            </> : 
            <div className="py-5">
            <p><span className="alert alert-warning">Ingen invitasjoner</span></p>
            </div>
            }
        </div>
    )

}
export default MemberGroupInvites