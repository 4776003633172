import React, { useEffect } from 'react'

function Page(props) {

    useEffect(() => {

        document.title = `${props.pageTitle} | Connectify`
        window.scrollTo(0, 0)

    }, [])

    return (
        <div>
            {props.children}
        </div>
    )

}
export default Page