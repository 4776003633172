import React, {useState, useEffect} from 'react';
import Axios from 'axios'
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBadge } from 'mdbreact';

const DatatablePage = () => {
  function testClickEvent(param) {
    console.log(param);
  }

  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    
      async function fetchPosts() {
        setIsLoading(true)
          try {
              const response = await Axios.get("https://progitek.no/privat/bp/wp-json/wp/v2/vidunderbarn?per_page=100");
              console.log(response.data);
              setPosts(response.data)
          } catch (error) {
              console.log(error)
          }
          setIsLoading(false)

      }
      fetchPosts()

  }, [])

  const tableRows2 = posts.map(post => {
    return (
        {
            rating: post.acf.potensielt_niva,
            spiller: post.title.rendered,
            posisjon: post.acf.posisjon,
            klubb: post.acf.navaerende_klubb,
            fdato: post.acf.vidunderbarn_fodselsdato,
            pris: post.acf.pris
        }
    )


    })
    console.log(tableRows2)

   

  const data = () => ({
    columns: [
      {
        label: 'R',
        field: 'rating',
        sort: 'desc',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'R'
        }
      },
      {
        label: 'Spiller',
        field: 'spiller',
        width: 270
      },
      {
        label: 'Posisjon',
        field: 'posisjon',
        width: 200
      },
      {
        label: 'Klubb',
        field: 'klubb',
        width: 100
      },
      {
        label: 'Pris',
        field: 'pris',
        width: 150
      },
      {
        label: 'Født',
        field: 'fdato',
        width: 100
      }
    ],
    rows: tableRows2
  });

  const badgesData = {
    columns: [
      {
        label: 'ID',
        field: 'badge'
      },
      ...data().columns
    ],
    rows: [
      ...data().rows.map((row, order) => ({
        ...row,
        badge: (
          <MDBBadge pill color='primary' className='p-1 px-2' key={order} searchvalue={order}>
            ID: {order + 1}
          </MDBBadge>
        )
      }))
    ]
  };

  const widerData = {
    columns: [
      ...data().columns.map(col => {
        col.width = 200;
        return col;
      })
    ],
    rows: [...data().rows]
  };

  if (isLoading) return <p>Laster</p>

  return (
    <MDBContainer className='mt-3'>

      <MDBRow className='py-3'>
        <MDBCol md='12'>
                <div className="table-responsive">
                  <MDBDataTable striped bordered hover searching={true} data={data()} materialSearch />
                </div>
          
        </MDBCol>
      </MDBRow>

    </MDBContainer>
  );
};

export default DatatablePage;
