import React from 'react'
import { Link } from 'react-router-dom'

function LiveFixtureTemplateResponsive(props) {

    const fixture = props.fixture
    const index = props.index

    const date = new Date(fixture.event_date);
    
    let minutes = date.getMinutes()
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    if (day < 10) {
        day = `0${day}`
    } else {
        day = day 
    }
    if (month < 10) {
        month = `0${month}`
    } else {
        month = month
    }
    if (minutes < 10) {
        minutes = `0${minutes}`
    }
    const dateFormatted = `${day}/${month}/${year}`
    const timeFormatted = date.getHours() + ":" + minutes

    return (
        <div key={index} className="row py-2 z-depth-1 mb-1 bg-white" style={{borderBottom: "1px solid #e3e3e3", borderTop: "1px solid #e3e3e3"}}>
            
            <div className="col-7">   
                <Link to={`/football/fixtures/${fixture.fixture_id}`}>           
                    <img src={fixture.homeTeam.logo} className="img-fluid teamLogo" /> {fixture.homeTeam.team_name}    
                </Link>      
            </div>

            <div className="col-3">
                {fixture.statusShort === "FT" ? <small>FT</small> : fixture.statusShort === "NS" ? <small>{dateFormatted}</small> : ""}
            </div>

            <div className="col-2">
                <Link to={`/football/fixtures/${fixture.fixture_id}`}>{fixture.goalsHomeTeam}</Link>
            </div>

            <div className="col-7">
                <Link to={`/football/fixtures/${fixture.fixture_id}`}>
                    <img src={fixture.awayTeam.logo} className="img-fluid teamLogo" /> {fixture.awayTeam.team_name} 
                </Link>
            </div>
            <div className="col-3">
                    {fixture.statusShort === "FT" ? <small>{dateFormatted}</small> : fixture.statusShort === "NS" ? <small>{timeFormatted}</small> :
                    <>
                        {fixture.elapsed && fixture.elapsed > 0 && fixture.elapsed <= 90 && fixture.statusShort !== "FT" ?
                        <span className="text-success">{fixture.elapsed + "'"} <i className="fas fa-circle-notch fa-spin"></i></span>
                        : "FT"
                        }
                    </>
                }
            </div>

            
            <div className="col-2">
                <Link to={`/football/fixtures/${fixture.fixture_id}`}>   {fixture.goalsAwayTeam}</Link>
            </div>
            

        </div>
    )

}
export default LiveFixtureTemplateResponsive