import React from 'react'
import {Link} from 'react-router-dom'

function NotFound() {
    return (
        <div className="text-center">
            <h2>Fant ikke siden....</h2>
            <p>Du kan gå til <Link to="/">hjemmesiden</Link> for å finne innlegg</p>
        </div>
        
    )
}
export default NotFound