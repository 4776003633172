import React, { useEffect, useContext, useState } from 'react'
import Axios from 'axios'
import StateContext from '../../../StateContext'
import { Link } from 'react-router-dom'

function TopLeagues(props) {

    const appState = useContext(StateContext)

    const [leagues, setLeagues] = useState()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function fetchTopLeagues() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/top_leagues");
                if (response.data) {
                    setLeagues(response.data)
                }
                console.log(response, " TOP LEAGUES ---------------------")
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchTopLeagues()

    }, [])

    if (isLoading) return <p>Loading..</p>

    return (
        <div>
            {leagues && leagues.map(league => {
                return (
                    <div className="card card-body" key={league.id} >
                        <Link to={`/football/league/${league.acf.league_id}`}>
                            <img src={league.acf.league_logo} className="img-fluid pr-2" style={{maxWidth: "50px"}} />
                            {league.title.rendered} 
                        </Link>
                         
                    </div>
                )
            })}
        </div>
    )

}
export default TopLeagues