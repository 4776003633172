import React, {useEffect, useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import StateContext from '../../StateContext'

function RecipePosts(props) {

    const appState = useContext(StateContext)

    const [posts, setPosts] = useState([]);
    const [isLoading, setIsloading] = useState(false);

    const postsPerPage = props.postsPerPage;
    const page = props.page;

    useEffect(() => {
        async function getRecipePosts(page) {
            setIsloading(true)
            try {
                //console.log(page)
                
                const response = await Axios.get(appState.wordpressBaseUrl +  "wp-json/wp/v2/recipe?_embed&per_page=" + postsPerPage + "&page=" + page);
                if (response.data) {
                    setPosts(response.data)      
                    
                } else {
                    console.log("Klarte ikke hente innlegg")
                }
                
            } catch (error) {
                console.log(error)
            }
            setIsloading(false)
        }
        getRecipePosts(page)
    }, [page])

    if (isLoading) return (

        <div>
            <Loader />
        </div>

    )

    return (
        <>
            {posts.map(post => {

                const date = new Date(post.date);
                const dateFormatted = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
                return (
                    <div key={post.id}>
                        <div className="row" key={post.id}>
                            <div className="col-xl-5 mb-2">
                                <Link to={`/oppskrift/${post.id}`}>
                                {post._embedded['wp:featuredmedia'] && (
                                    <img 
                                    className="img-fluid image-thumbnail" 
                                    src={post._embedded['wp:featuredmedia']['0'].source_url ? post._embedded['wp:featuredmedia']['0'].source_url : "https://images.pexels.com/photos/94847/pexels-photo-94847.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} />
                                
                                )}
                                </Link>
                            </div>
        
                            <div className="col-xl-7 mb-2">

                                <p>
                                    {post._embedded['wp:term'] && (
                                        post._embedded['wp:term']['0'].map(term => { 
                                            return <span key={term.id} className="text-secondary"><Link to={`/category/${term.id}`}>{term.name}</Link> </span>  
                                        })
                                    )
                                
                                    }


                                </p>

                                <h2 key={post.id}>{post.title.rendered}</h2>
                                <p className="text-secondary"><small>Av {post._embedded.author[0].name} den {dateFormatted}</small></p>
                                <hr />
                                <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered}}></div>
                    
                                <Link className="btn btn-outline-dark" to={`/oppskrift/${post.id}`}>Les mer</Link>
                                
                            </div>
        
                        </div>
                        <hr />
                    </div>
                    
                )
                
            })}
        </>
    )
    

}
export default RecipePosts