import React, {useState, useContext, useEffect} from 'react'
import Axios from 'axios';
import StateContext from '../../../StateContext';
import { Link } from 'react-router-dom';

function AddMessageThread() {

    const appState = useContext(StateContext)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    const [messageThreadTitle, setMessageThreadTitle] = useState();
    const [message, setMessage] = useState();
    const [posted, setPosted] = useState(false);
    const [postError, setPostError] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [members, setMembers] = useState([])
    const [recipients, setRecipients] = useState([])

    useEffect(() => {

        async function getMembers() {
            setIsLoading(true);
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/members", {headers: headers, data: {context: "view"}})
                setMembers(response.data)
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        getMembers()

    }, [])

    async function addThread(e) {
        e.preventDefault();

        try {
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/messages", 
            {
                context: 'edit',
                subject: messageThreadTitle,
                message: message,
                recipients: recipients,
                sender_id: appState.user.appUserId
            }, 
            {
                headers: headers
            })
            if (response.data) {
                setPosted(true);
                setPostError(false)
            } else {
                setPostError(true)
            }
            
            console.log(response.data)
        } catch (error) {
            setPostError(true)
            console.log(error)
        }
    }

    if (isLoading) return <div className="text-center my-5">Laster.....</div>

    return (
        <div className="container">
            <h2 className="my-3">Legg til ny meldingstråd</h2>
            {postError && <div className="alert alert-danger">Kunne ikke sende melding</div>}

            {posted ? <div className="alert alert-success">Meldingstråd opprettet!  <Link to={`/bp/messages`}>Vis tråd</Link></div> : 
            <form onSubmit={addThread}>

                <label>Velg mottaker (Gjeldende mottaker: {recipients}</label>
                <select onChange={(e) => setRecipients(e.target.value)} className="form-control mb-2">
                    {members.map(member => {
                        return <option key={member.id} value={member.id}>{member.name}</option>
                    })}
                </select>

                <input onChange={(e) => setMessageThreadTitle(e.target.value)} className="form-control mb-2" placeholder="Tittel på meldingstråd" />
                <textarea onChange={(e) => setMessage(e.target.value)} className="form-control mb-2" placeholder="Din melding" />
                <button className="btn btn-rounded btn-sm btn-success">Opprett tråd</button>

            </form>
            }
        </div>
    )

}
export default AddMessageThread