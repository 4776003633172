import React, { useState, useContext  } from 'react';
import {Link} from 'react-router-dom'
import Axios from 'axios'

import DispatchContext from '../../../DispatchContext'
import StateContext from '../../../StateContext'
import Activity from '../Activity/Activity'

function LoginForm(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [isLoading, setIsLoading] = useState(false)
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()

    const [loggedIn, setLoggedIn] = useState(false)
    const [loginError, setLoginError] = useState(false)

    async function login(e) {
        e.preventDefault();

        setIsLoading(true)
        try {
            
            const response = await Axios.post(appState.wordpressBaseUrl +  "wp-json/jwt-auth/v1/token", {username, password})
            if (response.data) {
                appDispatch({type: "login", data: response.data})
                appDispatch({type: "flashMessage", value: "Du har blitt logget inn!"})
                setLoggedIn(true)

            } else {
                console.log("Feil brukernavn eller passord")
                appDispatch({type: "flashMessage", value: "Ugyldig brukernavn eller passord"})
                setLoginError(true)
            }
        } catch (error) {
            console.log(error)
            setLoginError(true)
        }
        setIsLoading(false)
    }

    if (appState.loggedIn || loggedIn) {
        return <><Activity /></>
    }



    return (
        <div style={{backgroundImage: `url('${appState.featuredImage}')`, size: "cover"}}>
            <div className="container-extra-small">
                <div className="py-3">
                    <div className="card my-5">
                        <div className="card-header">Logg inn</div>
                        
                        {props.message && <div className="alert alert-danger">{props.message}</div>}
                        {loginError && <div className="alert alert-danger">Noe gikk galt, prøv å logge inn på nytt</div>}

                        <div className="card-body">
                            <form className="mb-0 pt-2 pt-md-0" onSubmit={login}>
                                <div className="align-items-center">
                                    <div className="form-group mb-3">
                                        <label className="text-muted mb-1">
                                            <small>Brukernavn</small>
                                        </label>
                                        <input 
                                            name="username" 
                                            onChange={e => setUsername(e.target.value)} 
                                            className="form-control" 
                                            type="text"
                                            placeholder="Brukernavn" autoComplete="off" 
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-muted mb-1">
                                            <small>Passord</small>
                                        </label>
                                        <input 
                                            name="password" 
                                            onChange={e => setPassword(e.target.value)} 
                                            className="form-control" 
                                            type="password"
                                            placeholder="Passord" 
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <button className="py-3 mt-4 btn btn-lg btn-success btn-block" disabled={isLoading}>Logg inn</button>
                                    </div>
                                    <br />
                                    <p>
                                        <Link to="/bp/signup">Registrering</Link>
                                    </p>
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer" href={`${appState.wordpressBaseUrl}wp-login.php?action=lostpassword`}>Glemt passord? Trykk her</a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default LoginForm