import React, {useEffect, useState, useContext} from 'react'
import Axios from 'axios'
import LoaderSmall from '../../Loader/LoaderSmall'
import StateContext from '../../../StateContext'

function FavoritePost(props) {

    const appState = useContext(StateContext)

    const activity = props.activity
    const token = localStorage.getItem("token")

    const [favorite, setFavorite] = useState()
    const [isfavoriteLoading, setIsfavoriteLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [postId, setPostId] = useState([])
    const [liked, setLiked] = useState(Boolean(activity.favorited))
    const [disliked, setDisliked] = useState(false)
    const [likedStatus, setLikedStatus] = useState()
        
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }



    async function favoritePost(e) {
        e.preventDefault()

        let formData = new FormData(e.target);    //formdata object

        formData.set('postId', formData.get('postId'));   //append the values with key, value pair
        const postId = formData.get("postId")

        console.log("PostId: ", postId)

        setIsfavoriteLoading(true)
        try {
            const response = await Axios.post(
                appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity/" + postId + "/favorite",
                    {data: {
                        context: 'edit'
                    }},
                    {headers: headers }
                );
            if (response.data) {
                setFavorite(true);
                setLiked(true)
                console.log("Response Data: ", response.data)
                console.log(Boolean(response.data.favorited))
            } else {
                console.log("Noe gikk galt")
            }
        } catch (error) {
            console.log(error)
        }
        setIsfavoriteLoading(false)
    }

    async function removeFavoritePost(e) {
        e.preventDefault()

        let formData = new FormData(e.target);    //formdata object

        formData.set('postIdRemove', formData.get('postIdRemove'));   //append the values with key, value pair
        const postIdRemove = formData.get("postIdRemove")

        

        setIsfavoriteLoading(true)
        try {
            const response = await Axios.post(
                appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity/" + postIdRemove + "/favorite",
                    {data: {
                        context: 'delete'
                    }},
                    {headers: headers }
                );
            if (response.data) {
                setFavorite(false);
                setLiked(false)
                setDisliked(true)
                console.log(Boolean(response.data.favorited))
            } else {
                console.log("Noe gikk galt")
            }
        } catch (error) {
            console.log(error)
        }
        setIsfavoriteLoading(false)
    }

    useEffect(() => {

        async function isLiked() {
            setIsLoading(true)
            try {
                //console.log(page)
                
                const response = await Axios.get(
                    appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity/" + activity.id +"?display_comments=true", 
                        {
                            data: {
                                context: 'view'
                            },
                            headers: headers
                        }
                    );
                if (response.data) {
                    setLikedStatus(response.data[0].favorited)
                    console.log(response.data[0].favorited)
                } else {
                    
                    console.log("Klarte ikke hente aktivitet")
                }
                
            } catch (error) {
               console.log(error)
            }
            setIsLoading(false)

        }
        isLiked();
    }, [liked])

    if (isLoading || isfavoriteLoading) return <LoaderSmall/>

    return (
        <>    
            {!likedStatus ? 
                <form onSubmit={favoritePost}>
                    <input type="hidden" name="postId" onChange={e => setPostId(e.target.value)} value={activity.id} />
                    
                    <button className="btn btn-outline-dark btn-block" type="submit">
                        <i className={`fas fa-thumbs-up`}></i>
                        <small> {activity.favorites}  <span className="btnText">Lik innlegg</span></small>   
                    </button>
                    
                </form> : 
                <form onSubmit={removeFavoritePost}>
                    
                    <input type="hidden" name="postIdRemove" value={activity.id} />
                    <button className="btn btn-outline-primary btn-block" type="submit">
                        <i className={`fas fa-thumbs-up text-primary`}></i> 
                        <small> {liked ? +activity.favorites+1 : disliked ? activity.favorites-1 : activity.favorites} <span className="btnText"> Du liker dette</span></small> 
                    </button>
                    
                </form>  
            }  
        </>     
    )
}
export default FavoritePost