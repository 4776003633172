import React from 'react'
import {Link} from 'react-router-dom'

import Loader from '../Loader/Loader'

function PostPagination(props) {

    

    if (props.isLoading) return (

        <div>Loading....
            <Loader />
        </div>

    )

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
                {props.currentPage > 1 && (
                    <li className="page-item">
                        <Link className="page-link" to={`/innlegg/${props.currentPage - 1}`} >Forrige</Link>
                    </li>
                )}

                

                {props.pages.map(page => {
                    return <li key={page} className={`page-item ${props.currentPage === page+1 ? 'active' : ''}`}><Link className="page-link" to={`/innlegg/${page+1}`}>{page+1}</Link></li>
                })}
                
                
                
                {props.currentPage < props.pages.length && (
                    <li className="page-item">
                        <Link className="page-link" to={`/innlegg/${props.currentPage + 1}`} >Neste</Link>
                    </li>
                )}
            </ul>
        </nav>
    )
}

export default PostPagination
