import React, { useState } from 'react'


function TwentyOneGame() {

    const cardsExpanded = [
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-ess.png',
            value: 1
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-2.png',
            value: 2
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-3.png',
            value: 3
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-4.png',
            value: 4
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-5.png',
            value: 5
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-6.png',
            value: 6
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-7.png',
            value: 7
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-8.png',
            value: 8
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-9.png',
            value: 9
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-10.png',
            value: 10
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-knekt.png',
            value: 10
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-dronning.png',
            value: 10
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/klover-konge.png',
            value: 10
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-ess.png',
            value: 1
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-2.png',
            value: 2
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-3.png',
            value: 3
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-4.png',
            value: 4
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-5.png',
            value: 5
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-6.png',
            value: 6
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-7.png',
            value: 7
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-8.png',
            value: 8
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-9.png',
            value: 9
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-10.png',
            value: 10
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-knekt.png',
            value: 10
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-dronning.png',
            value: 10
        },
        {
            src: 'https://progitek.no/wp-content/uploads/2020/08/spar-konge.png',
            value: 10
        }
     ]

    const [randNum, setRandNum] = useState(Math.ceil(Math.random() * (cardsExpanded.length)))
    const [randNum2, setRandNum2] = useState(Math.ceil(Math.random() * (cardsExpanded.length)))


    const [dealerScore, setDealerScore] = useState(0)
    const [score, setScore] = useState(0)
    const [cardsDrawn, setCardsDrawn] = useState();
    const [cardsDrawnDealer, setCardsDrawnDealer] = useState()




    function drawCardRand() {
        const randCard = Math.ceil(Math.random() * (cardsExpanded.length+1))
        const randCard2 = Math.ceil(Math.random() * cardsExpanded.length+1)

        if (randCard < 1 || randCard2 < 1) {
            cardsDrawn ? setCardsDrawn(cardsDrawn.concat(randCard+1)) : setCardsDrawn([randCard+1])
            return alert("randCard less than 1! ", randCard)
        } else if (randCard > 25 || randCard2 > 25) {
            cardsDrawn ? setCardsDrawn(cardsDrawn.concat(randCard-1)) : setCardsDrawn([randCard-1])
            return alert("randCard bigger than than 25! ", randCard)
        }

        cardsDrawn ? setCardsDrawn(cardsDrawn.concat(randCard)) : setCardsDrawn([randCard])
        cardsDrawnDealer ? setCardsDrawnDealer(cardsDrawnDealer.concat(randCard2)) : setCardsDrawnDealer([randCard2])

        setScore(cardsExpanded[randCard].value)
        setDealerScore(cardsExpanded[randCard2].value)

        console.log(cardsDrawn)
        console.log(cardsExpanded[randCard].value)

        setRandNum(randCard)
        setRandNum2(randCard2)
        
    }

    function calcScore(card1, card2) {

        let score1, score2

        if (card1 === 1 && card2 < 11) {
            score1 = 11
        } else {
            score1 = cardsExpanded[card1-1].value
        }
        if (card2 === 1 && card1 < 11) {
            score1 = 11
        } else {
            score2 = cardsExpanded[card2-1].value
        }

        return score1 + score2
    }

    return (
        <div className="container">

            <div className="col-6 my-5">
                <div className="row">

                    <div className="col-6">
                        <p>Dealer</p>
                        {cardsExpanded && cardsExpanded[randNum2-1] && <img style={{height: "200px", width: "200px"}} src={cardsExpanded && cardsExpanded[randNum2-1].src && cardsExpanded[randNum2-1].src} className="img-fluid" />}
                        <p>Card number: {randNum2-1} </p>
                    </div>
                    <div className="col-6">
                        <p>You</p>
                        {cardsExpanded && cardsExpanded[randNum-1] && <img style={{height: "200px", width: "200px"}} src={cardsExpanded && cardsExpanded[randNum-1].src && cardsExpanded[randNum-1].src} className="img-fluid" />}
                        <p>Card number: {randNum-1} </p>
                    </div>
                </div>
                
                <p>Rand num: {randNum}</p>
                <p>Rand num2: {randNum2}</p>
                <p>Score: {calcScore(randNum, randNum2)}</p>
                <p>
                    Kort trukket: {cardsDrawn}
                </p>
                <button onClick={drawCardRand}>Draw</button>

            </div>

            <h2>{score}</h2>
            <div className="row">
                {cardsDrawn && cardsDrawn.map((card, index) => {
                    return (
                        <div key={index} className="col-2">
                            <img src={cardsExpanded && cardsExpanded[card-1].src && cardsExpanded[card-1].src} className="img-fluid" />
                        </div>
                    )
                })}
            </div>

        </div>
    )

}
export default TwentyOneGame