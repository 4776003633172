import React, { useEffect, useContext, useState } from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

import StateContext from '../../../StateContext'
import RemoveSavedMovie from '../../FilmApp/Movie/RemoveSavedMovie'

function MemberFavoritedMedia(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(true)
    const [favoriteMedia, setFavoriteMedia] = useState();
    const [removed, setRemoved] = useState(false)

    const [activeItem, setActiveItem] = useState("1")

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {
        async function fetchFavoritedMedia() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/wp/v2/favorite_media?author=' + props.userId, {headers: headers})
                if (response.data) {
                    setFavoriteMedia(response.data);
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchFavoritedMedia()
    }, [props.userId, removed])

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    if (isLoading) return <p>Laster.....</p>

    return (
        <div className="row">

            <div className="col-12 my-3">
                <div className="row">            
                    <div className="col-6">
                        <button onClick={() => toggle("1")} className={activeItem === "1" ? "btn btn-block btn-primary active" : "btn btn-block btn-primary"}>Vis filmer</button>
                    </div>
                    <div className="col-6">
                        <button onClick={() => toggle("2")} className={activeItem === "2" ? "btn btn-block btn-primary active" : "btn btn-block btn-primary"}>Vis serier</button>
                    </div>   
                </div>        
            </div>

            {activeItem === "1" &&
            <>
            {favoriteMedia ? favoriteMedia.map(favorite => {
                if (favorite.acf.media_image && favorite.acf.media_type === "film") {
                    return (
                        <div key={favorite.id} className="col-md-3 col-lg-2 my-3">
                            <div className="card container-media-hover">
                                {favorite.acf.media_type === "film" ? 
                                <>
                                <Link to={`/movies/single/${favorite.acf.media_id}`}>
                                    <img src={favorite.acf.media_image} alt="Media Favorited Cover" className="img-fluid image" />
                                </Link>
                                <div className="middle">
                                    <Link to={`/movies/single/${favorite.acf.media_id}`} className="btn btn-success"><i className="fas fa-eye"></i></Link>
                                    <RemoveSavedMovie setRemoved={setRemoved} movieId={favorite.acf.media_id} favId={favorite.id} mediaType="film" />
                                </div>
                                </>
                                : 
                                <>
                                <Link to={`/shows/single/${favorite.acf.media_id}`}>
                                    <img src={favorite.acf.media_image} alt="Media Favorited Cover" className="img-fluid image" />                          
                                </Link>
                                <div className="middle">
                                    <Link to={`/movies/single/${favorite.acf.media_id}`} className="btn btn-success"><i className="fas fa-eye"></i></Link>
                                    <RemoveSavedMovie setRemoved={setRemoved} movieId={favorite.acf.media_id} favId={favorite.id} mediaType="serie" />
                                </div>
                                </>
                                }
                            </div>
                        </div>
                    )
                } else {
                    return ""
                }

            }) : ""  }
            </>
            }

            {activeItem === "2" && 
            <>
            
            {favoriteMedia.map(favorite => {
                if (favorite.acf.media_image && favorite.acf.media_type === "serie") {
                    return (
                        <div key={favorite.id} className="col-md-3 col-lg-2 my-3">

                            <div className="card container-media-hover">

                                {favorite.acf.media_type === "film" ? 
                                <>
                                <Link to={`/movies/single/${favorite.acf.media_id}`}>
                                    <img src={favorite.acf.media_image} alt="Media Favorited Cover" className="img-fluid image" />                                  
                                </Link>
                                <div className="middle">
                                <Link to={`/movies/single/${favorite.acf.media_id}`} className="btn btn-success"><i className="fas fa-eye"></i></Link>
                                    <RemoveSavedMovie setRemoved={setRemoved} movieId={favorite.acf.media_id} favId={favorite.id} mediaType="film" />
                                </div>
                                </>
                                : 
                                <>
                                    <Link to={`/shows/single/${favorite.acf.media_id}`}>
                                    <img src={favorite.acf.media_image} alt="Media Favorited Cover" className="img-fluid image" />                   
                                </Link>
                                <div className="middle">
                                    <Link to={`/shows/single/${favorite.acf.media_id}`} className="btn btn-success"><i className="fas fa-eye"></i></Link>
                                    <RemoveSavedMovie setRemoved={setRemoved} movieId={favorite.acf.media_id} favId={favorite.id} mediaType="serie" />
                                </div>
                                </>
                                }
                                
                            </div>

                        </div>
                    )
                } else {
                    return ""
                }

            })}
            </>
            }
        </div>
    )
    
}
export default MemberFavoritedMedia