import React, {useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader';
import View from '../View/View'

function RecipeCategory() {
    const {id} = useParams()

    const [isLoading, setIsloading] = useState(true);
    const [isLoadingCat, setIsloadingCat] = useState(true);
    const [posts, setPosts] = useState([]);
    const [category, setCategory] = useState()

    useEffect(() => {

        async function getBlogPost() {
            setIsloading(true)
            try {

                const response = await Axios.get("https://camillahardilla.com/wp-json/wp/v2/posts?categories=" + id + "&_embed");
                if (response.data) {
                    console.log(response.data)
                    setPosts(response.data)
                    
                } else {
                    console.log("Klarte ikke hente innlegg")
                }
                
            } catch (error) {
                console.log(error)
            }
            setIsloading(false)
        }
        getBlogPost()
    }, [id])


    useEffect(() => {

        async function getCategoryInfo() {
            setIsloadingCat(true)
            try {

                const response = await Axios.get("https://camillahardilla.com/wp-json/wp/v2/categories/" + id + "?_embed&_embed");
                if (response.data) {
                    console.log(response.data)
                    setCategory(response.data)
                    
                } else {
                    console.log("Klarte ikke hente innlegg")
                }
                
            } catch (error) {
                console.log(error)
            }
            setIsloadingCat(false)
        }
        getCategoryInfo()
    }, [id])

    if (isLoading || isLoadingCat) return <Loader />

    return (
        <>
            <View text={`Kategori: ${category.name}`} image={category.acf.kategori_bilde} />
            <div className="container mt-3">
                {posts.map((post, index) => {
                    
                    const date = new Date(post.date);
                    const dateFormatted = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
                    return (
                        
                        
                        <div key={post.id}>
                            <div className="row" key={post.id}>
                                <div className="col-xl-5 mb-2">
                                    <Link to={`/oppskrift/${post.id}`}>
                                    <img className="img-fluid image-thumbnail" src={post._embedded['wp:featuredmedia']['0'].source_url ? post._embedded['wp:featuredmedia']['0'].source_url : "https://images.pexels.com/photos/94847/pexels-photo-94847.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} />
                                    </Link>
                                </div>
            
                                <div className="col-xl-7 mb-2">
                                    <p>{post._embedded['wp:term']['0'].map(term => { return <span className="text-secondary"><Link to={`/category/${term.id}`}>{term.name}</Link> </span>  })}</p>
                                    <h2 key={post.id}>{post.title.rendered}</h2>
                                    <p className="text-secondary"><small>Av {post._embedded.author[0].name} den {dateFormatted}</small></p>
                                    <hr />
                                    <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered}}></div>
                                    {post.type === "recipe" ? 
                                    <Link className="btn btn-outline-dark" to={`/oppskrift/${post.id}`}>Les mer</Link> : 
                                    <Link className="btn btn-outline-dark" to={`/innlegg/single/${post.id}`}>Les mer</Link>}
                                    
                                    
                                </div>
            
                            </div>
                            <hr />
                        </div>
                        
                        
                    )
                })}
            </div>
        </>
    )
}
export default RecipeCategory