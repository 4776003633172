import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import StateContext from '../../StateContext'

function PageList() {

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(true);
    const [posts, setPosts] = useState([]);
    


    useEffect(() => {

        async function getBlogPosts() {

            try {
                //console.log(page)
                setIsloading(true)
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/pages?per_page=100");
                if (response.data) {
                    setPosts(response.data)
                    //console.log(posts)
                } else {
                    console.log("Klarte ikke hente sider")
                }
                setIsloading(false)
            } catch (error) {

            }

        }
        getBlogPosts()
    }, [])

    if (isLoading) return (

        <div>
            <Loader />
        </div>

    )

    return (
        <div className="container">
            <h1 className="text-center">PageList page</h1>
            {posts.map(post => {
                return (

                    <div key={post.id}>
                        <h2 key={post.id}>{post.title.rendered}</h2>
                        <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></div>
                        <Link to={`/sider/single/${post.id}`}>Les mer</Link>
                        <hr></hr>
                    </div>


                )

            })}
            
        </div>

    )
}
export default PageList