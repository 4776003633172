import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import StateContext from '../../../StateContext';
import { Link } from 'react-router-dom';

function MemberPosts(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [posts, setPosts] = useState([])

    useEffect(() => {

        async function fetchPosts() {
            setIsLoading(true)
            try {
                
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/wp/v2/posts?author=' + props.userId)

                if (response.data) {
                    setPosts(response.data)
                }
                console.log(response.data)

            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchPosts()

    }, [props.userId])

    if (isLoading) return <p>Laster innlegg...</p>

    return (
        <div>
            <br />
            {posts.map(post => {
                return (
                    <div key={post.id}>
                        <div className="card card-body my-3">
                            {post.type === "recipe" ? <Link to={`/oppskrift/${post.id}`}>{post.title.rendered}</Link> : <Link to={`/innlegg/single/${post.id}`}>{post.title.rendered}</Link>}
                        </div>
                    </div>
                )
            })}
            <br/>
        </div>
    )

}
export default MemberPosts