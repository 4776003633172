
import React, { useEffect, useState } from 'react'
import Axios from 'axios'

function LeagueTopScorer(props) {

    const [topScorers, setTopScorers] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchData() {
            setIsLoading(true)
            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/topscorers/" + props.id,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })
                console.log(response.data.api.topscorers)
                setTopScorers(response.data.api.topscorers)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchData()

    }, [props.id])

    if (isLoading) return <p>Loading top scorers</p>

    return (
        <div>
            {topScorers && 
                <div className="table-responsive bg-white">
                    <table className="table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Player</th>
                                <th>Team</th>
                                <th>Goals</th>
                                <th>Apps</th>
                                <th>G/A</th>
                                <th>M/G</th>
                            </tr>
                        </thead>
                        <tbody>
                        {topScorers.map((player, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index+1} </td>
                                    <td>{player.player_name} </td>
                                    <td>{player.team_name} </td>
                                    <td>{player.goals.total} </td>
                                    <td>{player.games.appearences}</td>
                                    <td>{(player.goals.total/player.games.appearences).toFixed(2)} </td>
                                    <td> {(player.games.minutes_played/player.goals.total).toFixed(0)} </td>
                                </tr>
                            )
                        })}
                        </tbody>          
                    </table>
                </div>
            }
        </div>
    )

}
export default LeagueTopScorer