import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import LeaguesByCountry from './League/LeaguesByCountry'
import TopLeagues from './League/TopLeagues'
import ExternalNews from './FootballBlog/ExternalNews'
import FootballBlog from './FootballBlog/FootballBlog'
import Countries from './League/Countries'

function AllLeagues() {

    const [leagues, setLeagues] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [activeItem, setActiveItem] = useState("3")

    useEffect(() => {

        async function fetchLeagues() {
            setIsLoading(true)
            try {
                const response = await Axios.get("https://api-football-v1.p.rapidapi.com/v2/leagues/current", 
                {headers: {
                    "x-rapidapi-key": "4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45"
                }}
                )
                if (response.data) {
                    setLeagues(response.data.api.leagues)
                }
                console.log(response.data, "PREDICTIONS");
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false)

        }
        fetchLeagues()

    }, [])

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    if (isLoading) return <p>Laster...</p>

    return (
        <div className="container my-5">

            <div className="row">
                <div className="col-4">
                    <button onClick={() => toggle("1")} className={"btn btn-primary btn-sm btn-block"}>Ligaer</button>
                </div>
                <div className="col-4">
                    <button onClick={() => toggle("2")} className={"btn btn-primary btn-sm btn-block"}>Nasjoner</button>
                </div>
                <div className="col-4">
                    <button onClick={() => toggle("3")} className={"btn btn-primary btn-sm btn-block"}>Nyheter</button>
                </div>
            </div>

            

            {activeItem === "1" && 
                <>

                    <h2>Top leagues</h2>
                    <TopLeagues />

                    <h2>All Leagues</h2>
                    <LeaguesByCountry />
        
                    {leagues ? 
                    <div className="row">
        
                    {leagues.length ? 
                    
                    leagues.map(league => {
                        return (
                            <div key={league.league_id} className="col-md-6 col-lg-4 col-xl-3">
                                <div className="mb-2 card card-body">
                                <Link to={`/football/league/${league.league_id}`}>{league.name} <span><img style={{maxWidth: "20px", maxHeight: "20px"}} src={league.logo} /></span></Link>
                                </div>
                            </div>
                        )
                    })
                    : ""
                    }
                    </div>
        
                    : <p>No leagues 
                        {console.log(leagues)}
                    </p>}
                </>
            }

            {activeItem === "2" && <Countries />}

            {activeItem === "3" &&      
                <>
                    <h2>News</h2>
                    <ExternalNews />
                    <FootballBlog />
                </>
            }

        
        </div>
    )

}
export default AllLeagues