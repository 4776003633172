import React, { useContext, useState } from 'react'
import Axios from 'axios'
import StateContext from '../../../StateContext'
import { Link } from 'react-router-dom';

function AddMessageThreadProfile(props) {

    const appState = useContext(StateContext)

    const [messageThreadTitle, setMessageThreadTitle] = useState();
    const [message, setMessage] = useState();
    const [posted, setPosted] = useState(false);
    const [postedMessageId, setPostedMessageId] = useState([])

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    async function sendMessage(e) {
        e.preventDefault()
        try {
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/messages", 
            {
                context: 'edit',
                subject: messageThreadTitle,
                message: message,
                recipients: [props.userId],
                sender_id: appState.user.appUserId
            }, 
            {
                headers: headers
            })
            console.log(response.data)
            setPosted(true)
            setPostedMessageId(response.data[0].id)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
        <br/>
        <div className="card my-4">
            <div className="card-header">Send melding til {props.username}</div>
            <div className="card card-body">
                {posted && (
                    <div className="my-3">
                        <div className="alert alert-success">Melding sendt</div>
                        <Link to={`/bp/messages/${postedMessageId}`}>Gå til meldingstråd</Link>
                    </div>
                )}
                <form onSubmit={sendMessage}>
                    <input onChange={e => setMessageThreadTitle(e.target.value)} className="form-control mb-2" placeholder="Emne" />
                    <textarea onChange={e => setMessage(e.target.value)} className="form-control mb-2" placeholder="Melding" />
                    <button className="btn btn-sm btn-primary">Send</button>
                </form>
            </div>
        </div>
        <br/>
        </>
    )
}
export default AddMessageThreadProfile