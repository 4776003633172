import React, { useState } from 'react'
import ImagePicker from '../Media/ImagePicker';

function AddImageEditor(props) {

    const [image, setImage] = useState()

    return (
        <>
        <ImagePicker type="activity" value={props.value} setValue={props.setValue} image={image} setImage={setImage} />
        </>
    )

}
export default AddImageEditor