import React, {useEffect, useState, useContext} from 'react'
import {useParams} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import PostPagination from '../Pagination/PostPagination'
import View from '../View/View'
import RecipePosts from './RecipePosts'
import StateContext from '../../StateContext'

function RecipePostLists() {

    const { page } = useParams()

    const appState = useContext(StateContext)

    let currentPage = parseInt(page)
    const [currentPageParam, setCurrentPageParam] = useState(false)
    const [isLoading, setIsloading] = useState(false);
    const [totalPosts, setTotalPosts] = useState([])

    // props to pagination component
    const postsPerPage = 5;
    const numberOfPages = Math.ceil(totalPosts / postsPerPage);
    const pages = Array.from(Array(numberOfPages).keys())
 
    useEffect(() => {

        async function getTotalPosts() {
            
            //const response = await Axios.get("http://localhost:3000/json/posts.json");
            const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/recipe?per_page=100");
            if (response.data) {
                setTotalPosts(response.data.length)
                //console.log(response.data)
            } else {
                console.log("Klarte ikke hente innlegg")
            }
        }

        getTotalPosts()
    }, [page])

    if (isLoading || currentPageParam) return (
        <div>
            <Loader />
        </div>
    )

    return (
        <>
            <View text="Oppskrifter" image="https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
            <div className="container mt-3">
                <RecipePosts page={currentPage} postsPerPage={postsPerPage} />
                <PostPagination pages={pages} template="oppskrifter" currentPage={currentPage} totalPosts={totalPosts} isLoading={isLoading} />
            </div>
        </>
    )
}
export default RecipePostLists