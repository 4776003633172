import React, { useContext, useState } from 'react';
import Axios from 'axios';

import StateContext from '../../../StateContext';

function DeleteGroupActivity(props) {

    const appState = useContext(StateContext)

    const [deleted, setDeleted] = useState(false);
    const [deletedErr, setDeletedErr] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    async function deleteActivity(e) {
        e.preventDefault();

        try {
            const response = await Axios.delete(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/activity/' + props.activityId, {
                headers: headers
            })
            console.log(response)
            setDeleted(true)
        } catch (error) {
            setDeletedErr(true)
            console.log(error)
        }

    }

    if (deleted) {
        return <div className="alert alert-success">Slettet</div>
    } else if (deletedErr) {
        return <div className="alert alert-danger">Ikke slettet</div>
    }

    return (
        <form onSubmit={deleteActivity}>
            <button style={{backgroundColor: "transparent", border: 'none'}}><i className="fa fa-trash"></i></button>
        </form>
    )
}
export default DeleteGroupActivity