import React, { useEffect, useState } from 'react'
import Axios from 'axios'

function TeamTransfers(props) {

    const [isLoading, setIsLoading] = useState(true)
    const [transfers, setTransfers] = useState()
    const [transfersIn, setTransfersIn] = useState()
    const [permanentTransfersIn, setPermanentTransfersIn] = useState()
    const [permanentTransfersOut, setPermanentTransfersOut] = useState()
    const [transfersOut, setTransfersOut] = useState()
    const [showTransfers, setShowTransfers] = useState("in")
    const [season, setSeason] = useState("2020")

    useEffect(() => {

        async function fetchData() {
            setIsLoading(true)
            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/transfers/team/" + props.id,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })

                console.log(response.data.api.transfers)
                
                setTransfers(response.data.api.transfers)

                const teamTransfersIn = response.data.api.transfers.filter(transfer => {
                    return transfer.team_in.team_id === +props.id
                })

                const teamTransfersOut = response.data.api.transfers.filter(transfer => {
                    return transfer.team_out.team_id === +props.id
                })

                setTransfersIn(teamTransfersIn);
                setTransfersOut(teamTransfersOut);


                
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchData()



    }, [props.id])

    useEffect(() => {

        if (transfersIn && transfersOut) {
            const permanentTransfersTo = transfersIn && transfersIn.filter(transfer => {
                return transfer.type !== "Loan" && transfer.type !== "loan"
            })
            console.log(permanentTransfersTo)
            setPermanentTransfersIn(permanentTransfersTo)

            const permanentTransfersFrom = transfersOut && transfersOut.filter(transfer => {
                return transfer.type !== "Loan" && transfer.type !== "loan"
            })
            setPermanentTransfersOut(permanentTransfersFrom)

        }
            

    }, [transfersIn, transfersOut])

    if (isLoading) return <p>Loading transfers....</p>

    return (
        <div className="bg-white row">
            
            {transfers && 
                <>
                <div className="col-12" style={{fontSize: "14px"}}>
                    <div className="row">
                        <div className="column-2">
                            <button className={season === "2020" ? "btn-block buttonTransparent btn-outline-primary p-2" : "btn-block text-light buttonTransparent p-2"} onClick={() => setSeason("2020")}>2020</button>
                        </div>
                        <div className="column-2">
                            <button className={season === "2019" ? "btn-block buttonTransparent p-2 btn-outline-primary" : "btn-block text-light buttonTransparent p-2"} onClick={() => setSeason("2019")}>2019</button>
                        </div>
                        <div className="column-2">
                            <button className={season === "2018" ? "btn-block buttonTransparent btn-outline-primary p-2" : "btn-block text-light buttonTransparent p-2"} onClick={() => setSeason("2018")}>2018</button>
                        </div>
                        <div className="column-2">
                            <button className={season === "2017" ? "btn-block buttonTransparent btn-outline-primary p-2" : "btn-block text-light buttonTransparent p-2"} onClick={() => setSeason("2017")}>2017</button>
                        </div>
                        <div className="column-2">
                            <button className={season === "2016" ? "btn-block buttonTransparent btn-outline-primary p-2" : "btn-block text-light buttonTransparent p-2"} onClick={() => setSeason("2016")}>2016</button>
                        </div>
                        <div className="column-2">
                            <button className={season === "" ? "btn-block buttonTransparent btn-outline-primary p-2" : "btn-block text-light buttonTransparent p-2"} onClick={() => setSeason("")}>All</button>
                        </div>                                                                
                    </div>
                </div>
                <div className="col-12"  style={{fontSize: "14px"}}>
                    <div className="row">
                        <div className="column-6">
                            <button 
                                className={showTransfers === "in" ? "btn-block buttonTransparent btn-outline-primary p-2" : "btn-block text-light buttonTransparent p-2"} 
                                onClick={() => setShowTransfers("in")}
                            >
                                Transfers in
                            </button>
                        </div>
                        <div className="column-6">
                            <button 
                                className={showTransfers === "out" ? "btn-block buttonTransparent btn-outline-primary p-2" : "btn-block text-light buttonTransparent p-2"} 
                                onClick={() => setShowTransfers("out")}
                            >
                                Transfers out
                            </button>
                        </div>                                                             
                    </div>
                </div>
                {showTransfers === "in" &&
                    <>
                        <div className="col-12">
                            <h2 className="text-center my-2">In</h2>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Player</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Date</th>
                                        <th>Sum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {permanentTransfersIn && permanentTransfersIn.map((transfer, index) => {

                                    if (transfer.transfer_date.includes(season)) {
                                        return  (
                                            <tr key={index}>
                                                <td>{transfer.player_name}  </td>
                                                <td>{transfer.team_out.team_name}</td>
                                                <td>{transfer.team_in.team_name}</td>
                                                <td>{transfer.transfer_date}</td>
                                                <td>{transfer.type}</td>
                                            </tr>
                                        )
                                    }
                                })}
                                </tbody>
                            </table>
                        </div>
                    </>
                } 

                {showTransfers === "out" &&
                    <>
                        <div className="col-12">
                            <h2 className="text-center my-2">Out</h2>
                        </div>
                        
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Player</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Date</th>
                                        <th>Sum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {permanentTransfersOut.map((transfer, index) => {
                                    
                                    if (transfer.transfer_date.includes(season)) {

                                        
                                        return  (
                                            <tr key={index}>
                                                <td>{transfer.player_name}  </td>
                                                <td>{transfer.team_out.team_name}</td>
                                                <td>{transfer.team_in.team_name}</td>
                                                <td>{transfer.transfer_date}</td>
                                                <td>{transfer.type}</td>
                                            </tr>
                                        )
                                    }
                                })}
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                </>
            }
            <br/><br/>

        </div>
    )

}
export default TeamTransfers