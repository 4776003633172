import React, {useState, useEffect, useContext} from 'react'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import StateContext from '../../StateContext';

function Comments(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(true);
    const [comments, setComments] = useState([]);

    useEffect(() => {

        async function getBlogPostComments() {

            try {
                setIsloading(true)
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/comments?post=" + props.postId);
                if (response.data) {
                    setComments(response.data)
                    //console.log(response.data)
                } else {
                    console.log("Klarte ikke hente innlegg")
                }
                setIsloading(false)
            } catch (error) {
                
            }

        }
        getBlogPostComments()
    }, [])

    if (isLoading) return (

        <div>Loading....
            <Loader />
        </div>

    )

    return (
        <>
        <div className="card my-3">
            <div className="card-header">
                {comments.length} {comments.length === 1 ? "Kommentar" : "Kommentarer"}
            </div>
        </div>
        <>
            
            {comments.map(comment => {
                
                const currentTime = new Date().getTime()              
                const dateComment = new Date(comment.date);

                const dateCommentTime = dateComment.getTime()

                const dateCommentMinutes = dateCommentTime/1000/60 // Seconds/minutes
                
                const minutesSinceComment = Math.round( (currentTime - dateCommentTime) / 1000 / 60 )

                const monthComment = dateComment.getMonth() +1;
                const dayComment = dateComment.getDate();
                const hoursComment = dateComment.getHours()
                let timeComment = dateComment.getMinutes()
                timeComment < 10 ? timeComment = "0" + timeComment : timeComment = timeComment
                
                return (
                    <div key={comment.id} >
                        <div className="card card-body mb-2">
                            <p>
                                <strong className="font-weight-bold">{comment.author_name}</strong> 
                                <small className="pull-right text-muted">
                                    
                                    <i className="far fa-clock mx-1"></i>
                                    {minutesSinceComment < 60 ? 
                                        minutesSinceComment + " minutter siden" : 
                                        " den " + dayComment + "/" + monthComment + " kl " + hoursComment + ":" + timeComment }
                                </small>
                            </p>
                            <hr />
                            <div dangerouslySetInnerHTML={{ __html: comment.content.rendered }}></div>
                        </div>                      
                    </div>
                )
                })}
        
        </>
        </>
    )
}
export default Comments