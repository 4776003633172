import * as firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBGl2Jyc7xqH5EOZBZaImMwiwv-Yx5hUsU",
    authDomain: "backend-footgrail.firebaseapp.com",
    databaseURL: "https://backend-footgrail.firebaseio.com",
    projectId: "backend-footgrail",
    storageBucket: "backend-footgrail.appspot.com",
    messagingSenderId: "196396150588",
    appId: "1:196396150588:web:3cd4492848c6fbe4143839",
    measurementId: "G-KMJKNZWLQR"
};

const app = firebase.initializeApp(firebaseConfig)
export const auth = app.auth();
export const firestore = app.firestore()