import React, { useContext } from 'react'
import StateContext from '../../StateContext'
import { Link } from 'react-router-dom';
//import DispatchContext from '../../DispatchContext'

function NotificationsHeader() {

    const appState = useContext(StateContext)
    //const appDispatch = useContext(DispatchContext)

    const notificationsCount = appState.user.appUserNotificationsCount;
    //const notificationsCount = 6;

    return (
        <li className="nav-item">
            <Link to="/bp/notifications" className={"nav-link header-chat-icon " + (notificationsCount ? "text-danger" : "text-dark")}>
                <i className="fas fa-bell"></i>
                {notificationsCount ? <span className="chat-count-badge text-dark">{notificationsCount < 10 ? notificationsCount : "9+"}</span> : ""}
            </Link>  
        </li>
    )
}
export default NotificationsHeader