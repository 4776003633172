import React, { useEffect, useContext, useState } from 'react'
import {useParams} from 'react-router-dom'
import Loader from '../Loader/Loader'
import Axios from 'axios'
import {useImmerReducer} from 'use-immer'
import StateContext from "../../StateContext"
import DispatchContext from "../../DispatchContext"
import { Link, withRouter } from 'react-router-dom'
import NotFound from '../404/NotFound'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditRecipe(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [value, setValue] = useState("");

    const originalState = {
        title: {
            value: "",
            hasErrors: false,
            message: ""
        }, 
        body: {
            value: "",
            hasErrors: false,
            message: ""
        },
        isFetching: true,
        isSaving: false,
        id: useParams().id,
        sendCount: 0,
        notFound: false
    }

    function ourReducer(draft, action) {
        switch (action.type) {
            case "fetchComplete":
                draft.title.value = action.value.title.rendered
                draft.body.value = action.value.content.rendered
                draft.isFetching = false
                return
            case "titleChange":
                draft.title.hasErrors = false
                draft.title.value = action.value
                return
            case "bodyChange":
                draft.body.hasErrors = false
                draft.body.value = action.value
                return 
            case "submitRequest":
                if (!draft.title.hasErrors && !draft.body.hasErrors) {
                    draft.sendCount++
                }
                return
            case "saveRequestStarted": 
                draft.isSaving = true
                return
            case "saveRequestFinished":
                draft.isSaving = false
                return
            case "titleRules":
                if (!action.value.trim()) {
                    draft.title.hasErrors = true
                    draft.title.message = "Du må oppgi en tittel"
                }
                return 
            case "bodyRules":
                if (!action.value.trim()) {
                    draft.body.hasErrors = true
                    draft.body.message = "Du må oppgi innhold"
                }
                return 
            case "notFound":
                draft.notFound = true;
                return
        }
    }

    const [state, dispatch] = useImmerReducer(ourReducer, originalState)

    function submitHandler(e) {
        e.preventDefault()
        dispatch({type: "titleRules", value: state.title.value})
        dispatch({type: "bodyRules", value: state.body.value})
        dispatch({type: "submitRequest"})
    }

    useEffect(() => {

        const ourRequest = Axios.CancelToken.source()

        async function fetchPost() {
            
            try {
                const response = await Axios.get(`${appState.wordpressBaseUrl}wp-json/wp/v2/recipe/${state.id}`,
                {  
                    token: appState.user.token
                },  
                {
                    cancelToken: ourRequest.token
                })
                if (response.data) {

                    setValue(response.data.content.rendered)

                    dispatch({type: "fetchComplete", value: response.data})
                    if (appState.user.username != response.data.author.username) {
                        appDispatch({type: "flashMessage", value: "Du har ikke rettighet til å redigere dette innlegget"});

                        props.history.push("/");
                    }
                    console.log("OPPSKRIFT: ", response.data)
                } else {
                    dispatch({type: "notFound", value: "Fant ikke innlegg"})
                }
              
            } catch (error) {
                console.log(error)
            }
            
        }
        fetchPost()
        return () => {
            ourRequest.cancel()
        }
    }, [])

    useEffect(() => {

        if (state.sendCount) {
            dispatch({type: "saveRequestStarted"})
            const ourRequest = Axios.CancelToken.source()

            async function fetchPost() {
                
                try {
                    const response = await Axios.post(`${appState.wordpressBaseUrl}wp-json/wp/v2/recipe/${state.id}`, 
                    {   title: state.title.value, 
                        body: value, 
                        token: appState.user.token
                    }, 
                    {cancelToken: ourRequest.token}
                    )
                    dispatch({type: "saveRequestFinished"})
                    appDispatch({type: "flashMessage", value: "Innlegg er oppdatert!"})
                    
                } catch (error) {
                    console.log("There was a problem or the request was cancelled.")
                }
                
            }
            fetchPost()
            return () => {
                ourRequest.cancel()
            }
        }

    }, [state.sendCount ])

    if (state.notFound) {
        return (
            <NotFound/>
            
        )
    }

    if (state.isFetching) return <Loader />

    return (
        <div className="container my-4">
            
            <Link className="small font-weight-bold my-3" to={`/oppskrift/${state.id}`}>Tilbake til innleggsside</Link>
            <form className="mt-3" onSubmit={submitHandler}>
                <div className="form-group">
                    <label htmlFor="post-title" className="text-muted mb-1">
                        <small>Title</small>
                    </label>
                    {console.log("STATE.TITLE: ", state.title.value)}
                    <input 
                        onBlur={e => dispatch({type: "titleRules", value: e.target.value})} 
                        onChange={e => dispatch({type: "titleChange", value: e.target.value})} 
                        value={state.title.value} 
                        autoFocus 
                        name="title" 
                        id="post-title" 
                        className="form-control form-control-lg form-control-title" 
                        type="text" 
                        placeholder="" 
                        autoComplete="off" 
                    />
                    {state.title.hasErrors && <div className="alert alert-danger small liveValidateMessage">{state.title.message}</div>}
                </div>

                <ReactQuill theme="snow" value={value} onChange={setValue}/>

                <div className="form-group">
                    <label htmlFor="post-body" className="text-muted mb-1 d-block">
                        <small>Body Content</small>
                    </label>
                    <textarea onBlur={e => dispatch({type: "bodyRules", value: e.target.value})} onChange={e => dispatch({type: "bodyChange", value: e.target.value})} name="body" id="post-body" className="body-content tall-textarea form-control" type="text" value={state.body.value} />
                    {state.body.hasErrors && <div className="alert alert-danger small liveValidateMessage">{state.body.message}</div>}
                </div>

                <div className="form-group">
                    <label htmlFor="post-body" className="text-muted mb-1 d-block">
                        <small>Bilde url</small>
                    </label>
                    
                </div>

                <button className="btn btn-primary" disabled={state.isSaving}> {!state.isSaving ? "Save New Post" : "Oppdaterer"} </button>
            </form>

        </div>
    )
}
export default withRouter(EditRecipe)