import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import StateContext from '../../../StateContext'

function Members() {
    const appState = useContext(StateContext)
    const [isLoading, setIsloading] = useState(true);
    const [members, setMembers] = useState([]);

    useEffect(() => {

        async function getMembers() {

            try {
                //console.log(page)
                setIsloading(true)
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/members");
                if (response.data) {
                    setMembers(response.data)
                    //console.log(posts)
                } else {
                    console.log("Klarte ikke hente medlemmer")
                }
                setIsloading(false)
            } catch (error) {

            }

        }
        getMembers()
    }, [])

    if (isLoading) return (

        <div>Loading....
            <Loader />
        </div>

    )

    return (
        <div className="container">
            <h1 className="text-center">Members page</h1>
            <div>
                {members.map(member => {
                    return (
                        <div key={member.id} className="col-lg-6 mx-auto">
                            <div className="card card-body my-3">
                                <div>
                                    <Link to={`/bp/members/${member.id}`}>
                                        <h2>{member.name}</h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )

                })}
            </div>
        </div>

    )
}
export default Members