import React, { useEffect, useState } from 'react'
import Axios from 'axios'

function AllFixtures() {

    const [fixtures, setFixtures] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchFixtures() {
            setIsLoading(true)
            try {
                const response = await Axios.get("https://api-football-v1.p.rapidapi.com/v2/predictions/157462?x-rapidapi-key=4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45", 
                {headers: {
                    'Authorization': 'X-RapidAPI-Key: 4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45'
                }}
                )
                if (response.data) {
                    setFixtures(response.data)
                }
                console.log(response.data.api, "PREDICTIONS");
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false)

        }
        fetchFixtures()

    }, [])

    if (isLoading) return <p>Laster...</p>

    return (
        <div className="container my-5">
            <h2>All Fixtures</h2>

            {fixtures ? 
            <>

            {fixtures.length ? 
            fixtures.map(fixture => {
                return (
                    <p>Fixture</p>
                )
            })
            : <p>1 fixture

                {console.log(fixtures)}
            </p>
            }
            </>

            : <p>No fixtures 
                {console.log(fixtures)}
            </p>
        
            
        } 
        
        </div>
    )

}
export default AllFixtures