import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import Loader from '../../Loader/Loader';
import { Link } from 'react-router-dom';

function SearchTeams() {

    const [teams, setTeams] = useState()
    const [searchTerm, setSearchTerm] = useState()
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        async function search() {

            

                let url
                if (!searchTerm || searchTerm === "") {
                    url = "https://api-football-v1.p.rapidapi.com/v2/teams/league/2"
                } else {
                    url = "https://api-football-v1.p.rapidapi.com/v2/teams/search/" + searchTerm
                }

                console.log(url)

                setIsLoading(true)
                try {

                    const response = await Axios({
                        "method":"GET",
                        "url":url,
                        "headers":{
                        "content-type":"application/octet-stream",
                        "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                        "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                        "useQueryString":true
                        }
                    })
                    setTeams(response.data.api.teams)
                    console.log(response.data.api.teams)
                    
                } catch (error) {
                    console.log(error)
                }
                setIsLoading(false)
            

        }
        search()

    }, [searchTerm])

    return (
        <div className="my-5">
            <div className="search-overlay-top shadow-sm">
                <div className="container container--narrow">
                    <label htmlFor="live-search-field" className="search-overlay-icon">
                    <i className="fas fa-search"></i>
                    </label>
                    <input autoFocus className="live-search-field" onChange={e => setSearchTerm(e.target.value)} placeholder="Search for a team..." />
                </div>
            </div>

            {isLoading ? <Loader /> : teams && teams.length && (
                <div className="container">
                    {teams.map((team, index) => {
                        return (
                            <div className="card card-body" key={index}>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={team.logo} className="img-fluid teamLogo" />
                                    </div>
                                    <div className="col-10">
                                        <span><Link to={`/football/teams/${team.team_id}`}>{team.name}</Link></span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}


        </div>
    )

}
export default SearchTeams

