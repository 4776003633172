import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import DispatchContext from '../../DispatchContext'
import StateContext from '../../StateContext'
import {
    MDBNavbarNav, MDBNavItem, 
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBNavLink
    } from "mdbreact";
import Axios from 'axios';
import MessagesHeader from '../BuddyPress/Message/MessagesHeader';
import NotificationsHeader from './NotificationsHeader';

function HeaderLoggedIn(props) {

    const appDispatch = useContext(DispatchContext)
    const appState = useContext(StateContext)

    useEffect(() => {

        if (appState.user.token && !appState.user.appUserId) {
            async function fetchAppUserId() {
                try {
                    const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/users?search=" + appState.user.user_email)
                    
                    if (response.data.length) {
                        console.log("FETCH USER ID: ", response.data)
                        localStorage.setItem("appUserId", response.data[0].id)
                        appDispatch({type: "appBPMemberIdFetched", value: response.data[0].id});   
                    } else {
                        const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/members?search=" + appState.user.user_nicename)
                        if (response) {
                            localStorage.setItem("appUserId", response.data[0].id)
                            appDispatch({type: "appBPMemberIdFetched", value: response.data[0].id});   
                            console.log("FETCH USER ID MEMBER: ", response.data)
                        } else {
                            localStorage.setItem("appUserId", 3)
                            console.log("error fetching member id")
                        }
                        console.log(response.data, "              RESPONSE APP FETCH USER ID ---------------------------------")
                    }
                } catch (error) {
                    console.log("appUserIDFetch ---- ERROR!!!");
                }
            }
            fetchAppUserId()
        }
        
    }, [])



    function logOut() {
        appDispatch({type: "logout"})
        appDispatch({type: "flashMessage", value: "Du har blitt logget ut!"});
    }

    /*function isSearchOpen(e) {
        e.preventDefault()
        appDispatch({
            type: "openSearch"
        })
    }*/
    

    return (
        <MDBNavbarNav right>
            <NotificationsHeader onClick={() => {props.isOpen && props.setIsOpen(false)}}/>
            <MessagesHeader onClick={() => {props.isOpen && props.setIsOpen(false)}}/>
            <MDBNavItem>
                <MDBNavLink onClick={() => {props.isOpen && props.setIsOpen(false)}} to="/create-post">Nytt innlegg</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
                <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                    <MDBIcon icon="user" />
                    </MDBDropdownToggle>
                        <MDBDropdownMenu onClick={() => {props.isOpen && props.setIsOpen(false)}} className="dropdown-default">
                            <MDBDropdownItem><Link to="/bp/activity">Aktivitet</Link></MDBDropdownItem>
                            <MDBDropdownItem><Link to="/bp/groups">Grupper</Link></MDBDropdownItem>
                            <MDBDropdownItem><Link to="/bp/messages">Meldinger</Link></MDBDropdownItem>
                            <MDBDropdownItem><Link to="/bp/members">Medlemmer</Link></MDBDropdownItem>
                            <MDBDropdownItem><Link to="/bp/invites">Invitasjoner</Link></MDBDropdownItem>
                            <MDBDropdownItem><Link to={"/bp/members/" + appState.user.appUserId}>Din profil</Link></MDBDropdownItem>
                            {appState.user.appAdmin && (
                                <MDBDropdownItem><Link to="/admin">Admin</Link></MDBDropdownItem>
                            )}
                            <MDBDropdownItem><Link to="/add-media">Legg til media</Link></MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </MDBNavItem>
            <button onClick={logOut} className="btn btn-sm btn-danger btn-rounded">
                Logg ut
          </button>
        </MDBNavbarNav>
    )

}
export default HeaderLoggedIn