import React, { useEffect, useContext, useState } from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'
import StateContext from '../../../StateContext'

function CurrentMemberFriends(props) {


    /// friend_id can not equal current logged in user id
    /// only return friends for current user
    // friend_id == member_id || initiator_id === member_id

    const [friends, setFriends] = useState([])
    const [memberFriends, setMemberFriends] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const appState = useContext(StateContext)
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {
        async function getMemberFriends() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/friends?_embed", 
                    {
                        data: {
                            context: "view",
                            'user_id': props.id,
                            'is_confirmed': 1
                    },
                    headers: headers
                    })
                setFriends(response.data)
            } catch (error) {
                console.log("getMemberFriendsError: ", error)
            }
            setIsLoading(false)
        }
        getMemberFriends()
        
    }, [])

    useEffect(() => {
        async function getCurrentMemberFriends() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/friends/" + props.id + "?_embed", 
                    {
                        data: {
                            context: "view"
                    },
                    headers: headers
                    })
                console.log(response.data, "     FRIENDS MEMBERFRIENDS")
                setMemberFriends(response.data)
            } catch (error) {
                console.log("getMemberFriendsError: ", error)
            }
            setIsLoading(false)
        }
        getCurrentMemberFriends()
        
    }, [])

    function formatDate(date) {
        let dateFriendship = new Date(date);
        const day = dateFriendship.getDate()
        let month = dateFriendship.getMonth()
        
        const year = dateFriendship.getFullYear();

        return `${day}/${month+1}/${year}`
    }

    if (isLoading) return <p>Laster.....</p>

    return (
        <>
            <br/>
            <div className="row">
                {friends.map(friend => {
                    return (
                        <div key={friend.id} className="col-md-6">
                            {friend.is_confirmed && 

                                <>
                                    {friend.friend_id == props.id ? 
                                        <div className="card card-body mb-3">
                                            <div className="row">
                                                <div className="col-3 col-md-2">
                                                    <img alt="avataruser" src={friend._embedded.initiator[0].avatar_urls.full} className="img-fluid avatar rounded-circle" />
                                                </div>
                                                <div className="col-9 col-md-10">
                                                    <Link to={"/bp/members/" + friend.initiator_id}>
                                                        <p className="font-weight-bold">{friend._embedded.initiator[0].name}</p>
                                                    </Link>
                                                    <p><small>Siden: {formatDate(friend.date_created)} </small></p>
                                                </div>
                                            </div>
                                        </div>                                
                                    : 
                                        <div className="card card-body mb-3">
                                            <div className="row">
                                                <div className="col-3 col-md-2">
                                                    <img alt="avataruser" src={friend._embedded.friend[0].avatar_urls.full} className="img-fluid avatar rounded-circle" />
                                                </div>
                                                <div className="col-9 col-md-10">
                                                    <Link to={"/bp/members/" + friend.friend_id}>
                                                        <p className="font-weight-bold">{friend._embedded.friend[0].name}</p>
                                                    </Link>
                                                    <p><small>Siden: {formatDate(friend.date_created)} </small></p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    )
                })}
            </div>
            <br/>
        </>
    )

}
export default CurrentMemberFriends