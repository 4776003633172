import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import StateContext from '../../StateContext';

function NavbarInsta() {

    const appState = useContext(StateContext)

    return (
        <nav className="navbar-insta">
            <div className="col-lg-6 mx-auto">
                <ul className="navbar-nav">

                    <div className="row text-center">
                        <div className="col-1"></div>
                        <div className="col-2">
                            <li className="nav-item active">
                                <Link className="nav-link" to={`/`}><i className="fas fa-home"></i></Link>
                            </li> 
                        </div>
                        <div className="col-2">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/search-movies"><i className="fas fa-search"></i></Link>
                            </li> 
                        </div>
                        <div className="col-2">
                        <li className="nav-item active">
                                <Link className="nav-link" to="/bp/activity"><i className="far fa-plus-square"></i></Link>
                            </li> 
                        </div>
                        <div className="col-2">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/bp/favoritedmedia">
                                    <i className="far fa-heart"></i>
                                </Link>
                            </li> 
                        </div>
                        <div className="col-2 col-lg-2 ">
                            <li className="nav-item active">
                                <Link className="nav-link" to={`/bp/members/${appState.user.appUserId}`}>
                                    <div className="col-lg-6 mx-auto">                               
                                        <i className="fas fa-user"></i>
                                    </div>
                                </Link>
                            </li> 
                        </div>
                        
                    </div>
                </ul>
            </div>
        </nav>
    )

}
export default NavbarInsta