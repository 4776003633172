import React, { useState, useContext } from 'react'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import StateContext from '../../../StateContext'
import Editor from '../../Editor/Editor'

function AddPost() {

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(false)
    const [post, setPost] = useState("");

    const [postQuill, setPostQuill] = useState()

    const [posted, setPosted] = useState(false)
    const [postError, setPostError] = useState(false)

    const token = appState.user.token

    async function addPost(e) {
        e.preventDefault()
    
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    
        try {
            setIsloading(true)
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity", 
                { content: post },
                {headers: headers }
            )
            if (response.data) {
                setPosted(true)
                console.log(response)  
                console.log(response.data)        
            } else {
                console.log("Feil brukernavn eller passord")
                setPostError(true)
            }
            setIsloading(false)
        } catch (error) {
            console.log(error)
            setPostError(true)
            setIsloading(false)
        }
    }



    if (isLoading) return <Loader />



    if (posted) {
        return  (
            <>
                <div className="alert alert-success">Kommentar sendt</div>

                <div className="card my-2">
                    <div className="card-body">                                
                        <div className="row">
                            <div className="col-3 col-md-1">
                                <img alt="avatar-media" src="//www.gravatar.com/avatar/954e55e8323729f816c5f80277373afb?s=50&#038;r=g&#038;d=mm" className="img-fluid rounded-circle" /> 
                            </div>
                            <div className="col-9 col-md-11">
                                <span>Du delte:</span><br />
                                <small className="text-secondary">Akkurat nå</small>
                            </div>
                        </div>
                        
                        <br />
                        <div>{post}</div>                                                           
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="mt-2 mb-2">

            <div className="card">
                {postError && <p>Kommentar ikke sendt, prøv igjen</p>}
                <Editor postQuill={postQuill} setPostQuill={setPostQuill} />
            </div>
            <br/>
        </div>
        
    )
}
export default AddPost

