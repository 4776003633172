import React, {useState, useContext, useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import StateContext from '../../../StateContext'
import DispatchContext from '../../../DispatchContext'
import AddCommentPost from '../Activity/AddCommentPost'
import ModalDetails from '../Templates/ModalDetails'

function SingleActivityTemplate(props) {

    const activity = props.activity
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [liked, setLiked] = useState(false)
    const [postId, setPostId] = useState([])
    const [favorite, setFavorite] = useState()
    const [isfavoriteLoading, setIsfavoriteLoading] = useState(false)
    const [showCommentField, setShowCommentField] = useState(false)
    const [imagePadding, setImagePadding] = useState()

    const imageRef = useRef();

    const [showDetails, setShowDetails] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        if (activity.content.rendered.includes("img")) {
            setImagePadding("p-3")
        } else {
            setImagePadding("")
        }

        
    }, [])

    async function favoritePost(e) {
        e.preventDefault()

        let formData = new FormData(e.target);    //formdata object

        formData.set('postId', formData.get('postId'));   //append the values with key, value pair
        const postId = formData.get("postId")


        console.log("PostId: ", postId)

        setIsfavoriteLoading(true)
        try {
            const response = await Axios.post(
                appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity/" + postId + "/favorite",
                    {data: {
                        context: 'edit'
                    }},
                    {headers: headers }
                );
            if (response.data) {
                setFavorite(true);
                setLiked(true)
                console.log("Response Data: ", response.data)
                appDispatch({type: "flashMessage", value: "Innlegg likt!"})
            } else {
                console.log("Noe gikk galt")
            }
        } catch (error) {
            console.log(error)
        }
        setIsfavoriteLoading(false)
    }

    async function removeFavoritePost(e) {
        e.preventDefault()

        let formData = new FormData(e.target);    //formdata object

        formData.set('postIdRemove', formData.get('postIdRemove'));   //append the values with key, value pair
        const postIdRemove = formData.get("postIdRemove")



        console.log("PostId: ", postId)

        setIsfavoriteLoading(true)
        try {
            const response = await Axios.post(
                appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity/" + postIdRemove + "/favorite",
                    {data: {
                        context: 'delete'
                    }},
                    {headers: headers }
                );
            if (response.data) {
                setFavorite(false);
                console.log("Response Data: ", response.data)
                appDispatch({type: "flashMessage", value: "Like fjernet!"})
            } else {
                console.log("Noe gikk galt")
            }
        } catch (error) {
            console.log(error)
        }
        setIsfavoriteLoading(false)
    }

    function setFavoritedPost() {
        setLiked(true)
    }

    function setFavoritedPostFalse() {
        setLiked(false)
    }

    function toggleDetails() {
        if (!showDetails) {
            setShowDetails(true)
        } else {
            setShowCommentField(false)
        }
    }

    function imagePaddingActivity(content) {
        
            if (content.includes("img")) {
                return "text-center"
            } else {
                return "p-3"
            }
        
    }

    

    return (
        <div className="z-depth-1 bg-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-10">
                        <div className="row">
                            <div className="col-3 col-lg-2">
                                <Link to={`/bp/members/${activity.user_id}`}>
                                    <img src={activity.user_avatar.thumb} alt="user-avatar" className="img-fluid rounded-circle" />
                                </Link>
                            </div>
                            <div className="col-9 col-lg-10">
                                <div dangerouslySetInnerHTML={{__html: activity.title}}></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-2">
                        <div className="float-right">
                            <div onClick={toggleDetails} style={{cursor: "pointer"}}>
                                <i className="fas fa-ellipsis-h"></i>
                            </div>
                            
                        </div>
                    </div>

                    {showDetails && (
                        <ModalDetails authorPost={activity.user_id} id={activity.id} toggleDetails={toggleDetails} showDetails={showDetails} setShowDetails={setShowDetails} />
                    )}

                </div>
            </div>
            
            

            <div ref={imageRef} className={imagePaddingActivity(activity.content.rendered)} dangerouslySetInnerHTML={{__html: activity.content.rendered}}></div> 

            <div className="p-3" style={{marginBottom: "-10px", marginTop: "-10px !important"}}>
                <div className="row no-gutters">
                    <div className="col-6">
                        <div className="d-flex justify-content-start">

                            <div className="mr-1" style={{paddingLeft: "0px"}}>
                                {!activity.favorited ? 
                                    <form onSubmit={favoritePost}>
                                        <input type="hidden" name="postId" onChange={e => setPostId(e.target.value)} value={activity.id} />
                                        <button onClick={setFavoritedPost}  type="submit" style={{backgroundColor: "transparent", border: "none", marginLeft: "-8px"}}>
                                            <i className={`${liked ? "fas fa-heart text-danger" : "far fa-heart"}`}></i>
                                        </button>    
                                    </form> : 
                                    <form onSubmit={removeFavoritePost}>
                                        <input type="hidden" name="postIdRemove" value={activity.id} />
                                        <button onClick={setFavoritedPostFalse} type="submit" style={{backgroundColor: "transparent", border: "none", marginLeft: "-8px"}}>
                                            <i className={`fas fa-heart text-danger`}></i>
                                        </button>    
                                    </form>  
                                } 
                            </div>

                            <div className="mr-1">
                                {props.isSingle ? 
                                    <i className="far fa-comment mr-1"></i>
                                :
                                    <Link to={`/bp/activity/${activity.id}`}>
                                        <i className="far fa-comment mr-1"></i>
                                    </Link>
                                }
                            </div>   

                            <div className="mr-1">
                                <i className="far fa-paper-plane"></i>
                            </div>  

                        </div>
                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-end">
                            <i className="far fa-bookmark"></i>
                        </div>
                    </div>
                </div>

                <p className="font-weight-bold" style={{fontSize: "0.9rem"}}> {activity.favorites ? activity.favorites + " likes" : "0 likes"}</p>
                
                <div className="text-muted">
                    {activity.comment_count > 0 ? 
                        <>
                            { activity.comment_count === 1 ? 
                                <span  
                                onClick={() => {
                                    !showCommentField ? 
                                    setShowCommentField(true) :
                                    setShowCommentField(false)
                                }} 
                                >
                                    {activity.comment_count} kommentar
                                </span> : 
                                <span
                                onClick={() => {
                                    !showCommentField ? 
                                    setShowCommentField(true) :
                                    setShowCommentField(false)
                                }} 
                                >
                                    {activity.comment_count} kommentarer
                                </span>
                            }
                        </> : <div style={{marginBottom: "-23px"}}></div>}  
                        
                        {showCommentField && 
                            <>
                                {activity.comments && activity.comments.map(comment => {
                                    return (
                                        <div key={comment.id} className="row my-2">
                                            <div className="col-3 col-md-1">
                                                <img alt="comment avatar" src={comment.user_avatar.thumb} className="img-fluid rounded-circle" />
                                            </div>
                                            <div className="col-9 col-md-11" style={{borderRadius: "1%", backgroundColor: "#fafafa"}}>
                                                <small className="font-weight-bold">{comment.display_name}</small>
                                                <div dangerouslySetInnerHTML={{__html: comment.content.rendered}}></div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                                                                            
                        

                    <br/><small>{props.date}</small>
                </div>
            </div>
            <AddCommentPost parentPostId={activity.id} commentsPost={activity.comments} />
        </div>
    )
}
export default SingleActivityTemplate