import React from 'react'
import LeaguesByCountry from './LeaguesByCountry'
import { useParams } from 'react-router-dom'

function SingleCountry() {

    const {code} = useParams()

    return (
        <div className="container my-3">
            <LeaguesByCountry country={code}  />
        </div>
    )

}
export default SingleCountry