import React from 'react';
import {Link} from 'react-router-dom'
import {MDBBtn, MDBCard, MDBCardTitle, MDBIcon} from 'mdbreact'

function CardImage(props) {
    return (
        <MDBCard
            className='card-image'
            style={{
            backgroundImage:
                `url('${props.imageUrl}')`
            }}
        >
            <div className='text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4'>
                <div>
                    <h5 className='green-text'>
                        <MDBIcon icon='desktop' /> {props.cardCat}
                    </h5>
                    <MDBCardTitle tag='h3' className='pt-2'>
                        <strong>{props.cardTitle}</strong>
                    </MDBCardTitle>
                    <p>
                        {props.cardDescription}
                    </p>
                    <MDBBtn color={props.btnColor + ' text-white'}>
                        <MDBIcon icon='clone left' /> <Link to={`${props.cardLink}`} className="text-white"> {props.btnText}</Link>   
                    </MDBBtn>
                </div>
            </div>
        </MDBCard>
    )
}
export default CardImage