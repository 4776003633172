import React, { useState, useEffect, useContext } from 'react'

import { firestore } from '../firebase'
import {auth} from '../firebase'

import { useParams, Link } from 'react-router-dom'
import FootballBlogList from './FootballBlogList';
import LoginFirebase from '../LoginFirebase';
import StateContext from '../../../StateContext';

function EditFootballBlog() {

    const appState = useContext(StateContext)

    const {id} = useParams();

    const [entry, setEntry] = useState()
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [updateCount, setUpdateCount] = useState(0)
    const [message, setMessage] = useState("")

    const [activeItem, setActiveItem] = useState("1");

    useEffect(() => {

        setIsLoading(true)

        const entryRef = firestore.collection("football-blogs").doc(id);

        entryRef.get().then((doc) => {
            const entry = {id: doc.id, ...doc.data()};
            setEntry(entry)
            setTitle(doc.data().title)
            setContent(doc.data().content)
        })

        setIsLoading(false)
        

    }, [id, updateCount])


    async function editPost(e) {
        e.preventDefault();

        try {
            const editedPost = {
                title: title, 
                content: content,
                featured_image_post: entry.featured_image_post || "https://progitek.no/privat/bp/wp-content/uploads/2020/08/basel-soccer-1536x1024.png",
                wordpress_id: entry.wordpress_id || 33
            }
            const entryRef = await firestore.collection("football-blogs").doc(id).update(editedPost);
            console.log(entryRef, "EntryRef")
            setUpdateCount(updateCount+1)
            setMessage("Oppdatert!")
        } catch (error) {
            setMessage("Noe gikk galt")
        }

    } 

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    if (isLoading) return <p>Laster...</p>

    return (
        <div className="container my-3">
            {appState.loggedInFB ? <p>Logget inn</p> : <LoginFirebase />}
            <h2>Edit post</h2>
            <div className="btn btn-success btn-sm" onClick={() => toggle("1")}>Edit</div>

            {activeItem === "1" && (
                <>
                    {entry ? 
                    <form onSubmit={editPost}>
                        <label>Tittel</label>
                        <input className="form-control mb-2" value={title ? title : ""} onChange={e => setTitle(e.target.value)} />
                        <label>Innhold</label>
                        <textarea rows="20" value={content ? content : ""} onChange={e => setContent(e.target.value)} className="form-control mb-2" />
                        {message && <p>{message}</p>}
                        <button className="btn btn-primary">Oppdater</button>
                        <div className="btn btn-success btn-sm" onClick={() => toggle("2")}>Preview</div>
                        <div className="btn btn-success btn-sm" onClick={() => toggle("3")}>Vis alle innlegg</div>
                    </form>
                    :
                    <>
                        <p>Ingen innhold</p>
                       <FootballBlogList />
                    </>
                    }
                </>
            )}

            {activeItem === "2" && (
                <>
                <h2>{entry.title}</h2>
                <div dangerouslySetInnerHTML={{__html: entry.content}} />
                <div className="btn btn-success btn-sm" onClick={() => toggle("1")}>Edit</div>
                <div className="btn btn-success btn-sm" onClick={() => toggle("3")}>Vis alle innlegg</div>
                </>
            )}

            {activeItem === "3" &&<FootballBlogList />}
            <br />
        </div>
    )
}
export default EditFootballBlog