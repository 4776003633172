import React, { useState, useContext } from 'react'
import DispatchContext from '../../DispatchContext'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import StateContext from '../../StateContext'

function HeaderLoggedOut(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [isLoading, setIsLoading] = useState(false)

    async function login(e) {
        e.preventDefault();
        setIsLoading(true)
        try {
            
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/jwt-auth/v1/token", {username, password})
            if (response.data) {
      
                console.log(response.data)
                appDispatch({type: "login", data: response.data})
                appDispatch({type: "flashMessage", value: "Du har blitt logget inn!"})

            } else {
                console.log("Feil brukernavn eller passord")
                appDispatch({type: "flashMessage", value: "Noe gikk galt under pålogging, prøv igjen"})
            }
        } catch (error) {
            console.log(error)
            appDispatch({type: "flashMessage", value: "Feil brukernavn eller passord"})
        }
        setIsLoading(false)
    }

    return (
        <Link className="btn btn-success btn-rounded btn-sm" to="/bp/login">Logg inn</Link>
    )
}
export default HeaderLoggedOut;