import React, { useEffect, useState, useContext, useRef } from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import AddPost from './AddPost'
import LoginForm from '../Auth/LoginForm'
import StateContext from '../../../StateContext'
import DispatchContext from '../../../DispatchContext'
import ViewJarallax from '../../View/ViewJarallax'
import SingleActivityTemplate from '../Templates/SingleActivityTemplate'
import Tweets from './Tweets'

function Activity() {

    const appState = useContext(StateContext)


    const token = appState.user.token
    const [isLoading, setIsloading] = useState(false);
    const [activity, setActivity] = useState([]);
    const [message, setMessage] = useState("");
    const [perPage, setPerPage] = useState(10)

    const [searchTerm, setSearchTerm] = useState()

    const [action, setAction] = useState("loadposts")
    const [loadingMore, setLoadingMore] = useState(false)

    const bottomEl = useRef(null)
    const topEl = useRef(null)
    const postEl = useRef(null)
    
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }


    useEffect(() => {

        async function getActivity() {

            let url;
            if (searchTerm) {
                url = appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity?search=" + searchTerm + "&display_comments=true&per_page=" + perPage
            } else {
                url = appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity?display_comments=true&per_page=" + perPage
            }
  
            try {
                //console.log(page)
                if (action !== "loadmore") {
                    setIsloading(true)
                } else {
                    setLoadingMore(true)
                }
                
                const response = await Axios.get(
                    url, 
                        {
                            data: {
                                context: 'view'
                            },
                            headers: headers
                        }
                    );
                if (response.data) {
                    setActivity(response.data)
                    console.log(response.data)
                } else {
                    setActivity([])
                    console.log("Klarte ikke hente aktivitet")
                }
                if (action !== "loadmore") {
                    setIsloading(false)
                }
            } catch (error) {
                setActivity([])
            }
            if (action !== "loadmore") {
                setIsloading(false)
            } else {
                setLoadingMore(false)
            }
            
        }
        getActivity();
    }, [perPage, searchTerm])


    function loadMoreHandler() {
        setPerPage(perPage+10)
        setAction("loadmore")

    }

    if (!appState.user.token) {
        return <p className="text-center my-5">Logg inn for å se aktivitet</p>
    }


    if (isLoading) return (

        <div>
            <Loader />
        </div>

    )



    return (
        <div className="bg-light">
            <ViewJarallax text="Aktivitet" image="https://camillahardilla.com/wp-content/uploads/2020/02/flat-lay-photography-of-vegetable-salad-on-plate-1640777-750x500.jpg"/>
            
            <div className="col-lg-6 col-xl-5 mx-auto">
                {appState.loggedIn ? 
                <>  
                    <div ref={topEl}></div>
                    <AddPost  />

                    <div className="row mb-2">
                        <div className="col-4">
                            <button onClick={() => setSearchTerm("football")} className="btn btn-rounded btn-block btn-info btn-sm">#football</button>
                        </div>
                        <div className="col-4">
                            <button onClick={() => setSearchTerm("film")} className="btn btn-rounded btn-block btn-info btn-sm">#movies</button>
                        </div>
                        <div className="col-4">
                            <button onClick={() => setSearchTerm("")} className="btn btn-rounded btn-block btn-info btn-sm">#all</button>
                        </div>
                        
                    </div>

                    
                    
                    <div ref={postEl} className="">
                        {activity.map(activity => {

                            const date = new Date(activity.date)
                            const dateFormatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}/${date.getHours()}:${date.getMinutes()}`

                            return (
                                <div key={activity.id} className="mb-2">
                                    <SingleActivityTemplate activity={activity} date={dateFormatted} />
                                </div>
                            )

                        })}
                    </div>
                    <div className="text-center pb-4">
                        {activity && activity.length && activity.length >= perPage ?
                        <div className="btn btn-success btn-rounded" onClick={loadMoreHandler} >Last mer</div>
                        : loadingMore ? "Loading older posts" :  "Ingen aktivitet"}
                        
                        <div ref={bottomEl}></div>
                    </div>
                </> 
                : 
                <>
                <div className="alert alert-danger">{message}</div><LoginForm />
                </>}
            </div>
        </div>

    )
}
export default Activity