import React, { useEffect, useState, useContext } from 'react'
import {useParams, Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import StateContext from '../../../StateContext'
import ViewJarallax from '../../View/ViewJarallax'
import Page from '../../ReusableComponents/Page'


function SingleBlog() {

    const {id} = useParams()

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(true);
    const [post, setPost] = useState([]);
    const isLoggedIn = localStorage.getItem("token")

    useEffect(() => {

        async function getBlogPost() {
            setIsloading(true)
            try {

                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/football_blog/" + id + "?_embed");
                if (response.data) {
                    setPost(response.data)
                    console.log(response.data)
                } else {
                    console.log("Klarte ikke hente innlegg")
                }
                
            } catch (error) {

            }
            setIsloading(false)
        }
        getBlogPost()
    }, [id])

    


    if (isLoading) return  (
    
        <div className="text-center">
            <Loader />
        </div>

    )

    function formatDate(date) {
        const datePost = new Date(date)
        const dateFormatted = `${datePost.getDate()}/${datePost.getMonth()+1}/${datePost.getFullYear()}`
        return dateFormatted
    }

    return (
        <Page pageTitle={post && post.title.rendered}>
            <ViewJarallax text={post && post.title.rendered} image={post && post.acf.featured_image_post || "https://images.pexels.com/photos/33129/popcorn-movie-party-entertainment.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} />
            
            <div className="container"> 
                <p className="mt-2">
                    <small>
                        Av <Link to={`/bp/members/${post && post.author}`}>
                                <img src={post && post._embedded.author[0].avatar_urls[96]} className="img-fluid rounded-circle teamLogo"/> {post && post._embedded.author[0].name}
                            </Link> den {formatDate(post.date)} 
                    </small>
                </p> 
                <hr/>          
                <div className="my-4">       
                    <div dangerouslySetInnerHTML={{ __html: post && post.content.rendered }}></div>           
                    <hr/>
                </div>
            </div>   
        </Page>
    )
}
export default SingleBlog