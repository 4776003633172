import React from 'react'
import {Link} from 'react-router-dom'

function FooterBPSocial() {
    return (
        <>
        <div className="row pt-5 mb-3 text-center d-flex justify-content-center">

            <div className="col-md-3 mb-3">
                <h6 className="title font-weight-bold"><Link to="/categories">Kategorier</Link></h6>
            </div>

            <div className="col-md-3 mb-3">
                <h6 className="title font-weight-bold"><Link to="/innlegg/1">Siste nyheter</Link></h6>
            </div>

            <div className="col-md-3 mb-3">
                <h6 className="title font-weight-bold"><Link to="/bp/activity">Samfunn</Link></h6>
            </div>
            <div className="col-md-3 mb-3">
                <h6 className="title font-weight-bold"><Link to="/sider/single/670">About</Link></h6>
            </div>

        </div>

        <hr className="rgba-white-light" />

        <div className="row d-flex text-center justify-content-center mb-md-0 mb-4">
          
          <div className="col-sm-12 col-md-8 mt-5">
            <p>Connectify</p>
          </div>
        </div>
        </>
    )
}
export default FooterBPSocial