import React from 'react'
import './ViewJarallax.css'

function ViewJarallax(props) {

    
    return (

    <div 
        className="view jarallax" 
        data-jarallax='{"speed": 0.2}' 
        style={{backgroundImage: `url(${props.image ? props.image : "https://images.pexels.com/photos/9056/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500"})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center"}} >
        <div className="mask rgba-black-strong">
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="row pt-5 mt-3">
                    <div className="col-md-12 mb-3">
                        <div className="intro-info-content text-center">
                            <h1 className="display-3 mb-5 wow fadeInDown text-white"> 
                            
                                {props.logo && <img className="img-fluid" src={props.logo} style={{maxWidth: "80px"}} /> } 
                                <span className="text-white">{props.preText}</span>
                                <span className="text-primary font-weight-bold">{props.text}</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default ViewJarallax