import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'

function TeamLeagues(props) {

    const [leagues, setLeagues] = useState()
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {

        async function fetchLeagues() {
            setIsLoading(true)
            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/leagues/team/" + props.id,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })
                //console.log(response.data.api.leagues)
                setLeagues(response.data.api.leagues)
                props.setLeagueName(response.data.api.leagues[0].name)

                const currentSeason = response.data.api.leagues.filter(league => {
                    return "League" === league.type && league.is_current === 1;
                })
                console.log(currentSeason[0])
                const seasonStart = new Date(currentSeason[0].season_start);
                const seasonEnd = new Date(currentSeason[0].season_end);
                const seasonStartYear = seasonStart.getFullYear()
                const seasonEndYear = seasonEnd.getFullYear()
                console.log(seasonEnd.getFullYear())
                if (seasonStartYear === seasonEndYear) {
                    console.log("SUMMER SEASON TYPE")
                    props.setCurrentSeason(currentSeason[0].season);
                } else {
                    props.setCurrentSeason(`2019-2020`);
                }

            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchLeagues()


    }, [props.id])

    if (isLoading) {
        return <p>Leagues for team not found</p>
    }

    return (
        <div>
            {leagues && leagues.map((league, index) => {
                if (league.season === 2019) {
                    return (
                        <div className="card card-body" key={index}>
                            
                            <Link to={`/football/league/${league.league_id}`}>
                                <div className="row">
                                    <div className="col-8"><img src={league.logo} className="teamLogo img-fluid" /> {league.name}</div>
                                    <div className="col-4 text-right">{league.type} {league.season}</div>
                                </div>
                                 
                            </Link>
                                
                        </div>
                    )
                }

            })}
            <br/>
        </div>
    )

}
export default TeamLeagues