import React, { useEffect, useContext, useState } from 'react'
import Axios from 'axios'
import StateContext from '../../../StateContext'
import { Link } from 'react-router-dom'

function MemberGroups(props) {

    const appState = useContext(StateContext)

    const [groups, setGroups] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    const currentUserUrl = 'wp-json/buddypress/v1/groups?show_hidden=true&user_id=' + props.userId;
    const guestUserUrl = 'wp-json/buddypress/v1/groups?show_hidden=false&user_id=' + props.userId;

    useEffect(() => {

        async function fetchMemberGroups() {

            setIsLoading(true)

            try {
                let endpoint;
                if (appState.user.appUserId == props.userId) {
                    endpoint = currentUserUrl
                } else {
                    endpoint = guestUserUrl
                }
                const response = await Axios.get(appState.wordpressBaseUrl + endpoint, 
                {
                    headers: headers,
                    data: {
                        context: 'view',
                        user_id: props.userId
                    }
                })
                if (response.data) {
                    setGroups(response.data)
                }
            } catch (error) {
               console.log(error) 
            }
            setIsLoading(false)

        }
        fetchMemberGroups()

    }, [props.userId])

    if (isLoading) return <p>Laster....</p>

    return (
        <div>
            <br />
            {groups.map(group => {
                return (
                    <div className="card card-body" key={group.id} >

                        <Link to={`/bp/groups/${group.id}`} >{group.name}</Link>

                    </div>
                )
            })}
            <br/>
        </div>
    )

} 
export default MemberGroups