import React, { useEffect, useState, useContext } from 'react'
import {useParams, Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import StateContext from '../../../StateContext'
import MessageThreadAvatars from './MessageThreadAvatars'
import Messages from './Messages'

function MessageThread(props) {

    const {id} = useParams()
    
    const appState = useContext(StateContext)
    const appUserId = appState.user.appUserId
    // TODO::
    // 1: Find members of Chat
    // 2: Use member data to add recipients

    const [isLoading, setIsloading] = useState(true);
    const [isMembersLoading, setIsMembersLoading] = useState(true);

    const [messageThread, setMessageThread] = useState([]);
    const [messageContent, setMessageContent] = useState()
    const [posted, setPosted] = useState(false)
    const [membersMessage, setMembersMessage] = useState([])
    const [recipient, setRecipient] = useState(0)

    const [recipientsThread, setRecipientsThread] = useState({})

    const token = localStorage.getItem("token")

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }


    console.log("RecipientsThread", recipientsThread)

    useEffect(() => {

        async function getMembersMessage() {
            setIsMembersLoading(true)
            try {
                
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/members")
                if (response.data) {
                    setMembersMessage(response.data)
                    //console.log("Sammenligning members thread and logged in user: ", response.data[0].user_login, "    ", appState.user.user_nicename)
                    if (response.data[0].user_login === appState.user.user_nicename) {
                        //console.log("Sammenligning members thread and logged in user: ", response.data[0].user_login, "    ", appState.user.user_nicename)
                        setRecipient(response.data[1].id)
                    } else {
                        //console.log("Sammenligning members thread and logged in user 2: ", response.data[1].user_login, "    ", appState.user.user_nicename)
                        setRecipient(response.data[0].id)
                    }

                    setRecipient(response.data)
                } else {
                    console.log("Noe gikk galt under lasting av medlemmer")
                }
            } catch (error) {
                console.log(error)
            }
            setIsMembersLoading(false)
        }

        async function getMessages() {

            try {
                //console.log(page)
                setIsloading(true)
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/messages/" + id + "?_embed", {headers: headers});
                if (response.data) {
                    setMessageThread(response.data)
                    let result = Object.keys(response.data[0].recipients).map(key => {
                        return [Number(key), response.data[0].recipients[key]]
                    })
                    setRecipientsThread(result)
                } else {
                    console.log("Klarte ikke hente medlemmer")
                }
                setIsloading(false)
            } catch (error) {

            }

        }
        getMessages()
        getMembersMessage()
        

    }, [id])

    async function replyMessage(e) {
        e.preventDefault()
        try {
            const recipientArray = recipientsThread.map(recipient => {
        
                return recipient[1].user_id
            })
            //console.log("RECIPIENTARRAY: ", recipientArray)
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/messages", 
                {   
                    context: 'edit',
                    id: id,
                    message: messageContent,
                    recipients: recipientArray     
                }, 
                {
                    headers: headers
                }
            );

            
            if (response.data && response.status === 200) {
                //console.log("Posted message response: ", response.data)
                setPosted(true)
            } else {
                //console.log(response)
            }
        } catch (error) {
            setPosted(false)
            console.log(error)
        }
    }

    if (isLoading || isMembersLoading) return (

        <div>
            <Loader />
        </div>

    )

    return (
        <div className="bg-light" style={{marginTop: "-15px"}}>
            <div className="container-small ">

            <br/>
            <div className="card">

                <div className="row no-gutters">

                    <div className="col-lg-4 column-xl-insta">
                        <div className="card-header text-center pt-4" style={{height: "4.7rem", borderRight: "1px solid #D8D8D8"}}>
                            
                            <span className="font-weight-bold">Direct </span>
                            <span className="float-right">
                                <Link to="/bp/add-messagethread"><i className="far fa-edit"></i></Link>
                            </span>
                        </div>
                        <Messages threadId={id} />
                    </div>

                    <div className="col-lg-8"  style={{borderLeft: "1px solid #D8D8D8"}}>
                        <div className="card-header" style={{height: "4.7rem"}}>
                            <MessageThreadAvatars recipientsThread={recipientsThread} />
                        </div>
                        <div className="pl-2 pr-2" style={{overflowY: "scroll", height: "500px", overFlowX: "hidden"}}>
                            {messageThread ? messageThread[0].messages.map((message, index) => {
                                return (
                                    
                                        <div key={index} >
                                            
                                            <div className="row mt-2">
                                                <div className="col-3 col-md-1">
                                                    <img alt="useravatar" src={message.avatar_urls ? message.avatar_urls.thumb : ""} className="img-fluid rounded-circle" />
                                                </div>

                                                <div className="col-9 col-md-11 float-right">
                                                    <div className={`${message.sender_id == appUserId ? "float-left" : "float-right"}`} >
                                                        <small>{message.sender_id == appUserId && "Du"}</small>              
                                                        <div 
                                                        className={`card card-body ${message.sender_id == appUserId ? "bg-light" : ""} `} 
                                                        dangerouslySetInnerHTML={{__html: message.message.rendered}}
                                                        >                              
                                                        </div>
                                                    </div>
                                                </div>                   
                                            </div>              
                                        </div>
                                    
                                )
                            }) : <div>Kunne ikke laste innhold</div>}
                        </div>
                        

                        {posted && (

                            <div className="row my-5">
                                <div className="col-3 col-md-1">
                                    <img alt="useravatar" src={"http://www.gravatar.com/avatar/17a4c5382d0dfa87efaa5d194c098ec9?s=50&#038;r=g&#038;d=mm"} className="img-fluid rounded-circle" />
                                </div>
                                <div className="col-9 col-md-11 float-right">
                                    <small>Din melding: </small>              
                                    <div className={"card card-body bg-primary text-white"}><p>{messageContent}</p> </div>
                                </div>                   
                            </div>     

                        )}
                        {posted && <div className="alert alert-success">Melding sendt!</div>}


                            <form className="mt-3" onSubmit={replyMessage}>
                                <div class="form-row">
                                    <div class={messageContent ? "col-10" : "col-12"}>
                                        <textarea onChange={e => setMessageContent(e.target.value)} className="form-control bg-light" placeholder="Send message" value={messageContent}></textarea>

                                    </div>
                                    {messageContent ? 
                                        <div className="col-2 text-center">
                                            <button 
                                                className={messageContent !== "" ? "text-primary" : "text-muted"} 
                                                style={{backgroundColor: "transparent", border: "none", paddingTop: "15px"}}
                                            >
                                                Send
                                            </button>
                                        </div>
                                        
                                        : ""
                                    }
                                    
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
            <br/>
        </div>

    )
}
export default MessageThread