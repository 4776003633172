import React, {useState, useContext, useEffect} from 'react'
import Axios from 'axios'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Loader from '../Loader/Loader'
import DispatchContext from '../../DispatchContext'
import StateContext from '../../StateContext'
import AddMedia from '../Media/AddMedia';

function CreatePost(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const token = appState.user.token

    const [isLoading, setIsLoading] = useState(false)
    const [title, setTitle] = useState()

    const [value, setValue] = useState('');

    const [media, setMedia] = useState([])
    const [mediaLoading, setMediaLoading] = useState(false)
    const [featuredImage ,setFeaturedImage] = useState()
    const [featuredImageUrl, setFeaturedImageUrl] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [show, setShow] = useState(false);

    const [showMedia, setShowMedia] = useState(false)
    const [mediaPage, setMediaPage] = useState(1);
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        async function fetchImages() {
            setMediaLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/media?per_page=10&page=" + mediaPage);
                setMedia(response.data)

            } catch (error) {
                console.log("Kunne ikke hente media")
            }
            setMediaLoading(false)

        } 
        fetchImages()

    }, [mediaPage, refresh])

    async function handleSubmit(e) {
        e.preventDefault()
        setIsLoading(true)
        try {
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/wp/v2/posts", 
            
                {
                    title, 
                    content: value,
                    status: "publish",
                    featured_media: featuredImage
                },                     
                {headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
        
            // Redirect til nytt innleggs url
            appDispatch({type: "flashMessage", value: "Du har lagt til et nytt innlegg!"})

            
        } catch (error) {
            console.log("Noe gikk galt: ", error)
        }
        setIsLoading(false)
        
    }

    function toggleModal() {
        if (modalOpen) {
            setModalOpen(false)
        } else {
            setModalOpen(true)
        }
        
    }

    function toggleMedia() {
        if (showMedia) {
            setShowMedia(false)
        } else {
            setShowMedia(true)
        }
    }

    function refreshMedia() {
        setRefresh(true);

        setTimeout(() => {
            setRefresh(false)
        }, 500)
        
    }

    if (!appState.user.token) return <p>Du må være logget inn</p>


    return (
        <div>
            
            <div className="container my-4">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="post-title" className="text-muted mb-1">
                            <small>Title</small>
                        </label>
                        <input onChange={e => setTitle(e.target.value)} autoFocus name="title" id="post-title" className="form-control form-control-lg form-control-title" type="text" placeholder="" autoComplete="off" />
                    </div>

                    <ReactQuill theme="snow" value={value} onChange={setValue}/>

                    <div className="row">
                        <div className="col-md-12">
                            <Button variant="info" className="btn-sm" onClick={() => setShow(true)}>
                                Legg til fremhevet bilde (Gjeldende bilde: {featuredImage})
                            </Button>
                        </div>
                        
                        
                        <div className="col-md-3">
                            <img className="img-fluid" src={featuredImageUrl} />
                        </div>
                    </div>                   
        
                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Fremhevet bilde
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {mediaLoading ? <Loader/> : 

                            <div className="row">
                            {media.map(image => {
                                
                                return (                                                                       
                                    <div className="col-4" key={image.id}>
                                        <div className="form-check">
                                            <input onChange={(e) => {setFeaturedImage(image.id); setFeaturedImageUrl(image.source_url)}} className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" htmlFor="defaultCheck1">
                                            <img src={image.source_url} />
                                            </label>                                                                                         
                                        </div>
                                    </div>                               
                                )
                            })}
                                <div className="col-12">
                                    <div className="my-3">
                                        <button className="btn btn-sm btn-rounded btn-info" onClick={refreshMedia}>Refresh</button>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            {mediaPage > 1 && <button onClick={() => setMediaPage(mediaPage-1)}>Nyere bilder</button>}
                                        </div>
                                        <div className="col-6">
                                            <button onClick={() => setMediaPage(mediaPage+1)}>Eldre bilder</button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </Modal.Body>
                    </Modal>

                    <hr></hr>
                    

                    <button className="btn btn-primary" disabled={isLoading}>Lagre innlegg</button>
                </form>

                <hr/>

                <h2>Last opp bilde</h2>

                <div className="col-12">
                    <span className="btn btn-success btn-sm" onClick={toggleMedia}>{showMedia ? "Lukk" : "Last opp media"}</span>
                    {showMedia && <AddMedia/>}
                </div>

            </div>
        </div>
    )
}
export default CreatePost