import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'

function TeamsByLeague(props) {

    const [teams, setTeams] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchTeams() {
            setIsLoading(true)
            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/teams/league/" + props.id,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })
                console.log(response)
                if (response.data.api.teams) {
                    setTeams(response.data.api.teams)
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        fetchTeams()

    }, [props.season])

    if (isLoading) return <p>Laster lag...</p>

    return (
        <div className="my-3">
            {teams.map(team => {
                return (
                <div className="card card-body" key={team.team_id}><Link to={`/football/teams/${team.team_id}`}><img src={team.logo} className="img-fluid" style={{maxWidth: "25px"}} /> {team.name}</Link> </div>
                )
            })}
        </div>
    )
}
export default TeamsByLeague