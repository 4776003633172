import React, {useState, useContext, useEffect} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Axios from 'axios'

import StateContext from '../../StateContext';
import AddImageEditor from './AddImageEditor';
import AddMedia from '../Media/AddMedia';

function Editor(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsloading] = useState(false)
    const [post, setPost] = useState(value);
    const [postContent, setPostContent] = useState()

    const [posted, setPosted] = useState(false)
    const [postError, setPostError] = useState(false)

    const [showMedia, setShowMedia] = useState(false)
    const [imageUploaded, setImageUploaded] = useState(false)
    const [mediaLoading, setMediaLoading] = useState(false)

    const [height, setHeight] = useState("20rem")

    const token = appState.user.token

    const [value, setValue] = useState('');

    useEffect(() => {

        if (imageUploaded) {

            async function fetchUploadedImage() {
                setMediaLoading(true)
                try {
                    const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/media?per_page=1");
                    
                    if (response.data) {
                        console.log(response.data[0], "IMAGE")
                        setValue(`${value}<img src="${response.data[0].source_url}" class="img-fluid">`)
                        console.log(value)
                    }
                    
                } catch (error) {
                    console.log("Kunne ikke hente media")
                }
                setMediaLoading(false)
            }
            fetchUploadedImage()
        }

    }, [imageUploaded])


    async function addPost(e) {
        e.preventDefault()

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }

        console.log(value)

        try {
            setIsloading(true)
            console.log(appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity")
            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity", 
                { content: value },
                {headers: headers }
            )
            if (response.data) {
                setPosted(true)
                setPostContent(response.data[0].content.rendered)
                console.log(response)  
                console.log(response.data)        
            } else {
                console.log("Feil brukernavn eller passord")
                setPostError(true)
            }
            setIsloading(false)
        } catch (error) {
            console.log(error)
            setPostError(true)
            setIsloading(false)
        }
    }



    if (isLoading) return <p>Sender....</p>

    if (posted) return (
        <div className="card card-body">
            <p>Sendt!</p>
            <div dangerouslySetInnerHTML={{__html: postContent}}></div>
        </div>
    )

    if (postError) return <p>Noe gikk galt!<br/> {post}</p>

  return (
        <>
            <form onSubmit={addPost}>
                <ReactQuill theme="snow" value={value} onChange={setValue} style={{height: height}} />
                <br />
                <br/>
                <br/>
                {value && value!== "" && value !== "<p></p>" && 
                    <div className="p-2">
                        <button className="btn btn-primary mb-3 btn-sm btn-block">Del</button>
                    </div>
                }
            </form>

            <div className="row">

                <div className="col-12">
                    <div className="row">
                        <div className="col-4">
                            <AddImageEditor value={value} setValue={setValue} />
                        </div>
                        <div className="col-4">
                            <div className="btn btn-sm btn-success" onClick={() => !showMedia ? setShowMedia(true) : setShowMedia(false)}> 
                                <i className="fas fa-upload"></i><span className="btnText"> Last opp bilde</span>
                            </div>        
                        </div>     
                        <div className="col-4">
                            <div className="btn btn-warning btn-sm">
                                <i className="far fa-grin-beam"></i> <span className="btnText"> feeling / activity</span>
                            </div>
                        </div>
                    </div>

                </div>

                {showMedia && <div className="col-12"><AddMedia action="uploadToPost" setImageUploaded={setImageUploaded} /></div>}
            </div>
        </>

        
  );
}
export default Editor