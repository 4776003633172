import React, {useEffect, useState, useContext} from 'react'
import {useParams} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../Loader/Loader'
import BlogPosts from './BlogPosts'
import PostPagination from '../Pagination/PostPagination'
import View from '../View/View'
import StateContext from '../../StateContext'
import ViewJarallax from '../View/ViewJarallax'

function BlogPostList() {

    const { page } = useParams()

    const appState = useContext(StateContext)

    let currentPage = parseInt(page)
    const [isLoading, setIsloading] = useState(false);
    const [totalPosts, setTotalPosts] = useState([])

    // props to pagination component
    const postsPerPage = 5;
    const numberOfPages = Math.ceil(totalPosts / postsPerPage);
    const pages = Array.from(Array(numberOfPages).keys())
 
    useEffect(() => {

        async function getTotalPosts() {
            setIsloading(true)

            console.log(appState.wordpressUrl + "posts")
            
            //const response = await Axios.get("http://localhost:3000/json/posts.json");
            const response = await Axios.get(`${appState.wordpressBaseUrl}wp-json/wp/v2/posts?per_page=100`);
            if (response.data) {
                setTotalPosts(response.data.length)
                //console.log(response.data)
            } else {
                console.log("Klarte ikke hente innlegg")
            }
            setIsloading(false)
        }

        getTotalPosts()
    }, [page])



    return (
        <>
            <ViewJarallax text="Innlegg" image="https://images.pexels.com/photos/94847/pexels-photo-94847.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
            {isLoading ? <Loader /> : 
            <div className="container mt-3">
                <BlogPosts page={currentPage} postsPerPage={postsPerPage} />
                <PostPagination pages={pages}  currentPage={currentPage} totalPosts={totalPosts} isLoading={isLoading} />
            </div>
            }
        </>
    )
}
export default BlogPostList