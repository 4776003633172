import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { useParams, Link } from 'react-router-dom'
import ViewJarallax from '../View/ViewJarallax'
import Loader from '../Loader/Loader'
import LoaderSmall from '../Loader/LoaderSmall'

function SingleFixture() {

    const {id} = useParams()

    const [fixture, setFixture] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [activeItem, setActiveItem] = useState("1")

    useEffect(() => {

        async function fetchSingleFixture() {

            setIsLoading(true)
            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/fixtures/id/" + id,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    },"params":{
                    "timezone":"Europe/London"
                    }
                })
                console.log(response.data.api.fixtures[0])
                setFixture(response.data.api.fixtures[0])
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        fetchSingleFixture()

    }, [])

    if (isLoading) return <p>Laster...</p>

    function formatDate(dateFixture) {
        let date = new Date(dateFixture);



        let month = date.getMonth()+1
        let day = date.getDay()

        const dateFormatted = `${date.getDay()}/${month}/${date.getFullYear()} kl ${date.getHours()}:${date.getMinutes()}0`

        return dateFormatted
    }

    function formatDateShort(dateFixture) {

        let date = new Date(dateFixture);
        let month = date.getMonth()+1
        const dateFormatted = `${date.getDate()}/${month} kl ${date.getHours()}:${date.getMinutes()}0`

        return dateFormatted
    }

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }
    

    return (
        <div className="bg-light">
        <div className="container pt-1 pb-3">
            
            
                <div className="row bg-white pt-1">
                    <div className="col-4 text-center">
                        <Link to={`/football/teams/${fixture.homeTeam.team_id}`}>
                            <img src={fixture.homeTeam.logo} />
                            <p className="font-weight-bold">{fixture.homeTeam.team_name}</p>
                        </Link>
                    </div>
                    <div className="col-4 text-center">
                        <br/>
                        <div className="text-center"> 
                            <small>
                                {fixture.elapsed && fixture.elapsed > 0 && fixture.elapsed <= 90 && fixture.statusShort !== "FT" ? 
                                <span className="text-success">{fixture.elapsed + "'"} <i className="fas fa-circle-notch fa-spin"></i></span> 
                                : 
                                formatDateShort(fixture.event_date)}
                            </small> 
                        </div>
                        <div className="text-center"><small><Link to={`/football/league/${fixture.league_id}`}>{fixture.league.name}  </Link>  </small></div>
                        <h2 className="font-weight-bold mt-4">{fixture.goalsHomeTeam} - {fixture.goalsAwayTeam}</h2>
                    </div>
                    <div className="col-4 text-center">
                        <Link to={`/football/teams/${fixture.awayTeam.team_id}`}>
                            <img src={fixture.awayTeam.logo} />
                            <p className="font-weight-bold">{fixture.awayTeam.team_name}</p>
                        </Link>
                    </div>
                </div> 

                <div className="row d-flex justify-content-between mb-1 bg-white" style={{borderTop: "1px solid #e3e3e3"}}>
                    <div style={{width: "33.33333%"}}>
                        <button 
                            style={{backgroundColor: "transparent", border: "none"}}
                            onClick={() => toggle("1")} 
                            className={activeItem === "1" ? "btn-block text-dark p-2 z-depth-1" : "btn-block grey-text p-2"}
                        >
                            Lineups
                        </button>
                    </div>
                    <div style={{width: "33.33333%"}}>
                        <button 
                            style={{backgroundColor: "transparent", border: "none"}}
                            onClick={() => toggle("2")} 
                            className={activeItem === "2" ? "btn-block text-dark p-2 z-depth-1" : "btn-block grey-text p-2"}
                        >
                            Stats
                        </button>
                    </div>
                    <div style={{width: "33.33333%"}}>
                        <button 
                            style={{backgroundColor: "transparent", border: "none"}}
                            onClick={() => toggle("3")} 
                            className={activeItem === "3" ? "btn-block text-dark p-2 z-depth-1" : "btn-block grey-text p-2"}
                        >
                            Events
                        </button>
                    </div>
                </div>  

            {activeItem === "1" &&

                <div className="row" style={{fontSize: "12px"}}>

                    <div style={{width: "50%"}}>
                                                

                        {fixture.lineups ? fixture.lineups[`${fixture.homeTeam.team_name}`].startXI.map((awayLineup, index) => {
                            return (
                                <div key={index} className="">
                                    <div className="card p-3">
                                        <Link to={`/football/players/${awayLineup.player_id}`}>{awayLineup.pos} {awayLineup.player}</Link>
                                    </div>
                                </div>
                            )
                        }) : "No home lineup yet"}
                    
                    </div>

                    <div style={{width: "50%"}} className="text-right">
                        

                        {fixture.lineups ? fixture.lineups[`${fixture.awayTeam.team_name}`].startXI.map((awayLineup, index) => {
                            return (
                                <div key={index} className="card p-3">
                                    <Link to={`/football/players/${awayLineup.player_id}`}>{awayLineup.player} {awayLineup.pos}</Link>
                                </div>
                            )
                        }) : "No away lineup yet"}
                    
                    </div>

                </div>
            }

            {activeItem === "2" &&
            <>
            {fixture.statistics ? 
                <div className="row text-center bg-white py-2">
                    <div className="col-4 col-md-2 text-center">
                        <img src={fixture.homeTeam.logo} className="img-fluid" style={{maxWidth: "50px"}} />
                    </div>
                    <div className="text-center col-4 col-md-8">
                        <br/>
                        Lagstatistikk
                    </div>
                    <div className="col-4 col-md-2 text-center">
                        <img src={fixture.awayTeam.logo} className="img-fluid" style={{maxWidth: "50px"}} />
                    </div>

                    <div className="col-12"><hr/></div>

                    <div className="col-md-2">
                        <label>Ball possession</label>
                        <p>{fixture.statistics && fixture.statistics["Ball Possession"].home} - {fixture.statistics["Ball Possession"].away}</p>
                    </div>
                    <div className="col-md-2">
                        <label>Fouls</label>
                        <p>{fixture.statistics && fixture.statistics["Fouls"].home} - {fixture.statistics["Fouls"].away}</p>
                    </div>
                    <div className="col-md-2">
                        <label>Corners</label>
                        <p>{fixture.statistics && fixture.statistics["Corner Kicks"].home} - {fixture.statistics["Corner Kicks"].away}</p>
                    </div>
                    <div className="col-md-2">
                        <label>Yellow cards</label>
                        <p>{fixture.statistics && fixture.statistics["Yellow Cards"].home} - {fixture.statistics["Yellow Cards"].away}</p>
                    </div>
                    <div className="col-md-2">
                        <label>Passes accurate</label>
                        <p>{fixture.statistics && fixture.statistics["Passes accurate"].home} - {fixture.statistics["Passes accurate"].away}</p>
                    </div>
                    <div className="col-md-2">
                        <label>Shots on goal</label>
                        <p>{fixture.statistics && fixture.statistics["Shots on Goal"].home} - {fixture.statistics["Shots on Goal"].away}</p>
                    </div>
                    {console.log(fixture.statistics && fixture.statistics)}
                    
                </div>
             : "Not stats"}
                </>
            }

            {activeItem === "3" &&
            
            <div className="mb-3">
                {fixture.events ? fixture.events.map((event, index) => {
                    return (
                        <div style={{fontSize: "12px"}} className={event.type === "Goal" ? "mb-2 " : " mb-2"} key={index}>
                            <div className={event.type === "Goal" ? "mb-2 lightGreenBg z-depth-1 row" : " bg-white mb-2 z-depth-1 row"}>

                                <div className="col-1 col-lg-1 p-4">
                                    {event.type === "Goal" && <i className="far fa-futbol"></i>}
                                    {event.type === "Card" && <img style={{maxWidth: "20px"}} className="img-fluid" src="https://progitek.no/privat/bp/wp-content/uploads/2020/07/last-ned.png" />}
                                    {event.type === "subst" && <img style={{maxWidth: "20px"}} className="img-fluid" src="https://progitek.no/privat/bp/wp-content/uploads/2020/07/1200px-Substitution.svg_.png" />}
                                    
                                </div>
                                <div className="col-3 col-lg-2 p-4">
                                    {event.teamName}
                                </div>
                                <div className="col-5 col-lg-8 p-4">
                                    
                                    {event.type === "subst" && <>   <i className="fas fa-arrow-circle-down text-danger"></i> {event.detail}   <i className="fas fa-arrow-circle-up text-success"></i>  </>}
                                    {event.player} 
                                    {event.assist && event.type === "Goal" && 
                                    <span>
                                        <img className="img-fluid ml-2" src="https://progitek.no/privat/bp/wp-content/uploads/2020/07/a-icon-28.png" style={{maxWidth: "20px"}} /> {event.assist}
                                    </span>
                                    
                                    }
                                    <span>{event.type === "Card" && ` ${event.detail} for`} {event.comments}</span>
                                </div>
                                <div className="col-2 col-lg-1 p-4 text-right">
                                    {event.elapsed}{event.elapsed_plus && `+${event.elapsed_plus}`}'
                                </div>
                  
                            </div>
                        </div>
                    )
                }) : "No events yet"}
            </div>

            }

        </div>
        </div>
    )

}
export default SingleFixture