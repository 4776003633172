import React, { useState, useContext } from 'react'

import { firestore } from '../firebase'
import StateContext from '../../../StateContext';
import LoginFirebase from '../LoginFirebase';
import AllPlayersLF from './AllPlayersLF';

function AddPlayerLF() {

    const appState = useContext(StateContext)

    const [name, setName] = useState("")
    const [image, setImage] = useState("")
    const [teamId, setTeamId] = useState("");
    const [playerId, setPlayerId] = useState("")
    const [error, setError] = useState(false)
    const [added, setAdded] = useState(false)
    const [addCount, setAddCount] = useState(0)

    async function handleSubmit(e) {
        e.preventDefault();

        if (!name || !image) {
            setError(true);
            return
        }

        try {
            const entriesRef = firestore.collection("players-fb");
            const entryData = {
                player_name: name, 
                player_image: image
            }
            const entryRef = await entriesRef.add(entryData);
            console.log("Saved: ", entryRef.id)
            setAdded(true)

            setName("")
            setTeamId("")
            setImage("")
            setAddCount(addCount+1)

        } catch (error) {
            console.log("Error")
            setError(true)
        }



    }

    if (!appState.loggedInFB) return <LoginFirebase />

    return (
        <div className="container my-4">
            {appState.loggedInFB ? <p>Logget inn</p> : <LoginFirebase />}
            <h2>Legg til spiller</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Navn</label>
                    <input className="form-control" value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Bilde</label>
                    <input className="form-control" value={image} onChange={e => setImage(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Lag ID</label>
                    <input className="form-control" value={teamId} onChange={e => setTeamId(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Spiller ID</label>
                    <input className="form-control" value={playerId} onChange={e => setPlayerId(e.target.value)} />
                </div>
                {error && <div className="alert alert-danger">Noe gikk galt</div>}
                {added && <div className="alert alert-success">Lagt til!</div>}
                <button className="btn btn-block btn-lg btn-success">Send</button>
            </form>

            <AllPlayersLF teamId={"40"} addCount={addCount} />
        </div>
        
    )
}
export default AddPlayerLF