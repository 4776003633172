import React, { useContext, useEffect, useState } from 'react'
import StateContext from '../../../StateContext'
import Axios from 'axios'
import Loader from '../../Loader/Loader'


function AddGroup(props) {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [status, setStatus] = useState("public")

    const [posted, setPosted] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    async function submitForm(e) {
        e.preventDefault();

        try {
            const response = await Axios.post(appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups', 
            {
                context: 'edit', 
                creator_id: appState.user.appUserId, 
                name: name, 
                description: description,
                status: status
            }, 
            {
                headers: headers
            }
            )
            console.log(response)
            setPosted(true)
        } catch (error) {
            console.log(error)
        }
    }

    if (isLoading) return <Loader/>

    return (
        <div className="col-md-6 mx-auto my-3">
            <div className="card">
                <div className="card-header">Opprett gruppe</div>
                <div className="card-body">
                    {posted && <div className="alert alert-success">Gruppe opprettet</div>}
                    <form onSubmit={submitForm}>
                        <input onChange={e => setName(e.target.value)} placeholder="Gruppens navn" className="form-control mb-2" />
                        <input onChange={(e) => setDescription(e.target.value)} placeholder="Gruppebeskrivelse" className="form-control mb-2" />

                        <p>Foreløpig status: {status}</p>
                        <select onChange={e => setStatus(e.target.value)} className="form-control mb-2">
                            <option value="public">Offentlig</option>
                            <option value="private">Lukket</option>
                            <option value="hidden">Skjult</option>
                        </select>

                        <button className="btn btn-success btn-rounded">Opprett</button>
                    </form>
                </div>
            </div>
        </div>
    )


}
export default AddGroup