import React, { useEffect, useContext, useState } from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

import StateContext from '../../../StateContext'
import Loader from '../../Loader/Loader';

function Notifications() {

    const appState = useContext(StateContext);

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    const [isLoading, setIsLoading] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [removedNotification, setRemovedNotification] = useState()

    useEffect(() => {

        async function getNotifications() {

            setIsLoading(true)

            try {
                localStorage.removeItem("appUserNotificationsCount")
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/notifications?_embed", {
                    data: {
                        context: "view",
                        user_id: appState.user.appUserId
                    }, 
                    headers: headers
                })
                console.log(response.data, "                    NOTIFICATIONS")
                setNotifications(response.data)
                if (response.data.length > 0) {
                    if (appState.user.appUserNotificationsCount !== response.data.length) {
                        
                        //appDispatch({type: "userNotificationsCount", value: response.data.length})
                        localStorage.setItem("appUserNotificationsCount", response.data.length)
                    } else {
                        localStorage.setItem("appUserNotificationsCount", response.data.length)
                    }

                } else {
                    localStorage.setItem("appUserNotificationsCount", 0)
                }
                
            } catch (error) {
                localStorage.setItem("appUserNotificationsCount", 0)
            }
            setIsLoading(false)

        }
        getNotifications()

    }, [removedNotification])

    async function removeNotification(e) {
        e.preventDefault();
        let formData = new FormData(e.target); 
        formData.set("notificationId", formData.get("notificationId"))
        const notificationId = formData.get("notificationId")

        try {
            const response = await Axios.put(appState.wordpressBaseUrl + "wp-json/buddypress/v1/notifications/" + notificationId + "?is_new=0", 
                {
                    context: "edit",
                    is_new: 0
                }, 
                {
                    headers: headers
                }
            );
            if (response.data) {
                setRemovedNotification(notificationId)
            }
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }



    if (isLoading) return <Loader />

    return (
        <div className="container my-5">
            {notifications.length ? (
                <>
                    {notifications.map(notification => {
                        return (
                            <div key={notification.id} className="card card-body my-2">
                                
                                <div className="row">
                                    <div className="col-3 col-md-2">
                                        <img alt="avatar-user" className="avatar rounded-circle" src={notification._embedded.user[0].avatar_urls.thumb} />
                                    </div>

                                    <div className="col-8 col-md-8">
                                        {notification.component === "activity" ? 
                                        <>
                                            <p>Aktivitet {notification.content}</p>
                                            <Link to={`/bp/activity/${notification.item_id}`}><p>{notification.content}</p></Link> 
                                        </>
                                        : notification.component === "messages" ?
                                        <>
                                        <h6 className="font-weight-bold">Ny melding</h6> 
                                        <Link to={`/bp/messages/${notification.thread_id}`}><p>{notification.content}</p></Link> 
                                        </>
                                        : notification.component === "friends" ?
                                        <>
                                            <h6 className="font-weight-bold">Venner</h6>
                                            <p>{notification.content}</p>
                                            <Link to={`/bp/members/${notification.item_id}`}><p>Vis venn</p></Link> 
                                        </> 
                                        : notification.component === "groups" ? 
                                        <>
                                            <h6 className="font-weight-bold">Grupper</h6>
                                            <p>{notification.content}</p>
                                            <Link to={`/bp/invites`}><p>Vis invitasjon</p></Link> 
                                        </>
                                        :
                                        <>
                                            <h6 className="font-weight-bold">Noe annet</h6>
                                            <Link to={`/bp/messages/${notification.thread_id}`}><p>{notification.content}</p></Link> 
                                        </>
                                            
                                        }  
                                                                 
                                    </div>

                                    <div className="col-1 col-md-2">
                                        {notification.is_new == 1 && !removedNotification ? (
                                        <form onSubmit={removeNotification}>                             
                                            <input type="hidden" name="notificationId" value={notification.id} />      
                                            <button><i className="fas fa-circle text-primary"></i></button>             
                                        </form>) : ""} 
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            ) : "Du har ingen varsler!"}
                        
        </div>
    )
}
export default Notifications