import React, { useEffect, useState } from 'react'
import Axios from 'axios'

function LeagueStandings(props) {

    const [standings, setStandings] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function getStandings() {

            setIsLoading(true)
            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/leagueTable/" + props.id + "/" + props.season,
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })
                if (response.data) {
                    console.log(response.data.api.standings[0], "STANDINGS")
                    setStandings(response.data.api.standings)
                } else {
                    console.log("Could not fetch")
                }
                
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        getStandings()

    }, [props.season])

    if (isLoading) return <p>Loading standings....</p>

    return (
        <div className="bg-white">
            
            

            {standings[0] ? 
            <div className="table-responsive">
                <table className="table table-bordered table-striped table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Team</th>
                            <th>P</th>
                            <th>W</th>
                            <th>D</th>
                            <th>L</th>
                            <th>GF</th>
                            <th>GA</th>
                            <th>GD</th>
                            <th>Points</th>
                            <th>Form</th>
                        </tr>
                    </thead>
                    <tbody>
                        {standings[0].map((team, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{team.teamName}</td>
                                    <td>{team.all.matchsPlayed}</td>
                                    <td>{team.all.win}</td>
                                    <td>{team.all.draw}</td>
                                    <td>{team.all.lose}</td>
                                    <td>{team.all.goalsFor}</td>
                                    <td>{team.all.goalsAgainst}</td>
                                    <td>{team.goalsDiff}</td>
                                    <td>{team.points}</td>
                                    <td>{team.forme}</td>
                                    {console.log(team)}
                                    
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            : <p className="text-danger">No standings found</p>}
            
        </div>
    )
}
export default LeagueStandings