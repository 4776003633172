import React, { useContext, useState } from "react";
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, 
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBContainer, MDBIcon
} from "mdbreact";
import { Link } from 'react-router-dom';

import HeaderLoggedIn from "./HeaderLoggedIn";
import HeaderLoggedOut from './HeaderLoggedOut'
import StateContext from '../../StateContext'

function NavbarPage(props) {

    const [isOpen, setIsOpen] = useState(false)
    const appState = useContext(StateContext)
    const headerContent = appState.loggedIn ? <HeaderLoggedIn isOpen={isOpen} setIsOpen={setIsOpen} /> : <HeaderLoggedOut />

    const toggleCollapse = () => {
        if (isOpen) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }


  return (
    
      <MDBNavbar color="white" light expand="md" >
          <MDBContainer>
            <MDBNavbarBrand>     
                <Link to="/"><img src="https://images.pexels.com/photos/9056/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500" className="img-fluid rounded-circle" style={{maxWidth: "35px"}} alt=""/></Link>
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={toggleCollapse} />
            <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
            <MDBNavbarNav left>
                <MDBNavItem active>
                    <MDBNavLink onClick={() => {isOpen && setIsOpen(false)}} to="/">Home</MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink onClick={() => {isOpen && setIsOpen(false)}} to="/innlegg/1">Innlegg</MDBNavLink>
                </MDBNavItem>
                {appState.wordpressSite === "bp-social" &&
                    <>
                    <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                            <MDBIcon icon="movie" /> Media
                            </MDBDropdownToggle>
                                <MDBDropdownMenu className="dropdown-default">
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/movies/1">Filmer</Link></MDBDropdownItem>
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/shows/1">Serier</Link></MDBDropdownItem>
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/search-movies">Søk filmer</Link></MDBDropdownItem>
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/search-shows">Søk serier</Link></MDBDropdownItem>
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/bp/favoritedmedia">Din favorittliste</Link></MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBNavItem>

                    <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                            <MDBIcon icon="movie" /> Football
                            </MDBDropdownToggle>
                                <MDBDropdownMenu className="dropdown-default">
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/football/all-leagues">Ligaer</Link></MDBDropdownItem>
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/football/countries">Countries</Link></MDBDropdownItem>
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/football/search-teams">Search</Link></MDBDropdownItem>
                                    <MDBDropdownItem><Link onClick={() => {isOpen && setIsOpen(false)}} to="/football/live">Live now</Link></MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBNavItem>

                    <MDBNavItem>
                        <MDBNavLink onClick={() => {isOpen && setIsOpen(false)}} to="/fm/vidunderbarn-table">FM</MDBNavLink>
                    </MDBNavItem>
                    </>
                }
                {appState.wordpressSite === "min-kokebok" && (
                    <>
                    <MDBNavItem>
                        <MDBNavLink onClick={() => {isOpen && setIsOpen(false)}} to="/category/:id">Kategorier</MDBNavLink>
                    </MDBNavItem>
                    </>
                )}

            </MDBNavbarNav>

            {!props.staticEmpty ? headerContent : ""}

            </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    );
  }


export default NavbarPage;