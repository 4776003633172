import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import TeamUpcomingFixtures from './TeamUpcomingFixtures'
import FixtureListTemplate from './Templates/FixtureListTemplate'
import LiveFixtureTemplateResponsive from './Fixture/LiveFixtureTemplateResponsive'

function TeamFixtures(props) {

    const [teamFixtures, setTeamFixtures] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchTeamFixtures() {

            setIsLoading(true)

            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/fixtures/team/" + props.id + "/last/10",
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    },"params":{
                    "timezone":"Europe/London"
                    }
                })
                console.log(response.data.api.fixtures[0])
                setTeamFixtures(response.data.api.fixtures)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchTeamFixtures()

    }, [props.id])

    if (isLoading) return <p>LAster...</p>

    return (
        <div>
            {teamFixtures.map((fixture, index) => {
                return (
                    <>
                    <div className="showLarge">
                        <FixtureListTemplate key={index} fixture={fixture} index={index} />
                    </div>
                    
                    <div className="showResponsive">
                        <LiveFixtureTemplateResponsive key={index} index={index} fixture={fixture} />
                    </div>
                    
                    </>
                )
            })}

            <div>
                <TeamUpcomingFixtures id={props.id} />
            </div>
        </div>
    )

}
export default TeamFixtures