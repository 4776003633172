import React from 'react'
import {Link} from 'react-router-dom'

function MoviePagination(props) {

    const currentPage = parseInt(props.currentPage)

    return (

        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
                {currentPage > 1 &&<li className="page-item">
                    <Link className="page-link" to={`/${props.link}/${currentPage-1}`} tabIndex="-1">Previous</Link>
                </li>}
                <li className="page-item"><Link className="page-link" to={`/${props.link}/1`}>1</Link></li>
                <li className="page-item active"><span className="page-link">{currentPage}</span></li>
                <li className="page-item"><Link className="page-link" to={`/${props.link}/500`}>500</Link></li>
                {currentPage < 500 && <li className="page-item">
                    <Link className="page-link" to={`/${props.link}/${currentPage+1}`}>Next</Link>
                </li>}
            </ul>
        </nav>
    )

}

export default MoviePagination