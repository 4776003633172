import React, { useEffect, useState, useContext } from 'react'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { useParams } from 'react-router-dom'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import ActivityMember from '../Activity/ActivityMember'
import StateContext from '../../../StateContext'
import DispatchContext from '../../../DispatchContext'
import MemberFriends from './MemberFriends'
import MemberFriendRequests from './MemberFriendRequests'
import ViewJarallax from '../../View/ViewJarallax'
import EditCoverImage from '../EditCoverImage'
import AddAvatarImage from '../AddAvatar'
import CurrentMemberFriends from './CurrentMemberFriends'
import AddMessageThreadProfile from '../Message/AddMessageThreadProfile';
import MemberPosts from './MemberPosts';
import MemberFavoritePosts from './MemberFavoritePosts';
import MemberGroups from './MemberGroups';
import MemberGroupInvites from './MemberGroupInvites';
import MemberFavoritedMedia from './MemberFavoritedMedia';

function Member() {

    const { id } = useParams()

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [showAddAvatarImage, setShowAddAvatarImage] = useState(false)
    const [showEditCoverImage, setShowEditCoverImage] = useState(false)

    const [activeItem, setActiveItem] = useState("1")

    const [isLoading, setIsloading] = useState(true);
    const [member, setMember] = useState([]);
    const [coverImage, setCoverImage] = useState()

    const [memberActivity, setMemberActivity] = useState([]);

    const token = localStorage.getItem("token")
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    useEffect(() => {

        async function getMember() {

            try {
                //console.log(page)
                setIsloading(true)
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/members/" + id, {headers: headers});
                if (response.data) {
                    setMember(response.data)
                } else {
                    console.log("Klarte ikke hente medlemmer")
                }
                setIsloading(false)
            } catch (error) {

            }

        }
        getMember()

        async function getMemberActivity() {

            try {
                //console.log(page)
                setIsloading(true)
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/activity?user_id=" + id, {headers: headers});
                if (response.data) {
                    setMemberActivity(response.data)
                } else {
                    console.log("Klarte ikke hente medlemmer")
                }
                setIsloading(false)
            } catch (error) {
    
            }
    
        }

        getMemberActivity()

    }, [id])

    useEffect(() => {

        async function getAvatarUser() {
            setIsloading(true)
            try {
                
                const response = await Axios.get(`${appState.wordpressBaseUrl}wp-json/buddypress/v1/members/${id}/cover`, 
                {
                    context: 'view'
                }
                )
                setCoverImage(response.data[0].image)
                console.log(response.data[0].image)
            } catch (error) {
                console.log("Cover error: ", error)
            }
            setIsloading(false)
        }
        getAvatarUser()

    }, [id])


    async function addFriend(e) {
        e.preventDefault()
        try {

            const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/friends", 
                {                   
                    context: 'edit',
                    'initiator_id': appState.user.appUserId,
                    'friend_id': id                  
                }, 
                {
                    headers: headers
                }
            )

            if (response.data) {
                appDispatch({type: "flashMessage", value: "Kontakt opprettet"})
            }
        } catch (error) {
            console.log(error)
            appDispatch({type: "flashMessage", value: "Kunne ikke opprette kontakt"})
        }
    }

    async function removeFriend(e) {
        e.preventDefault()
        const friendToRemoveId = id;
        try {
            const response = await Axios.delete(appState.wordpressBaseUrl + "wp-json/buddypress/v1/friends/" + friendToRemoveId,
                {                   
                    context: 'edit',
                    force: true                
                }, 
                {
                    headers: headers
                }
            )
            if (response.data) {
                appDispatch({type: "flashMessage", value: "Kontakt fjernet"})
            }
        } catch (error) {
            console.log(error)
            appDispatch({type: "flashMessage", value: "Kunne ikke fjerne kontakt"})
        }
    }


    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    if (isLoading) return (

        <div>
            Laster MEMBER....
            <Loader />
        </div>

    )

    return (
        <>
            <ViewJarallax image={coverImage} text={member.name} />
                <div className="col-md-3 mx-auto" style={{marginTop: "-40px"}}>
                    <div className="d-flex justify-content-center">
                        <img 
                            alt="avatar" 
                            className="img-fluid z-depth-1 zoom rounded-circle" 
                            src={member.avatar_urls ? member.avatar_urls.full : "https://upload.wikimedia.org/wikipedia/commons/f/f0/Sl%C3%A5tthaug_idrettsanlegg.jpg"} 
                        />
                    </div>            
                </div> 
            <div className="container">
                <hr />

                <div className="member-btns-sm mb-2">
                    {appState.user.appUserId == id ? 
                    <div className="row">
                        <div className="col-md-8 col-7">
                            <button 
                                onClick={() => toggle("1")} 
                                className="btn btn-block btn-primary profileBtn"
                            >
                                Legg til i historie
                            </button>
                        </div>
                        <div className="col-md-4 col-5">
                            <div className="row">
                                <div className="col-6">
                                    <MDBDropdown>
                                        <MDBDropdownToggle caret color="light" className="btn-block  profileBtn">
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu right basic>
                                            <MDBDropdownItem
                                                onClick={() => {toggle("5")}}
                                            >
                                                Legg til avatar
                                            </MDBDropdownItem>
                                            <MDBDropdownItem
                                                onClick={() => {toggle("4")}}
                                            >
                                                Legg til cover
                                            </MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>
                                <div className="col-6">
                                    <MDBDropdown>
                                        <MDBDropdownToggle caret color="light" className="btn-block  profileBtn">
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu right basic>
                                            <MDBDropdownItem
                                                onClick={() => {toggle("2")}}
                                            >
                                                Vis venner
                                            </MDBDropdownItem>
                                            <MDBDropdownItem
                                                onClick={() => {toggle("3")}}
                                            >
                                                Vis venneforespørsler
                                            </MDBDropdownItem>
                                            <MDBDropdownItem
                                                onClick={() => {toggle("10")}}
                                            >
                                                Gruppeinvitasjoner
                                            </MDBDropdownItem>
                                            <MDBDropdownItem
                                                onClick={() => {toggle("11")}}
                                            >
                                                Favorittfilmer / serier
                                            </MDBDropdownItem>
                                            <MDBDropdownItem
                                                onClick={() => {toggle("7")}}
                                            >
                                                Vis innlegg
                                            </MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    <div className="row">
                        <div className="col-md-7 col-6">
                            <button 
                                onClick={() => {toggle("6")}} 
                                className="btn btn-block btn-primary profileBtn"
                            >
                                Melding
                            </button>
                        </div>
                        <div className="col-md-5 col-6">
                            <div className="row">
                                <div className="col-6">
                                    {!member.friendship_status ? 
                                        <>
                                        <form onSubmit={addFriend}>
                                            <button 
                                            className="btn btn-block btn-primary btn-rounded profileBtn"
                                            data-toggle="tooltip" 
                                            data-placement="bottom" 
                                            title="Legg til venn">
                                                <i className="fas fa-user-times"></i>
                                            </button>
                                        </form>
                                        </> : 
                                        <>
                                        <form onSubmit={removeFriend}>
                                            <button 
                                                className="btn btn-block btn-danger btn-rounded profileBtn" 
                                                data-toggle="tooltip" 
                                                data-placement="bottom" 
                                                title="Fjern venn">
                                                <i className="fas fa-user-check"></i>
                                            </button>
                                        </form>
                                        </>
                                    }
                                </div>
                                <div className="col-6">
                                    <MDBDropdown>
                                        <MDBDropdownToggle caret color="light" className="btn-block profileBtn">
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu right basic>
                                            <MDBDropdownItem 
                                                onClick={() => {toggle("1")}}
                                            >
                                                Vis aktivitet
                                            </MDBDropdownItem>
                                            <MDBDropdownItem 
                                                onClick={() => {toggle("7")}}
                                            >
                                                Vis innlegg
                                            </MDBDropdownItem>
                                            <MDBDropdownItem
                                                onClick={() => {toggle("2")}}
                                            >
                                                Vis venner
                                            </MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    }
                </div>

                <div className="row pb-2 member-btns-lg">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-lg-3">
                                <button 
                                    onClick={() => {toggle("7")}} 
                                    className={activeItem === "7" ? "btn btn-white btn-block text-primary btn-sm" : "btn btn-white btn-block btn-sm"}>
                                    <i className="fas fa-user-edit"></i> 
                                    <span className="btnText-lg"> Innlegg</span>
                                </button>
                            </div>
                            <div className="col-lg-3">
                                <button 
                                    onClick={() => {toggle("1")}} 
                                    className={activeItem === "1" ? "btn btn-white btn-block text-primary btn-sm" : "btn btn-white btn-block btn-sm"}>
                                    <i className="fas fa-rss-square"></i>
                                    <span className="btnText-lg"> Aktivitet</span>
                                </button>
                            </div>
                            <div className="col-lg-3">
                                <button 
                                onClick={() => {toggle("2")} }
                                className={activeItem === "2" ? "btn btn-white btn-block text-primary btn-sm" : "btn btn-white btn-block btn-sm"}>
                                    <i className="fa fa-users" aria-hidden="true"></i> 
                                    <span className="btnText-lg"> Venner</span>
                                </button>
                            </div>
                            <div className="col-lg-3">
                                <MDBDropdown>
                                    <MDBDropdownToggle caret color="white" className={activeItem === "8" || activeItem === "9" || activeItem === "3" ? "btn-block btn-sm text-primary" : "btn-block btn-sm"}>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu right basic>
                                        <MDBDropdownItem
                                            onClick={() => {toggle("8")}}
                                        >
                                            Favorittinnlegg
                                        </MDBDropdownItem>
                                        <MDBDropdownItem
                                            onClick={() => {toggle("9")}}
                                        >
                                            Vis grupper
                                        </MDBDropdownItem>
                                        <MDBDropdownItem
                                            onClick={() => {toggle("3")}}
                                        >
                                            Venneforespørsler
                                        </MDBDropdownItem>
                                        <MDBDropdownItem
                                            onClick={() => {toggle("10")}}
                                        >
                                            Gruppeinvitasjoner
                                        </MDBDropdownItem>
                                        <MDBDropdownItem
                                            onClick={() => {toggle("11")}}
                                        >
                                            Favorittfilmer / serier
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </div>
                        </div>
                    </div>

                    {id !== appState.user.appUserId &&
                        <div className="col-md-5 ml-auto">
                            <div className="row">
                                <div className="col-lg-4">
                                    {member.id !== appState.user.appUserId ? <>
                                    
                                        {!member.friendship_status ? 
                                            <>
                                            <MDBDropdown>
                                                <MDBDropdownToggle caret color="primary" className="btn btn-block btn-primary btn-rounded btn-sm">
                                                    <i className="fas fa-user-times"></i> +
                                                </MDBDropdownToggle>
                                                <MDBDropdownMenu basic>
                                                    <form onSubmit={addFriend}>
                                                        <button 
                                                        className="btn btn-block btn-primary btn-sm"
                                                        data-toggle="tooltip" 
                                                        data-placement="bottom" 
                                                        title="Legg til venn">
                                                            <i className="fas fa-user-check"></i> Legg til venn
                                                        </button>
                                                    </form>
                                                </MDBDropdownMenu>
                                            </MDBDropdown>
                                            
                                            </> : 
                                            <>
                                                <MDBDropdown>
                                                <MDBDropdownToggle caret color="danger" className="btn btn-block btn-danger btn-rounded btn-sm">
                                                    <i className="fas fa-user-check"></i>
                                                </MDBDropdownToggle>
                                                <MDBDropdownMenu basic>
                                                    <form onSubmit={removeFriend}>
                                                        
                                                            <button 
                                                                className="btn btn-block btn-sm" 
                                                                data-toggle="tooltip" 
                                                                data-placement="bottom" 
                                                                title="Fjern venn">
                                                                <i className="fas fa-user-times"></i> Fjern venn
                                                            </button>                                                                                                    
                                                    </form>
                                                </MDBDropdownMenu>
                                                </MDBDropdown>
                                            
                                            </>
                                        }
                                        </> :
                                        <p>Din profil</p>
                                    }
                                </div>
                                <div className="col-lg-4">
                                    <button 
                                        className="btn btn-block btn-light btn-rounded btn-sm"
                                        onClick={() => {toggle("6")}} 
                                    >
                                        <i className="fas fa-comments"></i> Chat
                                    </button>
                                </div>
                                <div className="col-lg-4">
                                    <button className="btn btn-block btn-light btn-rounded btn-sm"><i className="fas fa-fas fa-user-edit"></i> Post</button>
                                </div>
                            </div>
                        </div>
                    }

                    {id == appState.user.appUserId &&
                    <div className="col-md-5 ml-auto">
                        <div className="row">
                            <div className="col-6">
                                <button 
                                    className="btn btn-block btn-light btn-rounded btn-sm"
                                    onClick={() => {toggle("4")}} 
                                    className={showEditCoverImage ? "btn btn-white btn-block text-primary btn-sm" : "btn btn-white btn-block btn-sm"}
                                >
                                    <i className="far fa-image" aria-hidden="true"></i> Legg til cover
                                </button>
                            </div>
                            <div className="col-6">
                                <button 
                                    className="btn btn-block btn-light btn-rounded btn-sm"
                                    onClick={() => {toggle("5")}} 
                                    className={showAddAvatarImage ? "btn btn-white btn-block text-primary btn-sm" : "btn btn-white btn-block btn-sm"}
                                >
                                    <i className="fas fa-user-plus"></i><i className="far fa-image" aria-hidden="true"></i> Legg til avatar
                                </button>
                            </div>
                        </div>
                    </div> 
                    }
                </div>
              
            </div>
            <div className="bg-light">
                <div className="col-lg-6 col-xl-5 mx-auto">

                    {token ? 
                    <>      
                        {activeItem === "8" && <MemberFavoritePosts userId={id} />}
                        {activeItem === "9" && <MemberGroups userId={id} />}
                        {activeItem === "7" && <MemberPosts userId={id} />}    
                        {activeItem === "1" && <ActivityMember memberActivity={memberActivity} />}
                                          
                        {activeItem === "2" && <>{id === appState.user.appUserId ? <CurrentMemberFriends id={id} /> : <MemberFriends id={id}/>}  </> }
                        
                        {activeItem === "3" && <MemberFriendRequests id={id}/>}
                        {activeItem === "10" && <MemberGroupInvites userId={id} />}
                        {activeItem === "11" && <MemberFavoritedMedia userId={id} />}

                        {activeItem === "4" && <EditCoverImage/>}
                        {activeItem === "5" && <AddAvatarImage/>}
                        {activeItem === "6" && <AddMessageThreadProfile userId={id} username={member.name} />}
                    </> : <></>}
                </div>
            </div>
        </>
    )
}
export default Member