import React, { useContext, useEffect, useState } from 'react'
import StateContext from '../../../StateContext'
import Axios from 'axios';
import { Link } from 'react-router-dom';

function SingleGroupMembers(props) {

    const groupId = props.groupId;

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [groupMembers, setGroupMembers] = useState([])

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        async function fetchMembersGroup() {
            setIsLoading(true)
            try {

                const url = appState.wordpressBaseUrl + 'wp-json/buddypress/v1/groups/' + groupId + '/members'
                const testUrl = "http://localhost/dashboard/OLEPETTER/WORDPRESS/BUDDYPRESS/wp-json/buddypress/v1/groups/1/members"

                const response = await Axios.get(url, {headers: headers});
                setGroupMembers(response.data)

            } catch (error) {
                //console.log(error)
            }
            setIsLoading(false)

        }
        fetchMembersGroup()

    }, [])

    if (isLoading) return <p>Laster medlemmer...</p>

    return (
        <div>
            <br />
            {groupMembers.length ? <>
            {groupMembers.map(member => {
                return (
                    <div key={member.id} className="card my-2 mb-3">

                        <div className="row">
                            <div className="col-2 col-lg-1">
                                <Link to={`/bp/members/${member.id}`}><img alt="member avatar" src={member.avatar_urls.full} className="img-fluid" /></Link>                     
                            </div>
                            <div className="col-10 col-lg-11">{member.name}</div>
                        </div>
                        
                    </div>
                )
            })}
            </>
            : <div>
                <div className="col-md-6 mx-auto my-5"><p>Ingen medlemmer enda</p></div>
            </div>}
            <br/>
        </div>
    )
}
export default SingleGroupMembers