import React, { useEffect, useContext, useState } from 'react'

import StateContext from '../../../StateContext'
import Axios from 'axios'

function ExternalNews() {

    const appState = useContext(StateContext)
    const [posts, setPosts] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchPosts() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/external_news_fbapi")
                if (response.data) {
                    setPosts(response.data);
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchPosts();

    }, [])

    function formatTitle(title) {

        const limit = 27;
        const newTitle = []

        if (title.length > limit) {
            title.split(" ").reduce((acc, cur) => {
                if (acc + cur.length <= limit) {
                    newTitle.push(cur)
                }
                return acc + cur.length;
            }, 0)
            
            return `${newTitle.join(" ")} ...`

        }

        return title

    }

    if (isLoading) return <p>Laster...</p>

    return (
        <div className="row mb-3">
            {posts?.map(post => {
                return (
                    <div key={post.id} className="col-lg-4">
                        <div className="card">
                            <a href={post.acf.lenke_nyhet}>
                                <img src={post.acf.fremhevet_bilde_url} className="img-fluid" />
                            </a>
                            <div className="card-body">
                                <h6 className="font-weight-bold">
                                    <a href={post.acf.lenke_nyhet}>
                                        {formatTitle(post.title.rendered)}
                                    </a>
                                </h6>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )

}
export default ExternalNews