import React, { Component, useContext } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Link } from 'react-router-dom';
import StateContext from '../../../StateContext';
import DeleteActivity from '../Activity/DeleteActivity';

function ModalPage(props) {

    const appState = useContext(StateContext);



    function toggleDetails() {
        if (!props.showDetails) {
            props.setShowDetails(true)
        } else {
            props.setShowDetails(false)
        }
    }

    return (
        <MDBContainer>
            <MDBModal centered isOpen={props.showDetails} toggle={toggleDetails}>
                
                <ul className="list-group text-center">

                    {console.log(props.authorPost, " === " + +appState.user.appUserId, " CHECK IF AUTHOR IS CURRENT LOGGED IN USER, ModalDetails:26")}

                    {props.authorPost === +appState.user.appUserId ? 
                        <>
                            <DeleteActivity type="movie" activityId={props.id} />
                        </>
                    : 
                        <>
                            <li className="list-group-item text-danger font-weight-bold">Report as unappropriate</li>
                            <li className="list-group-item text-danger font-weight-bold">Stop following</li>
                        </>
                    }


                    <li className="list-group-item"><Link to={`/bp/activity/${props.id}`}>Gå til innlegg</Link></li>               
                    <li className="list-group-item" onClick={() => props.setShowDetails(false)}>Cancel</li>
                </ul>

            </MDBModal>
        </MDBContainer>
        );

}

export default ModalPage;