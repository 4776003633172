import React from 'react'

import { MDBMask, MDBView } from "mdbreact";

function View(props) {
    return (
        <MDBView className="view-small">
            <img
            src={props.image}
            className="img-fluid"
            alt=""
            />
            <MDBMask overlay="black-strong" className="flex-center">
                <h2 className="white-text">{props.text}</h2>
            </MDBMask>
        </MDBView>
    )
}
export default View