import React, { useContext, useState, useEffect } from 'react'
import Axios from 'axios';
import StateContext from '../../../StateContext';
import { Link } from 'react-router-dom';

function SaveMovie(props) {

    const appState = useContext(StateContext)

    const [isMovieAdded, setIsMovieAdded] = useState(false);
    const [movieAddedId, setMovieAddedId] = useState()

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + appState.user.token
    }

    useEffect(() => {

        if (appState.user.appUserId !== null && appState.user.appUserId !== undefined) {
            async function isMediaAdded() {
                try {
                    const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/wp/v2/favorite_media?author=' + props.userId + "&search=" + props.movieTitle, {headers: headers})
                    if (response.data.length) {
                        console.log(response.data, "  IS MOVIE SAVED??")
                        if (response.data[0].acf.media_id === props.movieId) {
                            setIsMovieAdded(true);
                            setMovieAddedId(response.data[0].id)
                        } else {
                            setIsMovieAdded(false);
                        }
                        
                    }  else {
                        setIsMovieAdded(false);
                    }
                } catch (error) {
                    setIsMovieAdded(true);
                }
            }
            isMediaAdded()
        }
        

    }, [props.userId])

    async function saveMedia(e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append("status", "publish")
        formData.append("title", `User favorite movie (${props.movieTitle}) by ${appState.user.user_display_name}`);
        formData.append("content", "User favorited movie " + appState.user.user_display_name + " " + props.movieTitle)
        formData.append("fields[media_type]", props.mediaType);
        formData.append("fields[media_id]", props.movieId);
        formData.append("fields[media_image]", props.movieImageUrl)

        try {
            const response = await Axios.post(appState.wordpressBaseUrl + 'wp-json/wp/v2/favorite_media', 
            formData,
            {headers: headers})

            if (response.data) {
                setIsMovieAdded(true);
            }
        } catch (error) {
            console.log(error)
        }

    }

    async function removeMovieFromFavorites(e) {
        e.preventDefault();
        try {
            const response = await Axios.delete(appState.wordpressBaseUrl + 'wp-json/wp/v2/favorite_media/' + movieAddedId, {headers: headers})
   
            if (response.data) {
                setIsMovieAdded(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    return (
        <div>
            {appState.user.token && appState.user.appUserId !== null &&
            <>
            {isMovieAdded ? 
                <>

                    
                    <form onSubmit={removeMovieFromFavorites}>
                        <button className="btn btn-danger btn-lg btn-block">
                            <img src="https://progitek.no/wp-content/uploads/2020/06/trash.png" className="img-fluid mr-2" style={{maxWidth: "20px"}} />
                        </button>
                    </form>
                    <br/>
                    <p>
                        <span className="alert alert-success">
                            {props.mediaType === "film" ? "Film" : "Serie"} lagt til! <Link to="/bp/favoritedmedia">Vis din liste</Link>
                        </span>
                    </p>
                </>  
                : 
                <form onSubmit={saveMedia} className="">
                    <button className="btn btn-success btn-lg btn-block">
                        <input type="hidden" name="fields[media_type]" value={props.mediaType} />
                        <input type="hidden" name="fields[media_id]" value={props.movieId} />
                        <input type="hidden" name="fields[media_image]" value={props.movieImageUrl} />

                        <img src="https://progitek.no/wp-content/uploads/2020/06/plusicon.png" className="img-fluid mr-2" style={{maxWidth: "20px"}} />
                        Legg til i favoritt!!
                    </button>
                </form> 
         
            }
            </>
            }
        </div>
    )

}
export default SaveMovie