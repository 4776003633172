import React, { useContext } from 'react'
import { MDBModal } from 'mdbreact'
import StateContext from '../../../../StateContext'
import DispatchContext from '../../../../DispatchContext'

const SingleMovieSlide = (props) => {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const formatDate = (date) => {
        const release_date = new Date(date)
        return release_date.getFullYear();
    }

    const handleExit = () => {
        props.setSingleMovie(null);
        appDispatch({type: "hideSingleMovie"});
    }

    const modalHandler = () => {
        if (appState.showSingleMovie) {
            appDispatch({type: "hideSingleMovie"});
        } else {
            appDispatch({type: "showSingleMovie"})
        }
    }

    return (
        <MDBModal isOpen={appState.showSingleMovie} toggle={modalHandler} position="right" size="fluid">
            <div className={props.mode === "light" ? "container text-dark" : "text-white container"} >
            <div className="row">

                <div className="col-8">
                    <h2>{appState.singleMovie.title} ({formatDate(appState.singleMovie.release_date)})</h2>
                </div>
                <div className="col-4">
                    <div onClick={handleExit} className="text-right pointer">
                        <i className="fas fa-times-circle fa-2x hover-btn-exit"></i>
                    </div>
                </div>

                <div className="col-lg-8">
                    <div className="row">

                        
                        <div className="col-md-4 mb-3">
                            <hr/>
                            <h4 className="text-muted">Lengde</h4>
                            <h4>1 time 24 min</h4>
                        </div>
                        <div className="col-md-4 mb-3">
                            <hr/>
                            <h4 className="text-muted">Land</h4>
                            <h4>USA</h4>
                        </div>
                        <div className="col-md-4 mb-3">
                            <hr/>
                            <h4 className="text-muted">Språk</h4>
                            <h4>Engelsk</h4>
                        </div>

                        <img className="img-fluid" alt={appState.singleMovie.title} src={`https://image.tmdb.org/t/p/w1280/${appState.singleMovie.backdrop_path}`} />

                    </div>

                    <div className="row mt-3">
                        <div className="col-four">
                            <button className="btn btn-lg btn-block hover-btn"><i className="fas fa-play fa-2x"></i> Se nå</button>
                        </div>
                        <div className="col-four px-2">
                            <button className="btn btn-lg btn-block hover-btn"><i className="fa fa-eye fa-2x"></i> Trailer</button>
                        </div>
                        <div className="col-four">
                            <button className="btn btn-lg btn-block hover-btn"><i className="fas fa-bars fa-2x"></i> <i className="fas fa-plus fa-2x"></i> Huskeliste</button>
                        </div>
                    </div>

                </div>
                
                <div className="col-lg-4">
                    <p className="pt-3 text-lg">
                        {appState.singleMovie.overview}
                    </p>
                </div>

                </div>
            </div>
        </MDBModal>

    )
}
export default SingleMovieSlide