import React, {useEffect, useState, useContext} from 'react'
import Axios from 'axios'
import Loader from '../../Loader/Loader';
import StateContext from '../../../StateContext';
import ViewJarallax from '../../View/ViewJarallax';
import { Link } from 'react-router-dom';
import AddGroup from './AddGroup';

function Groups() {

    const appState = useContext(StateContext)

    const token = appState.user.token
    const [isLoading, setIsloading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [message, setMessage] = useState("");

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    useEffect(() => {

        async function getGroups() {

            
            try {
                //console.log(page)
                setIsloading(true)
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/buddypress/v1/groups?show_hidden=true", 
                { 
                    headers: headers,
                    data: {
                        context: 'view',
                        show_hidden: true
                    }
                }
                );

                if (response.data) {
                    setGroups(response.data)
                    console.log(response.data)
                } else {
                    setGroups([])
                    console.log("Klarte ikke hente aktivitet")
                }
                setIsloading(false)
            } catch (error) {
                setGroups([])
                console.log(error)
            }
            setIsloading(false)

        }
        token ? getGroups() : setMessage("Du må være logget inn for å se aktivitet");
    }, [])

    if (isLoading) return <Loader/>
    return (
        <>
        <ViewJarallax text={"Grupper"} />
            <div className="container my-4">

                {appState.user.token && 
                <div className="my-5">
                    <Link className="btn btn-primary" to="/bp/group/add">Legg til gruppe</Link>
                </div>
                }
                
                {groups.map(group => {
                    return (
                    
                    <div id={group.id} key={group.id} className="card">
                        <div className="row">
                            <div className="col-2 col-md-1">
                                <img className="img-fluid" alt="group-avatar" src={group.avatar_urls.full} />
                            </div>
                            <div className="col-10 col-md-11">
                                <h2><Link to={`/bp/groups/${group.id}`}>{group.name}</Link></h2>
                                <p>{group.status === "public" ? "Offentlig" : "Lukket"} gruppe / 1 medlem</p>
                                <p>Dato opprettet: {group.date_created}</p>
                            </div>
                        </div>
                        
                    </div>)
                })}
            </div>
        </>
    )
}
export default Groups