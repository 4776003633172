import React from "react";
import FooterSocialLinks from "./FooterSocialLinks";
import FooterBPSocial from "./FooterBPSocial";

const FooterPage = () => {
  return (
    <footer className="page-footer font-small pt-0 bg-dark" style={{marginTop: "-7.6px"}}>

        <div className="container">
            <FooterBPSocial/>
            <div className="col-md-4 col-lg-2 text-center mx-auto my-3">
              Powered by <br /><br />
              <a href="https://www.themoviedb.org/">
                <img 
                src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_square_2-d537fb228cf3ded904ef09b136fe3fec72548ebc1fea3fbbd1ad9e36364db38b.svg" 
                className="img-fluid"
                style={{maxWidth: "800px"}} 
                />   
              </a> 
            </div>
            <FooterSocialLinks/>



        </div>
 
        <div className="footer-copyright text-center py-3">
          <div className="container-fluid">© Copyright:<a href="https://www.progitek.no" alt=""> Ole Petter Kyrkjebø Hjønnevåg </a></div>
        </div>
    </footer>
  );
}

export default FooterPage;