import React, { useState, useContext, useEffect, useRef } from 'react'

import './Movies.css'

import Movies from './Movie/Movies'
import MovieGenres from './Genres/MovieGenres'

import SingleMovieSlide from './Movie/SingleMovieSlide';
import StateContext from '../../../StateContext';
import DispatchContext from '../../../DispatchContext';
import {useKeyPress} from '../../../hooks/useKeyPress'

function HomeMovies() {

    // Context providers for handling global state
    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext)


    // State
    const [height, setHeight] = useState(0)
    const [genre, setGenre] = useState("")
    const [singleMovie, setSingleMovie] = useState(null);
    const [mode, setMode] = useState("dark");

    // Constants
    const movieBottom = useRef(null);

    const windowHeight = window.innerHeight;
    // Length of each row of movies
    const moviesLengthRow = 4;
    const movieHeight = 380;
    //const moviePageHeight = movieHeight * 5;
    const genreHeight = 60;
    const totalGenres = 19;
    // Total movies loaded
    const totalMovies = 20 * appState.pageCount;

    // Call our hook for each key that we'd like to monitor
    const up = useKeyPress('w');
    const down = useKeyPress('s');
    const right = useKeyPress('d');
    const left = useKeyPress('a');

    const view = useKeyPress('f');
    const quitView = useKeyPress("q");

    const returnLeft = useKeyPress("r");

    const top = useKeyPress("t")
    const bottom = useKeyPress("b")

    const modeToggler = useKeyPress("n");


    // Listen for backspace event when in show single movie mode
    if (appState.showSingleMovie) {
        window.addEventListener("keydown", (key) => {
            if (key.keyCode === 8 || key.keyCode === 27) {
                appDispatch({type: "hideSingleMovie"})
            }
            
        })
        
    }

    const handleMode = () => {
        if (mode === "dark") {
            setMode("light")
        } else {
            setMode("dark")
        }
        
    }


    // UseEffect for checking keypresses
    useEffect(() => {

        if (modeToggler) {
            handleMode()
        }

        // GENRES TAB
        // Check if current active component = genre tab
        if (appState.activeComponent === 1) {

            if (top) {
                appDispatch({type: "decreasedActiveItem", value: 0})
                window.scrollTo(0, 0)
            }
    
            if (bottom) {
                appDispatch({type: "increasedActiveItem", value: 18})
                window.scrollTo(0, document.body.scrollHeight)
            }

            // if activeItem is inside genre list
            if (appState.activeItem >= 0 && appState.activeItem <= 18) {

                // if right button pressed, switch to new activeComponent --> movies
                if (right) {
                    appDispatch({type: "increasedActiveComponent", value: appState.activeComponent+1})
                    appDispatch({type: "decreasedActiveItem", value: 0})
                    window.scrollTo(0, 0)
                }

                if (appState.activeItem < 0 || appState.activeItem > 18 && down) {
                    appDispatch({type: "increasedActiveItem", value: 0})
                    setHeight(height+genreHeight);
                    window.scrollTo(height+genreHeight, height+genreHeight)
                }

                // if activeItem >= total genres
                if (appState.activeItem > 18) {
                    // DOWN
                    // if activeItem >= total genres, setActive item to first genre
                    if (down) {
                        console.log("Down 1")
                        appDispatch({type: "increasedActiveItem", value: 0})
                        setHeight(0);
                        window.scrollTo(0, 0)
                    // if active genre is 0, set active item (genre) to genre with highest index
                    } else if (appState.activeItem === 0) {
                        appDispatch({type: "decreasedActiveItem", value: appState.activeItem+1})
                    } else if (down) {
                        appDispatch({type: "increasedActiveItem", value: 0})
                        setHeight(height+genreHeight);
                        window.scrollTo(height+genreHeight, height+genreHeight)
                    } else {
                        console.log("Go up")
                        appDispatch({type: "decreasedActiveItem", value: appState.activeItem-1})
                        setHeight(height-genreHeight);
                        window.scrollTo(height-genreHeight, height-genreHeight)
                    }
                    
                } else if (appState.activeItem < totalGenres) {
                    // UP
                    if (up) {
                        console.log(appState.activeItem, "Up 1")

                        // if active genreindex === 0 --> set genre to bottom element, 19-1
                        if (appState.activeItem === 0) {
                            console.log("Genre index is 0")
                            appDispatch({type: "increasedActiveItem", value: 19})
                            setHeight(900, 0);

                        // if active item > 0, go down
                        } else if (appState.activeItem > 0) {
                            console.log("Go up 3")
                            appDispatch({type: "decreasedActiveItem", value: appState.activeItem-1})
                            setHeight(height-genreHeight);
                            window.scrollTo(height-genreHeight, height-genreHeight)

                        // if active item is not 0 or bigger than 0 ???
                        } else {
                            console.log("Go up 4")
                            appDispatch({type: "decreasedActiveItem", value: appState.activeItem-1})
                            setHeight(height-genreHeight);
                            window.scrollTo(height-genreHeight, height-genreHeight)
                        }

                    } else if (down) {
                        down && appDispatch({type: "increasedActiveItem", value: appState.activeItem+1})
                        setHeight(height+genreHeight);
                        window.scrollTo(height+genreHeight, height+genreHeight)
                    }
                    

                } else {
                    alert("WUUT")
                }
            } else {
                appDispatch({type: "increasedActiveItem", value: 18})
                setHeight(height+genreHeight);
                window.scrollTo(height+genreHeight, height+genreHeight)
            }

        }

        // MOVIES TAB
        // Check if current active component = movies tab
        if (appState.activeComponent === 2) {

            if (top) {
                appDispatch({type: "decreasedActiveItem", value: 0})
                window.scrollTo(0, 0)
                setHeight(0)
            }
    
            if (bottom) {
                appDispatch({type: "increasedActiveItem", value: totalMovies-1})
                setHeight(document.body.scrollHeight)
                window.scrollTo(0, document.body.scrollHeight)
            }

            // If R-button pressed --> return to genre tab
            if (returnLeft) {
                // return to active genre by default
                if (appState.genreIndex > 8) {
                    window.scrollTo(5000, 5000)
                } else {
                    window.scrollTo(0, 0)
                }
                appDispatch({type: "decreasedActiveItem", value: appState.genreIndex})
                //
                appDispatch({type: "decreasedActiveComponent", value: appState.activeComponent-1})
            }

            // Single Movie View Handler
            // If view-button pressed && appState.viewSingleMovie  --> load single movie view
            if (view) {
                appDispatch({type: "viewSingleMovie"})
            } 
            if (quitView) {
                appDispatch({type: "hideSingleMovie"})
            }

            // Left Button Handler
            // check for left keypress (A)
            if (left) {
                // if left keypress and activeitem < 1
                if (appState.activeItem < 1) {

                    // Keep track of previous Genre in state
                    appDispatch({type: "decreasedActiveItem", value: appState.genreIndex})

                    // Set active component to current component -1
                    appDispatch({type: "decreasedActiveComponent", value: appState.activeComponent-1})

                } else {
                    appDispatch({type: "decreasedActiveItem", value: appState.activeItem-1})
                }
            } 

            // Right button handler
            // if keypress = D (right) - set current active item to current active item +1
            if (right) {
                if (appState.activeItem % 4 === 3) {

                    // Check if current movie index === totalMovies
                    if (appState.activeItem >= totalMovies-1) {
                        //appDispatch({type: "scrollWindow", value: (appState.windowHeight + movieHeight)})
                        appDispatch({type: "increasedActiveItem", value: appState.activeItem+1})
                        appDispatch({type: "loadMoreMovies", value: appState.pageCount+1})
                        setHeight(height + movieHeight);
                        window.scrollTo(height + movieHeight, height + movieHeight)
                    } else {
                        //appDispatch({type: "scrollWindow", value: (appState.windowHeight + movieHeight)})
                        appDispatch({type: "increasedActiveItem", value: appState.activeItem+1})
                        setHeight(height + movieHeight);
                        window.scrollTo(height + movieHeight, height + movieHeight)
                    }

                } else {
                    appDispatch({type: "increasedActiveItem", value: appState.activeItem+1})
                }
                
            }

            // Down Button Handler
            // If keypress = S (down) and total movies loaded - length of movies row --> skip items until next row
            if (down && appState.activeItem < totalMovies - (moviesLengthRow+4)) {
                appDispatch({type: "increasedActiveItem", value: appState.activeItem + moviesLengthRow})
                setHeight(height + movieHeight);
                window.scrollTo(height + movieHeight, height + movieHeight)

            // If no row below current row of movies - send request to movies component to increment page by 1
            } else if (down && appState.activeItem >= (totalMovies - (moviesLengthRow+4))) {

                // Load more movies if only two rows left

                console.log(totalMovies - (moviesLengthRow+5), "Movies length load more")
                appDispatch({type: "loadMoreMovies", value: appState.pageCount+1})
                appDispatch({type: "increasedActiveItem", value: appState.activeItem + moviesLengthRow})
                setHeight(height + movieHeight);
                window.scrollTo(height + movieHeight, height + movieHeight)
            }

            // Up button handler
            // Check if keypress = up (W)
            // if current item active is not on first row --> decrease active item by one row
            if (up) {
                if (appState.activeItem > 3) {
                    appDispatch({type: "decreasedActiveItem", value: appState.activeItem - moviesLengthRow})
                    setHeight(height - movieHeight);
                    window.scrollTo(height - movieHeight, height - movieHeight)
                } else {
                    setHeight(height - movieHeight);
                    window.scrollTo(0, 0)
                }
            
            }


        }

    }, [up, down, right, left, view, returnLeft, quitView, appState.genreIndex, top, bottom, modeToggler])


    return (
        <div style={mode === "dark" ? {backgroundColor: "black", color: "white"} : {backgroundColor: "#d9d9d9", color: "black"}} >
            <div className="col-lg-11 mx-auto">
                <div className="title">
                    <div className="">
                        <div className="row">
                            <div className="col-9">
                                <h3>
                                    <span className="app-title">
                                        Filmer
                                    </span> 
                                </h3>
                            </div>
                            <div className="col-3">
                                <div className="text-right">
                                    <i className="fas fa-question"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-3">
                    <label class="switch">
                        <input onClick={handleMode} type="checkbox" checked={mode === "dark"} />
                        <span class="slider round"></span>
                    </label>
                    <label className="ml-2 text-lg text-muted">Dark mode</label>
                </div>


                <div className={"row"} >
                    <div className="col-md-4 col-lg-3">
                        <MovieGenres mode={mode} setSingleMovie={setSingleMovie} setGenre={setGenre} genre={genre} />
                    </div>
                    <div className={"col-md-8 col-lg-9"}>
                        <Movies 
                            setSingleMovie={setSingleMovie} 
                            singleMovie={singleMovie} 
                            genre={genre} 
                        />
                        <div ref={movieBottom}>Bottom</div>
                    </div>
                </div>
                {appState.showSingleMovie && appState.singleMovie && (
                    <div>
                        <SingleMovieSlide mode={mode} setSingleMovie={setSingleMovie} singleMovie={singleMovie} />
                    </div>
                )}
                
            </div>
        </div>
    )
}
export default HomeMovies