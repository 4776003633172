import React, { useEffect, useState, useContext } from 'react'
import Axios from 'axios'
import StateContext from '../../../StateContext';

function AddStreamMovie(props) {

    const appState = useContext(StateContext)

    const id = props.id
    const token = appState.user.token;

    const [streamingServicesMovie, setStreamingServicesMovie] = useState()
    const [streamingServices, setStreamingServices] = useState([])

    const [posted, setPosted] = useState(false);
    const [postErr, setPostErr] = useState(false)
    const [deleted, setDeleted] = useState(false);
    const [deleteError, setDeleteError] = useState(false)

    const [isLoading, setIsLoading] = useState(true)

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    useEffect(() => {
        async function streamservicesMovie() {
            setIsLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/media_streaming?search=" + props.title)
                

                const filteredStreams = response.data.map(stream => {
                    if (stream.acf.media_id === id) {
                        return stream
                    } else {
                        return
                    }
                })
                setStreamingServicesMovie(filteredStreams)

                //console.log(response.data)
                
            } catch(error) {
                console.log("Ingen filmer streamer")
            }
            setIsLoading(false)

        }
        streamservicesMovie()
    }, [id, posted, deleted])


    useEffect(() => {


        if (streamingServicesMovie && streamingServicesMovie.length) {

            let streamHtml

            if (streamingServicesMovie.length > 1) {

                streamHtml = streamingServicesMovie.map((stream, index) => {
                    return `<div class="col-3 col-md-2"><img src="${stream.acf.stream_logo}" class="img-fluid" alt="Stream Logo"></div>`
                })

                streamHtml = `<div class="row">${streamHtml}</div>`

            } else if (streamingServicesMovie.length === 1) {
                streamHtml = `<div class="row"><div class="col-3 col-md-2"><img src="${streamingServicesMovie[0].acf.stream_logo}" class="img-fluid" alt="Stream Logo"></div></div>`
            } else {
                streamHtml = ""
            }
            

            //props.setStreamingHtml(streamHtml);
            //console.log(streamHtml, "STREAM HTML AddStreamMove:81")
        }

    }, [streamingServicesMovie])

    useEffect(() => {

        async function fetchStreams() {

            setIsLoading(true)

            try {
                
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/stream_services");
                if (response.data) {
                    setStreamingServices(response.data);
                }

            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        fetchStreams()

    }, [])

    async function addStreamService(e, streamId, streamLogo) {
        e.preventDefault();

        const formData = new FormData(e.target);

        formData.append("title", `stream added for ${props.mediaType} with id ${id} with title ${props.title}`);
        formData.append("content", `stream added for ${props.mediaType} with id ${id} with title ${props.title} by ${appState.user.user_display_name}`);
        formData.append("status", "publish");
        formData.append("fields[stream_service]", streamId)
        formData.append("fields[media_id]", props.id);
        formData.append("fields[media_type]", props.mediaType)
        formData.append("fields[stream_logo]", streamLogo)

        try {
            const response = await Axios.post(appState.wordpressBaseUrl + 'wp-json/wp/v2/media_streaming', 
            formData,
            {
                headers: headers
            })
            if (response.data) {
                setPosted(true)
            } else {
                setPostErr(true)
            }
            console.log(response)
        } catch (error) {
            setPostErr(true)
            console.log(error)
        }

    }

    async function removeStreamService(e, streamId) {
        e.preventDefault()
        try {
            const response = await Axios.delete(appState.wordpressBaseUrl + 'wp-json/wp/v2/media_streaming/' + streamId, {headers: headers})
            if (response.data) {
                setDeleted(true)
            } else {
                setDeleteError(true)
            }
        } catch (error) {
            setDeleteError(true)
        }
    }

    if (isLoading) return <p>Laster.....</p>

    return (
        <div className="col-12">
            <div>
                
                {streamingServicesMovie && (
                        <div className="row my-3">
                        {streamingServicesMovie.map((stream, index) => {
                            return <div key={index} className="col-3 col-md-2">   
                                <img src={stream.acf.stream_logo} className="img-fluid" alt="Stream Logo" />                  
                            </div>
                        })}
                        </div>
                    )       
                }
            </div><br />

            {token && (
                <>
                    <div>
                        <br /><h2 className="my-4 text-white text-center">Legg til / Fjern strømmetjenester</h2>
                    </div>
                    {postErr && <div className="alert alert-danger">Kunne ikke legge til </div>}
                    <div className="row">

                        {streamingServices.map(stream => {
                            return (
                                <div key={stream.id} className="col-6 col-lg-3">
                                    <form onSubmit={(e) => addStreamService(e, stream.id, stream.acf.bildeurl_stream)}>
                                        <input type="hidden" name="streamId" value={stream.id} />
                                        <input type="hidden" name="streamTitle" value={stream.acf.tittel_stream} />
                                        <input type="hidden" name="streamLogo" value={stream.acf.bildeurl_stream} />
                                        <button type="submit" className="btn btn-success">
                                            <div className="row">
                                                <div className="col-md-5 float-left"><img src="https://progitek.no/wp-content/uploads/2020/06/plusicon.png" /></div>
                                                <div className="col-md-7 float-right"><img src={stream.acf.bildeurl_stream} alt={stream.acf.tittel_stream} /></div>
                                            </div>    
                                        </button>
                                    </form>
                                </div>
                            )
                        })} 
                                   
                        
                    </div>
                    <h2>Slett strømmetjenester</h2>
                    {streamingServicesMovie && (
                        <div className="row my-3">
                            {streamingServicesMovie.map((stream, index) => {
                                return (
                                    <div key={index} className="col-6 col-md-4 col-lg-3">  
                                        <form onSubmit={(e) => {removeStreamService(e, stream.id)}}>
                                            <button className="btn btn-danger">
                                                <div className="row">
                                                    <div className="col-md-5 float-left"><img src="https://progitek.no/wp-content/uploads/2020/06/trash.png" /></div>
                                                    <div className="col-md-7 float-right"><img src={stream.acf.stream_logo} className="img-fluid" alt="Stream Logo" /> </div>
                                                </div>                                             
                                            </button>                                          
                                        </form> 
                                                            
                                    </div>
                                )
                            })}
                        </div>
                        )       
                    }
                </>
            )}

        </div>
    )
}
export default AddStreamMovie