import React, { useState, useContext } from 'react'
import StateContext from '../../../StateContext'

function EditGroupCoverImage(props) {

    const appState = useContext(StateContext)

    const token = appState.user.token   
    const [error, setError] = useState(false)
    const [uploaded, setUploaded] = useState(false)

    const [image, setImage] = useState()
    const [isLoading, setIsLoading] = useState(false)



    async function editCoverImage(e) {

        e.preventDefault();
        setIsLoading(true)

        if (!image) {
            return 
        }

        const formData = new FormData()

        formData.append( 'context', 'edit' );
        formData.append( 'action', 'bp_cover_image_upload' );
        formData.append( 'file', image, image.name)

        
        console.log("IMAGE PATH: ", image)
        try {

            const response = await fetch(appState.wordpressBaseUrl + "wp-json/buddypress/v1/groups/" + props.groupId + "/cover", {
                method: "POST",
                headers: {
                 Authorization: "Bearer " + token
                },
                body: formData
            })
            
            //const response = await Axios.post(appState.wordpressBaseUrl + "wp-json/buddypress/v1/members/" + userId + "/cover", formData, headers)
            if (response.data) {
                setUploaded(true);
                console.log(response)
            } else {
                setUploaded(true);
                console.log("No response", response);
            }

        } catch (error) {
            setError(true);
            console.log("ERROR COVER UPLOAD: " , error.message)
        }
        setIsLoading(false)
    }

    if (isLoading) return <p>Laster opp......</p>

    return (
        <>
        
        <div className="card my-2">
            <div className="card-header">Velg CoverBilde</div>
            <div className="card-body">
            {uploaded && <div className="alert alert-success text-center">Lastet opp!</div>}
            {error && <div className="alert alert-danger text-center">Kunne ikke laste opp, vennligst prøv igjen</div>}
                <form onSubmit={editCoverImage}>
                    <p>Bilde: {image ? image.name : "Ikke valgt"}</p>
                    <input className="form-control mb-2" onChange={(e) => setImage(e.target.files[0])} type="file" name="image" />
                    <button className="btn btn-primary btn-block" disabled={isLoading || !image} type="submit">
                        <i className="far fa-image" aria-hidden="true"></i>Last opp bilde
                    </button>
                </form>
                <p className="my-3">
                    <a href={appState.wordpressBaseUrl + "medlemmer/" + appState.user.user_nicename + "/profile/change-cover-image/"}>
                        Trykk her hvis du ikke klarer å laste opp cover
                    </a>
                </p>
            </div>
        </div>
        <br/>
        </>
    )

}
export default EditGroupCoverImage