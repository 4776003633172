import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

function Countries() {

    const [countries, setCountries] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchData() {
            setIsLoading(true)
            try {
                const response = await Axios({
                    "method":"GET",
                    "url":"https://api-football-v1.p.rapidapi.com/v2/countries",
                    "headers":{
                    "content-type":"application/octet-stream",
                    "x-rapidapi-host":"api-football-v1.p.rapidapi.com",
                    "x-rapidapi-key":"4a924e26e2msh6cbbda7a5553cb0p10cffcjsn999d1a4b9b45",
                    "useQueryString":true
                    }
                })
                console.log(response.data.api.countries)
                setCountries(response.data.api.countries)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)

        }
        fetchData()

    }, [])

    if (isLoading) return <p>Loading countries..</p>

    return (
        <div className="container my-4">
            <div className="row">
                {countries.map((country, index) => {
                    return (
                        <div className="col-6 col-lg-3" key={index}>
                            <div className="card card-body mb-2">
                                <div>
                                    <Link to={`/football/countries/${country.country}`}>
                                        <img src={country.flag} className="img-fluid rounded-circle" style={{maxWidth: "20px"}} />
                                        <span> {country.country} </span>
                                    </Link>
                                </div>
                            </div>
                            
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default Countries