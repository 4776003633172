import React, {useState, useEffect} from 'react'
import { firestore } from '../firebase';

function AllPlayers(props) {

    const [posts, setPosts] = useState([]);

    useEffect(() => {

        const entriesRef = firestore.collection('team-players');
        entriesRef.get().then((snapshot) => {
            
            const entries = snapshot.docs.map((doc, index) => {
                const entries = {id: doc.id, ...doc.data()}
                return entries
            })
            setPosts(entries)
            
        })

    }, [props.addCount])

    if (!posts) return <p>Ingen elementer funnet</p>

    return (
        <div className="row">
            {posts && posts.map((post, index) => {
                return (
                    <figure key={index} className="col-md-2">
                        <img src={post.player_image} className="img-fluid" />
                    </figure>
                )
            })}

        </div>
    )
}
export default AllPlayers