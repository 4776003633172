import React, { useEffect, useContext, useState } from 'react'
import {useParams} from 'react-router-dom'
import Loader from '../Loader/Loader'
import Axios from 'axios'
import {useImmerReducer} from 'use-immer'
import StateContext from "../../StateContext"
import DispatchContext from "../../DispatchContext"
import { Link, withRouter } from 'react-router-dom'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AlertMessage from '../ReusableComponents/AlertMessage'
import ImagePicker from '../Media/ImagePicker'

function EditPost(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [isLoading, setIsLoading] = useState(false)
    const [isIngredientLoading, setIsIngredientLoading] = useState(false)
    const [isMatrettLoading, setIsMatrettLoading] = useState(false)
    const [post, setPost] = useState()

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState()
    const [alertMessage, setAlertMessage] = useState("")

    const [matretter, setMatretter] = useState()
    const [ingredienser, setIngredienser] = useState()

    const [description, setDescription] = useState("")
    const [servings, setServings] = useState(4)
    const [prepTime, setPrepTime] = useState(20)
    const [cookingTime, setCookingTime] = useState(15)
    const [restingTime, setRestingTime] = useState(15)
    const [featuredImageRecipe, setFeaturedImageRecipe] = useState("https://camillahardilla.com/wp-content/uploads/2019/04/img_8219-1024x683.jpg")
    console.log(featuredImageRecipe)

    const [categories, setCategories] = useState()
    const [isCategoriesLoading, setIsCategoriesLoading] = useState(true)
    const [categoriesSelected, setCategoriesSelected] = useState([1])

    const [value, setValue] = useState(post ? post.content.rendered : "");

    
    //console.log(post, "   POST VALUE")
    //console.log(value, "Editor value")

    const originalState = {
        title: {
            value: "",
            hasErrors: false,
            message: ""
        }, 
        body: {
            value: "",
            hasErrors: false,
            message: ""
        },
        isFetching: true,
        isSaving: false,
        id: useParams().id,
        sendCount: 0,
        notFound: false
    }

    function ourReducer(draft, action) {
        switch (action.type) {
            case "fetchComplete":
                draft.title.value = action.value.title.rendered
                draft.body.value = action.value.content.rendered
                draft.isFetching = false
                return
            case "titleChange":
                draft.title.hasErrors = false
                draft.title.value = action.value
                return
            case "bodyChange":
                draft.body.hasErrors = false
                draft.body.value = action.value
                return 
            case "submitRequest":
                if (!draft.title.hasErrors && !draft.body.hasErrors) {
                    draft.sendCount++
                }
                return
            case "saveRequestStarted": 
                draft.isSaving = true
                return
            case "saveRequestFinished":
                draft.isSaving = false
                return
            case "titleRules":
                if (!action.value.trim()) {
                    draft.title.hasErrors = true
                    draft.title.message = "Du må oppgi en tittel"
                }
                return 
            case "bodyRules":
                if (!action.value.trim()) {
                    draft.body.hasErrors = true
                    draft.body.message = "Du må oppgi innhold"
                }
                return 
            case "notFound":
                draft.notFound = true;
                return
        }
    }

    const [state, dispatch] = useImmerReducer(ourReducer, originalState)

    function submitHandler(e) {
        e.preventDefault()
        dispatch({type: "titleRules", value: state.title.value})
        dispatch({type: "bodyRules", value: state.body.value})
        dispatch({type: "submitRequest"})
    }

    useEffect(() => {

        const ourRequest = Axios.CancelToken.source()

        async function fetchPost() {
            setIsLoading(true)
            try {
                const response = await Axios.get(`${appState.wordpressBaseUrl}wp-json/wp/v2/posts/${state.id}?_embed`,
                {  
                    token: appState.user.token
                },  
                {
                    cancelToken: ourRequest.token
                })
                if (response.data) {
                    //console.log("REsponse fetch post: ", response.data)
                    setValue(response.data.content.rendered)
                    setPost(response.data)

                    setPrepTime(response.data.acf.forberedelse_oppskrift);
                    setServings(response.data.acf.antall_serveringer);
                    setDescription(response.data.acf.beskrivelse_oppskrift);
                    setFeaturedImageRecipe(response.data.acf.fremhevet_bilde_oppskrift)
                    const categoriesSelected = response.data._embedded['wp:term'][0].map(category => {
                        return category.id;
                    }).join(", ")
                    setCategoriesSelected(categoriesSelected)

                    dispatch({type: "fetchComplete", value: response.data})
                    if (appState.user.username != response.data.author.username) {
                        appDispatch({type: "flashMessage", value: "Du har ikke rettighet til å redigere dette innlegget"});
                        setShowAlert(true);
                        setAlertType("danger");
                        setAlertMessage("Du har ikke rettighet til å redigere dette innlegget")

                        props.history.push("/");
                    }
                    //console.log("OPPSKRIFT: ", response.data)
                } else {
                    dispatch({type: "notFound", value: "Fant ikke innlegg"})

                    setShowAlert(true);
                    setAlertType("danger");
                    setAlertMessage("Fant ikke innlegg")
                }
              
            } catch (error) {
                //console.log(error)
            }
            setIsLoading(false)
            
        }
        fetchPost()
        return () => {
            ourRequest.cancel()
        }
    }, [])

    useEffect(() => {

        if (state.sendCount) {
            dispatch({type: "saveRequestStarted"})
            const ourRequest = Axios.CancelToken.source()



            async function editPost(e) {

                const formData = new FormData()
                formData.append("status", "publish");
                formData.append("title", state.title.value);
                formData.append("content", value);
                //formData.append("featured_media", featuredImage);
        
                formData.append("fields[beskrivelse_oppskrift]", description);
                formData.append("fields[antall_serveringer]", servings);
                formData.append("fields[forberedelse_oppskrift]", prepTime);
                formData.append("fields[koketid]", +cookingTime);
                formData.append("fields[hviletid]", +restingTime);
                formData.append("fields[fremhevet_bilde_oppskrift]", featuredImageRecipe);
                console.log("FORMDATA - FEATURED RECIPE IMAGE: ", '"' + featuredImageRecipe + '"')
                
                try {
                    const response = await Axios.post(`${appState.wordpressBaseUrl}wp-json/wp/v2/posts/${state.id}`, 
                        formData,                     
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + appState.user.token
                            }                       
                        }
                    )

                    console.log(response, "       EDIT POST RESPONSE")
                    
                    dispatch({type: "saveRequestFinished"})
                    appDispatch({type: "flashMessage", value: "Innlegg er oppdatert!"})

                    setShowAlert(true);
                    setAlertType("success");
                    setAlertMessage("Innlegg oppdatert!")
                    
                } catch (error) {
                    //console.log(error)

                    setShowAlert(true);
                    setAlertType("danger");
                    setAlertMessage("Kunne ikke redigere")
                }
                
            }
            editPost()
            return () => {
                ourRequest.cancel()
            }
        }

    }, [state.sendCount ])

    useEffect(() => {

        async function fetchMatretter() {
            setIsMatrettLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/wp/v2/matrett');
                setMatretter(response.data)
                //console.log(response.data)
            } catch (error) {
                //console.log("Matrett error: ", error)
            }
            setIsMatrettLoading(false)
        }
        fetchMatretter()

    }, [])

    useEffect(() => {

        async function fetchIngredienser() {
            setIsIngredientLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + 'wp-json/wp/v2/ingredient');
                setIngredienser(response.data)
                //console.log(response.data)
            } catch (error) {
                //console.log("Matrett error: ", error)
            }
            setIsIngredientLoading(false)

        }
        fetchIngredienser()

    }, [])

    useEffect(() => {
        async function fetchCategories() {
            setIsCategoriesLoading(true)
            try {
                const response = await Axios.get(appState.wordpressBaseUrl + "wp-json/wp/v2/categories");
                setCategories(response.data)

            } catch (error) {
                //console.log("Kunne ikke hente media")
            }
            setIsCategoriesLoading(false)

        } 
        fetchCategories()

    }, [])

    let catArray;
    if (categories) {
        catArray = categories.map(category => {
            return [category.id + ', ']
        })
    } else {
        catArray = []
    }

    function selectCategories(e) {

        if (categoriesSelected) {

            setCategoriesSelected(categoriesSelected + ", " + +e.target.value)
            
        } else {
            setCategoriesSelected(+e.target.value)
        }
        
    }

    function isInArray(value, array) {
        return array.indexOf(value) > -1;
    }


    if (state.notFound) {
        return (
            <p>Ikke funnet... <Link to="/">Gå hjem</Link></p>
            
        )
    }

    if (state.isFetching || isLoading) return <Loader />

    if (!appState.user.token) return <p>Du må være logget inn</p>

    return (
        <div className="container my-4">
            
            <Link className="small font-weight-bold my-3" to={`/innlegg/single/${post.id}`}>Tilbake til innleggsside</Link>
            <br />

            <a className="btn btn-info btn-rounded" href={appState.wordpressBaseUrl + "wp-admin/post.php?post=" + post.id + "&action=edit"}>Rediger i Wordpress</a>
            <form className="mt-3" onSubmit={submitHandler}>
                <div className="form-group">
                    <label htmlFor="post-title" className="text-muted mb-1">
                        <small>Title</small>
                    </label>
                    <input 
                        onBlur={e => dispatch({type: "titleRules", value: e.target.value})} 
                        onChange={e => dispatch({type: "titleChange", value: e.target.value})} 
                        value={state.title.value} 
                        autoFocus 
                        name="title" 
                        id="post-title" 
                        className="form-control form-control-lg form-control-title" 
                        type="text" 
                        placeholder="" 
                        autoComplete="off" 
                    />
                    {state.title.hasErrors && <div className="alert alert-danger small liveValidateMessage">{state.title.message}</div>}
                </div>

                <label>Legg til bilde av oppskrift</label>
                <input value={featuredImageRecipe} type="text" name="field[fremhevet_bilde_oppskrift" onChange={e => setFeaturedImageRecipe(e.target.value)} className="form-control mb-2" placeholder="Bilde av oppskrift" />
                <ImagePicker />
                {featuredImageRecipe && <div className="col-md-3"><img src={featuredImageRecipe} className="img-fluid" /></div>}

                <div className="my-3">
                {!isCategoriesLoading &&
                    <>
                        <label>Kategorier</label>

                        <p>{categoriesSelected}</p>
                        
                    
                        {categories.map(category => {
                            return (
                                <div key={category.id} className="custom-control custom-checkbox">
                                    <input
                                        id={`category${category.id}`} 
                                        onChange={selectCategories} 
                                        type="checkbox" 
                                        className="custom-control-input" 
                                        name="field[ingredient]" 
                                        value={category.id} 
                                    />
                                    <label className="custom-control-label" htmlFor={`category${category.id}`}> 
                                    {category.name}  (ID: {category.id}) 
                                    {categoriesSelected.length && Boolean(isInArray(category.id, categoriesSelected)) &&
                                        <span className="text-success"> Valgt</span> 
                                    }
                                    
                                    
                                    </label><br/>
                                </div>
                            )
                        })}
                        <div onClick={() => setCategoriesSelected("")} className="btn btn-danger btn-rounded">Fjern alle kategorier</div>
                        
                    </>
                }
                </div>

                <label>Beskrivelse</label>
                <textarea onChange={(e) => setDescription(e.target.value)} value={description} name="fields[beskrivelse_oppskrift]" placeholder="Beskrivelse av oppskrift" className="form-control mb-2" />
                
                <label>Antall Serveringer</label>
                <input 
                    onChange={(e) => setServings(e.target.value)} 
                    value={servings} 
                    type="number" 
                    name="fields[antall_serveringer]" 
                    placeholder="Antall serveringer" 
                    className="form-control mb-2" 
                />
                
                <label>Forberedelse oppskrift i minutter</label>
                <input 
                    onChange={(e) => setPrepTime(e.target.value)} 
                    value={prepTime} 
                    type="number" 
                    name="fields[forberedelse_oppskrift]" 
                    placeholder="Forberedelsestid i minutter" 
                    className="form-control mb-2" 
                />

                <label>Steke / Koketid</label>
                <input 
                    onChange={(e) => setCookingTime(e.target.value)}
                    value={cookingTime}
                    type="number"
                    name="fields[koketid]"
                    placeholder="Koke / Steketid"
                    className="form-control mb-2"
                />
                <label>Hviletid</label>
                <input 
                    onChange={(e) => setRestingTime(e.target.value)}
                    value={restingTime}
                    type="number"
                    name="fields[hviletid]"
                    placeholder="Hviletid"
                    className="form-control mb-2"
                />

                <div className="my-3">
                {!isIngredientLoading &&
                    <>
                        <label>Ingredienser</label>
                    
                        {ingredienser.map(ingrediens => {
                            return (
                                <div key={ingrediens.id} className="form-check">
                                    <input type="checkbox" className="form-check-input" name="field[ingredient]" value={ingrediens.id} />
                                    <label className="form-check-label" htmlFor="field[ingredient]"> {ingrediens.title.rendered}</label><br/>
                                </div>
                            )
                        })}
                        
                    </>
                }
                </div>

                {!isMatrettLoading && <>
                    <label>Matretter</label>
                    <select className="form-control mb-2">
                        {matretter.map(matrett => {
                            return (
                                <option key={matrett.id}>{matrett.title.rendered}</option>
                            )
                        })}
                    </select>
                </>}
                
                <label>Beskriv oppskrift</label>
                <ReactQuill theme="snow" value={value} onChange={setValue}/>

                <button className="wp-button wp-button-primary my-3" disabled={state.isSaving}> {!state.isSaving ? "Save New Post" : "Oppdaterer"} </button>
                <AlertMessage showAlert={showAlert} alertType={alertType} alertMessage={alertMessage} setShowAlert={setShowAlert} />
            </form>

        </div>
    )
}
export default withRouter(EditPost)