import React, { useState, useEffect } from 'react'
import { firestore } from '../../firebase'

function AddStats() {

    const [players, setPlayers] = useState([])
    const [teams, setTeams] = useState([])

    const [message, setMessage] = useState("")

    const [playerId, setPlayerId] = useState()
    const [teamId, setTeamId] = useState()
    const [season, setSeason] = useState(2020)
    const [goals, setGoals] = useState()
    const [assists, setAssists] = useState()
    const [fixtures, setFixtures] = useState()

    useEffect(() => {

        try {
            const playersRef = firestore.collection("players-fb");
            playersRef.orderBy("player_name").get().then((snapshot) => {
                const players = snapshot.docs.map(doc => {
                    const players = {id: doc.id, ...doc.data()}
                    return players
                })
                setPlayers(players)
            })
        } catch (error) {
            console.log(error)
        }

    }, [])

    useEffect(() => {

        try {
            const teamsRef = firestore.collection("teams");
            teamsRef.get().then((snapshot) => {
                const teams = snapshot.docs.map(doc => {
                    const teams = {id: doc.id, ...doc.data()}
                    return teams
                })
                setTeams(teams)
            })
        } catch (error) {
            
            console.log(error)
        }

    }, [])

    async function handleSubmit(e) {

        e.preventDefault();

        if (!teamId || !playerId) {
            setMessage("Du må velge lag og spiller");
            return
        }

        try {
            const statsRef = firestore.collection("players-fb").doc(playerId).collection("player-stats");
            const statsData = {
                season: season,
                fixtures: fixtures,
                goals: goals,
                assists: assists,
                team_id: teamId
            }
            const statsStatus = await statsRef.add(statsData);
            if (statsStatus) {
                setMessage("Statistikk lagt til!")
                setGoals(0)
                setFixtures(0)
                setAssists(0)
            } else {
                setMessage("Error: kunne ikke legge til statistikk")
            }

        } catch (error) {
            setMessage("Error: kunne ikke legge til statistikk")
        }

    }

    return (
        <form onSubmit={handleSubmit}>
            <select className="browser-default custom-select" onChange={e => setPlayerId(e.target.value)}>
                {players && players.map((player, index) => {
                    return (
                        <option key={index} value={player.id}>{player.player_name} - {player.id}</option>
                    )
                })}
            </select>

            <select className="browser-default custom-select" onChange={e => setTeamId(e.target.value)}>
                {teams && teams.map((team, index) => {
                    return (
                        <option key={index} value={team.id}>{team.team_name} - {team.id}</option>
                    )
                })}
            </select>

            <label>Sesong</label>
            <input className="form-control mb-2" type="number" value="2020" placeholder="sesong" value={season} onChange={e => setSeason(e.target.value)} />
            <input className="form-control mb-2" type="number" placeholder="Kamper" onChange={e => setFixtures(e.target.value)} />

            <input type="number" placeholder="Mål" className="form-control mb-2" onChange={e => setGoals(e.target.value)} />

            <input type="number" placeholder="Assist" className="form-control mb-2" onChange={e => setAssists(e.target.value)} />
            {message && <p>{message}</p>}
            <button type="submit" className="btn btn-primary btn-lg btn-block">Send</button>


        </form>
    )
}
export default AddStats