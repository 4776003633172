import React from 'react'


function FooterSocialLinks() {
    return (
        <>
        <hr className="clearfix d-md-none rgba-white-light" />

            <div className="row pb-3">

                <div className="col-md-12">
                    <div className="mb-5 flex-center">
                    <a href="https://www.facebook.com/SmorasIl/">
                        <i className="fab fa-facebook-f fa-lg mx-2 white-text mr-md-4"></i>
                    </a>
                    <a href="https://twitter.com/smorasil">
                        <i className="fab fa-twitter fa-lg mx-2 white-text mr-md-4"></i>
                    </a>
                    <a href="https://www.instagram.com/smorasfotball/">
                        <i className="fab fa-instagram fa-lg mx-2 white-text mr-md-4"></i>
                    </a>

                    <i className="fab fa-linkedin-in fa-lg mx-2 white-text mr-md-4"></i>

                    </div>
                </div>
            </div>
        </>
    )
}
export default FooterSocialLinks