import React, {useState, useEffect, useContext} from 'react'
import {useParams} from 'react-router-dom'
import Axios from 'axios'
import AddStreamMovie from './AddStreamMovie';
import SaveMovie from './SaveMovie';
import StateContext from '../../../StateContext';
import RecommendMovie from './RecommendMovie';

function SingleMovie() {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [moviesLoaded, setMoviesLoaded] = useState(false);
    const [movie, setMovie] = useState()

    const [streamingHtml, setStreamingHtml] = useState();
    const [postError, setPostError] = useState(false)
    
    const api_key = "7e21606276f58bbdfa39c8cdd8e051f5"
    const {id} = useParams()

    //console.log("Movie: ", movie)

    const getYear = (date) => {
        return new Date(date).getFullYear()
    }




    useEffect(() => {
        async function getSingleMovie() {
            setIsLoading(true)
            try {
                const response = await fetch(`https://api.themoviedb.org/3/movie/${id}?api_key=${api_key}`);
                const data = await response.json()
                if (data) {
                    //console.log("Filmdata: ", data)
                    setMovie(data);
                    setMoviesLoaded(true)
                } else {
                    console.log("Kunne ikke laste film")
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        getSingleMovie()
    }, [id])



    

    if (isLoading) return <div>Laster...</div>

    return (
        <>
        
        {moviesLoaded &&
            <div 
                className="background-image" 
                style={{
                    backgroundImage: `url('https://image.tmdb.org/t/p/w1280/${movie.backdrop_path}')`,
                    backgroundSize: "cover",
                    backgroundColor: "rgba(0, 0, 0, 0.9)"
                }} 
            >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.7)"}}>

                    <div className="container py-4">

                        <div className="row">

                            <div className="col-md-4">
                                
                                {movie.poster_path != null ? 
                                    <img src={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} alt="movie-poster" className="img-fluid" /> : 
                                    <img src={`https://complexmovieapp.herokuapp.com/images/film-poster-placeholder.png`} alt="movie-poster" className="img-fluid" />}
                            </div>

                            <div className="col-md-8 text-white">
                                <div className="">

                                    <h2>{movie.title} <span className="grey-text"><small>{getYear(movie.release_date)}</small></span></h2>
            
                                    <h3 className="my-4 font-weight-bold">
                                        <span className="bg-success px-2 mr-2">{movie.vote_average}</span>
                                        <span>Brukeranmeldelser</span>
                                    </h3>

                                    <h4 className="font-weight-bold">Oversikt</h4>
                                    <p>{movie.overview}</p>

                                    {postError && <div className="alert alert-danger">Noe gikk galt, prøv igjen</div>}

                                    <div className="row">
                                        <div className="col-md-6">
                                            <SaveMovie 
                                                movieTitle={movie.title} 
                                                movieId={id} 
                                                userId={appState.user.appUserId} 
                                                mediaType="film" 
                                                movieImageUrl={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} 
                                            /> 
                                        </div>
                                        <div className="col-md-6">
                                            <RecommendMovie 
                                                isLoading={isLoading}
                                                streamingHtml={streamingHtml}
                                                type="film" 
                                                movieTitle={movie.title} 
                                                moviePoster={`https://image.tmdb.org/t/p/w1280/${movie.backdrop_path}`} 
                                            />
                                        </div>
                                        <div className="col-md-4 mt-5">
                                            <a href={`https://www.imdb.com/title/${movie.imdb_id}`}>
                                                <img 
                                                    src="https://upload.wikimedia.org/wikipedia/commons/6/69/IMDB_Logo_2016.svg" 
                                                    alt="imdb-logo" 
                                                    className="img-fluid" 
                                                />
                                            </a>
                                        </div>
                                    </div>
                           
                                    

                                </div>
                            </div>

                            <AddStreamMovie setStreamingHtml={setStreamingHtml} id={id} mediaType="film" title={movie.title} />
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    
    )

}
export default SingleMovie